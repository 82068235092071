import React, { Component } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from '../../utils/API';
import { Modal, message } from 'antd';
import { withRouter } from 'react-router-dom';

class BasicPageUpdate extends Component {
    state = {
        _id: this.props.match.params.id,
        title: "",
        body: "",
        author: "",
        date: new Date(),
        status: true,
        alias: "",
    };
    componentDidMount() {
        this.getData();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }
    getData() {
        API.get('/pages/contents/page/' + this.state._id)
            .then(result => {
                const pages = result.data.pages;
                pages.map((page, index) => {
                    this.setState({ title: page.title });
                    this.setState({ body: page.body });
                    this.setState({ alias: page.pathalias.alias })
                    if (page.status == 'true') {
                        this.setState({ status: true });
                    } else {
                        this.setState({ status: false });
                    }
                });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }
    handlePublish(event) {
        event.preventDefault();
        const pageData = this.state;
        API.post('/pages/update', pageData)
            .then(result => {
                message.success('Page Updated');
                this.props.history.push('/admin/pages');
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.response.data.msg,
                });
            });
    }
    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({ body: data });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }

    render() {
        return (
            <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Update FAQ</h1>
                            <div className="new-post">
                                <form onSubmit={this.handlePublish} >
                                    <div className="form-group">
                                        <label className="new-title">Title: <span className="requiredsymbol">*</span></label>
                                        <input
                                            className="form-control new-title"
                                            type="text"
                                            name="title"
                                            value={this.state.title}
                                            onChange={this.handleChange}
                                            required
                                            placeholder="The Best Title"
                                        />
                                    </div>
                                    <div>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            onChange={this.handleEditorChange}
                                            data={this.state.body}
                                            config={{
                                                placeholder:
                                                    "Start typing your page detail here...",
                                                toolbar: [
                                                    "Heading",
                                                    "|",
                                                    "Bold",
                                                    "Italic",
                                                    "Link",
                                                    "NumberedList",
                                                    "BulletedList",
                                                    "|",
                                                    "BlockQuote",
                                                    "MediaEmbed",
                                                    "ImageUpload",
                                                    "InsertTable",
                                                    "Undo",
                                                    "Redo",
                                                ],
                                            }}
                                        />
                                    </div>
                                    {/* <div className="form-group">
                                    <label className="lable-title">Path</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="alias"
                                        value={this.state.alias}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="ex: /about"
                                    />
                                </div> */}
                                    {/* <div className="form-group publish-box">
                                <input type="checkbox" defaultChecked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1"/>
                                <label className="custom-control-label" htmlFor="customCheck1">Publish</label>
                            </div> */}
                                    <br />
                                    <div className="form-group">
                                        <input
                                            type="submit"
                                            value="Update"
                                            className="btn btn-outline-primary btn-lg"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BasicPageUpdate);