import React, { Component, Fragment } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { Link } from "react-router-dom";
import { Popconfirm, message, Modal } from "antd"
import { faUser } from "@fortawesome/free-solid-svg-icons";
import UserViewModal from './UserViewModal';
import API from '../../utils/API';
import Moment from 'moment';

/* import UserUpdateModal from "../partials/UserUpdateModal"; */
import { toast, ToastContainer } from "react-toastify";

class Users extends Component {

    columns = [
        {
            key: "key",
            text: "",
            className: "id",
            align: "left",
            sortable: true,
        },
        {
            key: "firstname",
            text: "First Name",
            className: "firstname capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "lastname",
            text: "Last Name",
            className: "lastname capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "email",
            text: "Email",
            className: "email",
            align: "left",
            sortable: true
        },
        {
            key: "phonenumber",
            text: "Phone Number",
            className: "phonenumber",
            align: "left",
            sortable: true
        },
        {
            key: "confirmemail",
            text: "Email Verified",
            className: "email",
            align: "left",
            sortable: true
        },
        {
            key: "membershiprole",
            text: "Subscription",
            className: "sub",
            align: "left",
            sortable: true,
        },
        {
            key: "subscriptiontype",
            text: "Subscription Type",
            className: "sub",
            align: "left",
            sortable: true
        },
        {
            key: "coupon",
            text: "coupon",
            className: "coupon",
            align: "left",
            sortable: true
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            align: "left",
            sortable: true
        },
        {
            key: "onboarding",
            text: "Onboarding",
            className: "status",
            align: "left",
            sortable: true
        },
        {
            key: "registerDate",
            text: "Registered Date",
            className: "date",
            align: "left",
            sortable: true
        },
        {
            key: "platform",
            text: "Subscribed From",
            className: "platform",
            align: "left",
            sortable: true
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        <Popconfirm
                            title="Are you sure to delete this User?"
                            onConfirm={this.confirm}
                            okText="Yes"
                            cancelText="No"
                        >
                            <button
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                style={{ marginRight: '5px' }}
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Popconfirm>
                        {record.status == "Active" && (
                            <Popconfirm
                                title="Are you sure to block this User?"
                                onConfirm={this.blockConfirm}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button
                                    className="btn btn-danger btn-sm"
                                    title="Block"
                                    style={{ marginRight: '5px' }}
                                    onClick={() => this.blockUser(record)}>
                                    <i className="fa fa-ban"></i>
                                </button>
                            </Popconfirm>
                        )}
                        {record.status == "Blocked" && (
                            <Popconfirm
                                title="Are you sure to activate this User?"
                                onConfirm={this.unblockUser}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button
                                    className="btn btn-success btn-sm"
                                    title="Unblock"
                                    style={{ marginRight: '5px' }}
                                    onClick={() => this.blockUser(record)}>
                                    <i className="fa fa-unlock"></i>
                                </button>
                            </Popconfirm>
                        )}

                        <button
                            title="View"
                            /* data-toggle="modal"
                            data-target="#show-user-modal" */
                            className="btn btn-primary btn-sm"
                            onClick={() => this.viewRecord(record)}
                        >
                            <i className="fa fa-user"></i>
                        </button>
                    </Fragment>
                );
            }
        }
    ];

    config = {
        key_column: 'key',
        page_size: 10,
        length_menu: [10, 20, 50, 100],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    state = {
        records: [],
        visible: false,
        currentRecord: {
            _id: '',
            firstname: '',
            lastname: '',
            email: '',
            registerDate: '',
        }

    };
    componentDidMount() {
        this.getData();
        this.viewRecord = this.viewRecord.bind(this)
        this.confirm = this.confirm.bind(this);
        this.blockConfirm = this.blockConfirm.bind(this);
        this.unblockUser = this.unblockUser.bind(this);

    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {

        API.get("/users/user-data")
            .then(res => {
                
                var usersdata = [];
                Moment.locale('en');
                res.data.data.map((u, index) => {
                    let membershiprolevalue = u?.membershiprole? u?.membershiprole?.charAt(0).toUpperCase() + u?.membershiprole?.slice(1) : 'Free';
                    let usertypevalue = u.usertype.length > 0 ? '('+ u.usertype+')': '';
                    let intensivetrainingvalue = u?.intensivetraining == true ? ', Entrenamiento': '';
                    let subscriptiontypevalue = u?.subscription_type ? u.subscription_type+intensivetrainingvalue : u?.intensivetraining == true ? 'Entrenamiento': '--';
                    usersdata[index] = {
                        key: index + 1,
                        _id: u._id,
                        firstname: u.firstname,
                        lastname: u.lastname,
                        email: u.email,
                        phonenumber: u?.userinfoid?.phonenumber ? u?.userinfoid?.phonenumber : '',
                        membershiprole: membershiprolevalue + usertypevalue,
                        subscriptiontype: subscriptiontypevalue,
                        coupon: u?.coupon,
                        status: u.status == 1 ? 'Active' : 'Blocked',
                        onboarding: u?.userinfoid?.roles ? 'Done' : 'Not Done',
                        confirmemail: u.confirmemail == 1 ? 'Verified' : 'Not Verified',
                        registerDate: Moment(u.registerDate).format('MMM D, YYYY'),
                        platform: u?.subscribedfrom ? u?.subscribedfrom : 'Web'
                    }
                });
                this.setState({ records: usersdata })
            })
            .catch()
    }

    viewRecord(record) {
        this.setState({ currentRecord: record });
        this.setState({ visible: true });
    }
    deleteRecord(record) {
        this.setState({ currentRecord: record });
    }
    blockUser(record) {
        this.setState({ currentRecord: record })
    }
    blockConfirm() {
        API.post("/users/userblock", { id: this.state.currentRecord._id })
            .then(res => {
                if (res.status === 200) {
                    message.success('User blocked');
                    this.getData();
                }
            })
            .catch();
    }
    unblockUser() {
        API.post("/users/userunblock", { id: this.state.currentRecord._id })
            .then(res => {
                if (res.status === 200) {
                    message.success('User Unblock');
                    this.getData();
                }
            })
            .catch();
    }
    confirm() {
        API.post("/users/userdelete", { id: this.state.currentRecord._id })
            .then(res => {
                if (res.status === 200) {
                    message.success('User deleted');
                    this.getData();
                }
            })
            .catch();
        this.getData();
    }
    pageChange(pageData) {

    }


    render() {
        return (
            <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <Modal
                        width={800}
                        closable={false}
                        visible={this.state.visible}
                        keyboard="false"
                        centered="true"
                        onCancel={(e) => { this.setState({ visible: false }) }}
                        maskClosable={false}
                        footer={null}
                    >
                        <UserViewModal onclose={(e) => { this.setState({ visible: false }) }} record={this.state.currentRecord} />
                    </Modal>

                    {/* <UserAddModal/>
                    <UserUpdateModal record={this.state.currentRecord}/> */}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <Link to="/users/admin">
                                <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faUser} /> View Admin Users</button>
                            </Link>
                            <h1 className="mt-2 text-primary">Users List</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}


export default Users;
