import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Modal } from 'antd';
import API from '../../utils/API';
import $ from 'jquery';


const AddInterests = (props) => {
  const history = useHistory();
  const onFinish = values => {
    API.post('/interests/add', {
      name: values.catname,
    })
      .then(() => {
        history.push('/admin/interests');
        $('#add-category-modal').modal('hide');
      })
      .catch(e => {
        $('#add-category-modal').modal('hide');
        Modal.error({
          title: 'Error',
          content: e.response.msg,
        });
      });
  }

  return (
    <div>
      <div className="modal fade" id="add-category-modal" data-reset="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add New Interests</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <div className="new-cat-post">
                <Form
                  name="category"
                  layout="vertical"
                  onFinish={onFinish}
                  size={'default'}
                >
                  <Form.Item
                    label="Interest Name"
                    name="catname"
                    className="form-group"
                    rules={[
                      {
                        required: true,
                        message: 'Interest Name',
                      },
                    ]}
                  >
                    <Input placeholder="Interest Name" className="form-text" />
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: 'center' }}
                    className="formaction"
                  >
                    <input type="submit" value="Save" className="btn btn-outline-primary btn-lg"></input>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddInterests;