import React from 'react';

const PodcastPreview = (props) => {

  return (
      <div> 
     {!props.seepdf?<div style = {{textAlign:'center'}}>  <div  class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div></div>:<img src = {`${props.seepdf}`} style = {{width :"100%",height:"100%"}}/>}
    </div>
    
  );
};

export default PodcastPreview;