import React, { Component } from 'react';
import API from '../../../utils/API';
import { Modal, message } from 'antd'
import Sidebar from '../../../components/Sidebar/Sidebar';
import PodcastPreview from './PodcastPreview';
import ReactAudioPlayer from 'react-audio-player';
class UpdatePodcasts extends Component {


    state = {
        _id: this.props.match.params.id,
        podcastname: "",
        description: "",
        author: "",
        artistname: "",
        status: false,
        alias: "",
        podcast: "",
        coverimage: "",
        catlists: [],
        selectedCategory: "",
        podcastBase64: "",
        visible: false,
        coverImageBase64: "",
        visibleImage: false,
        loading: false,
        errorImage: false,
        errorDocument: false,
        showPodcast: "",
        duration: '',
        podcastOrder: undefined

    };
    componentDidMount() {

        this.getPodcastDetail()
        this.getCategoryList();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.seePodcast = this.seePodcast.bind(this);
        this.seeImage = this.seeImage.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        var daaa = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem("token")) {
            this.setState({
                author: daaa.email,
            });
        }

    };

    getPodcastDetail() {
        API.get('/podcasts/contents/node/' + this.state._id)
            .then(result => {
                //setCourseData(result.data.courses);
                //this.setState({courseData: result.data.courses});
                result.data.podcasts.map((course, index) => {
                    this.setState({ podcastname: course.podcastname });
                    this.setState({ description: course.description });
                    this.setState({ selectedCategory: course.selectedCategory });
                    this.setState({ podcast: course.podcast });
                    this.setState({ status: course.status });
                    this.setState({ artistname: course.artistname });
                    this.setState({ duration: course?.duration });
                    this.setState({ coverimage: course.coverimage })
                    this.setState({ alias: course.pathalias.alias.slice(1) })
                    this.setState({ podcastOrder: course?.podcastOrder !== undefined ? course.podcastOrder : '' })



                    if (course.status == 'true') {
                        this.setState({ status: true });
                    } else {
                        this.setState({ status: false });
                    }
                });
                API.get('/podcasts/getpodcast/' + this.state._id)
                    .then(result => {
                        this.setState({
                            podcastBase64: <ReactAudioPlayer
                                title={this.state.podcastname}
                                src={result?.data?.base64}
                                controls
                            />
                        })
                    })
                    .catch(e => {
                        Modal.error({
                            title: 'An error occurred',
                            content: e.message,
                        });
                    });
                API.get('/podcasts/getimagecover/' + this.state._id)
                    .then(result => {
                        this.setState({ coverImageBase64: result?.data?.base64 })
                    })
                    .catch(e => {
                        Modal.error({
                            title: 'An error occurred',
                            content: e.message,
                        });
                    });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }

    getCategoryList() {
        API.get('/categories/')
            .then(result => {
                const catlists = result.data.categories;
                this.setState({ catlists });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }
    handleClickPodcast = event => {
        document.getElementById("selectpodcast").click()
    };
    handleClickCover = event => {
        document.getElementById("selectcoverimage").click()
    }

    courseCateSelect = () => {
        if (this.state.selectedCategory != '') {
            return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                <option value="">Select Category</option>
                {this.state.catlists.map(catlist =>
                    <option
                        value={catlist._id}
                        selected={this.state.selectedCategory == catlist._id ? true : false}
                    >
                        {catlist.name}
                    </option>
                )
                }
            </select>
        } else {
            return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                <option value="">Select Category</option>
                {this.state.catlists?.map(catlist => <option value={catlist._id}>{catlist.name}</option>)}
            </select>

        }
    }

    handlePublish(event) {
        event.preventDefault();
        const courseData = this.state;
        this.setState({ loading: true })
        API.post('/podcasts/update', courseData)
            .then(result => {
                this.setState({ loading: false })
                this.props.history.goBack()
                message.success('Podcast updated', 3)
            })
            .catch(e => {
                this.setState({ loading: false })
                Modal.error({
                    title: 'An error occurred',
                    content: e.response.data.msg,
                });
            });
    }
    handleEditorChange = (event, editor) => {
        this.setState({ description: event });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }
    getImageBase64 = async (file) => {
        return await new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                if (event.target.result) {
                    resolve(event.target.result);
                } else {
                    reject('Please try again.');
                }
            };
        });
    }
    handleDocument = (event) => {
        this.setState({ errorDocument: false })
        if (event.target.files[0].size <= 104857600) {
            this.getImageBase64(event.target.files[0]).then(res => {
                this.setState({ podcast: res })
                this.setState({
                    podcastBase64: <ReactAudioPlayer
                        src={`${res}`}
                        controls
                    />
                })

            }).catch(error => {
                console.error(error);
            })
        } else {
            this.setState({ podcast: undefined })
            this.setState({ errorDocument: true })
        }
    }
    handleImage = (event) => {
        this.setState({ errorImage: false })
        if (event.target.files[0].size <= 10485760) {
            this.getImageBase64(event.target.files[0]).then(res => {

                this.setState({ coverImageBase64: res })
                this.setState({ coverimage: res })
            }).catch(error => {
                console.error(error);
            })
        } else {
            this.setState({ coverimage: undefined })
            this.setState({ errorImage: true })
        }
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }
    seePodcast() {
        this.setState({ visible: true })
    }
    seeImage() {
        this.setState({ visibleImage: true })
    }
    handleCancel() {
        this.setState({ visible: false })
        this.setState({ visibleImage: false })
    }
    handleChangePosition = (event) => {
        const { name, value } = event.target;
        if (event.target.value != "") {
            this.setState({
                [name]: parseInt(Math.abs(value)),
            })
        } else {
            this.setState({
                [name]: "",
            })
        }

    }
    render() {
        return (

            <div className="d-flex" id="wrapper">
                <Sidebar />

                <div id="page-content-wrapper">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Podcast</h4>

                            </div>
                            <div className="modal-body">
                                <div className="new-post">
                                    <form onSubmit={this.handlePublish}>
                                        <div className="form-group">
                                            <h5 className="l-duration">Podcast Order: </h5>
                                            <input
                                                className="form-control video-position"
                                                pattern="[0-9]"
                                                type="number"
                                                name="podcastOrder"
                                                min="1"
                                                value={this.state.podcastOrder}
                                                onChange={this.handleChangePosition}
                                                placeholder="Podcast order"
                                            />
                                        </div>
                                        <div class="alert alert-info">
                                            1. Order should be greater than 0
                                        </div>

                                        <div className="form-group">
                                            <h5 className="new-title">Podcast Name: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="podcastname"
                                                value={this.state.podcastname}
                                                onChange={this.handleChange}
                                                required
                                                placeholder="Podcast Name"
                                            />
                                        </div>

                                        <div>
                                            <h5 className="new-body">Podcast Description: </h5>
                                            <textarea
                                                style={{ height: '400px' }}
                                                className="form-control new-title"
                                                onChange={this.handleChange}

                                                value={this.state.description}
                                                name="description"
                                                placeholder=
                                                "Start typing podcast detail here..."


                                            />
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Author: </h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="artistname"
                                                value={this.state.artistname}
                                                onChange={this.handleChange}

                                                placeholder="Author Name"
                                            />
                                        </div>
                                        <div className="form-group col-md-6 p-0 mt-3">
                                            <h5 className="cat-select" >Category: <span className="requiredsymbol">*</span></h5>
                                            {this.courseCateSelect()}
                                        </div>



                                        <div className="form-group">
                                            <h5 className="new-title">Upload Podcast: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="file"
                                                accept="audio/*"
                                                onChange={this.handleDocument}
                                                hidden
                                                placeholder="Podcast Name"
                                                defaultValue={this.state.podcast}
                                                id="selectpodcasts"
                                            />

                                            <label class="text-white bg-secondary p-2 mb-2" htmlFor="selectpodcasts"> Update Podcast</label>
                                        </div>
                                        {this.state.errorDocument ? <div class="alert alert-danger">
                                            File size should be 100MB or less than 100MB
                                        </div> : <div class="alert alert-info">
                                            File size should be 100MB or less than 100MB
                                        </div>}

                                        {this.state.podcastBase64 ? <div>{this.state.podcastBase64}</div> : <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                        {/* <Modal
            visible={this.state.visible}
            okText="Submit"
            onCancel={this.handleCancel}
            centered="true"
            wrapClassName={'profileeditpop'}
            footer={null}

         >
            <BooksPreview seepdf={this.state.podcastBase64} />
         </Modal> */}
                                        <div className="form-group">
                                            <h5 className="new-title">Duration: </h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="duration"
                                                value={this.state.duration}
                                                onChange={this.handleChange}

                                                placeholder="ex. 8 min"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Upload banner image: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                onChange={this.handleImage}
                                                id="selectcoverimages"
                                                hidden
                                                defaultValue={this.state.coverimage}
                                                placeholder="Podcast Name"
                                            />
                                        </div>
                                        <label class="text-white bg-secondary p-2 mb-2" htmlFor="selectcoverimages"> Update banner image</label>
                                        {this.state.errorImage ? <div class="alert alert-danger">
                                            File size should be 10MB or less than 10MB
                                        </div> : <div class="alert alert-info">
                                            File size should be 10MB or less than 10MB
                                        </div>}

                                        <Modal
                                            visible={this.state.visibleImage}
                                            okText="Submit"
                                            onCancel={this.handleCancel}
                                            centered="true"
                                            wrapClassName={'profileeditpop'}
                                            footer={null}

                                        >
                                            <PodcastPreview seepdf={this.state.coverImageBase64} />
                                        </Modal>
                                        
                                        <div style={{ cursor: 'pointer', display:'inline-block',marginBlock:'20px' }} onClick={this.seeImage}><img src={this.state.coverImageBase64} style = {{width :"100px",height:"100px"}}/> Preview Banner Image</div>
                                        <div class="form-group">
                                            <label className="lable-title">Path <span className="requiredsymbol">*</span></label>
                                            <div className="input-box">
                                                <span class="prefix">/</span>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="alias"
                                                    value={this.state.alias}
                                                    onChange={this.handleChange}
                                                    required
                                                    placeholder="podcasts/my_podcasts"
                                                />
                                            </div>
                                        </div>
                                        <div style={{ marginLeft: "16px" }} className="form-group publish-box">
                                            <input type="checkbox" checked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label" htmlFor="customCheck1">Publish</label>
                                        </div>
                                        {this.state.loading && <div style={{ textAlign: 'center' }} class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                        <div className="form-group">
                                            <input disabled={this.state.loading || this.state.coverimage == undefined || this.state.podcast == undefined || (this.state.podcastOrder == "" || this.state.podcastOrder == undefined) ? false : this.state.podcastOrder < 1}
                                                type="submit"
                                                value="Update"
                                                className="btn btn-outline-primary btn-lg"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default UpdatePodcasts;