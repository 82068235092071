import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {Link} from "react-router-dom";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons/faUserAlt";
import Sidebar from '../../components/Sidebar/Sidebar';

const Dashboard = () => {

  return (
    <div>
        <div className="d-flex" id="wrapper">
            <Sidebar/>
            <div id="page-content-wrapper">
                <div className="container-fluid">
                    <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                    <h1 className="mt-2 text-primary">Dashboard</h1>
                    <div className="row px-2">
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-primary text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Users</h5>
                                    <p className="card-text">Manage the Users of the site.</p>
                                    <Link to="/users" className="btn btn-light"><FontAwesomeIcon className="text-primary" icon={faUserAlt}/> Go to Users</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-secondary text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Education</h5>
                                    <p className="card-text">Manage Education contents.</p>
                                    <Link to="/admin/educations" className="btn btn-light">Manage Education</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-info text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Blog</h5>
                                    <p className="card-text">Manage Blogs contents.</p>
                                    <Link to="/admin/blogs" className="btn btn-light">Manage Blog</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-dark text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Podcast</h5>
                                    <p className="card-text">Manage Podcast</p>
                                    <Link to="/admin/podcasts" className="btn btn-light">Manage Podcast</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-secondary text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Resources</h5>
                                    <p className="card-text">Manage Resources.</p>
                                    <Link to="/admin/resources" className="btn btn-light">Manage Resources</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-dark text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Books</h5>
                                    <p className="card-text">Manage Books related contents</p>
                                    <Link to="/admin/books" className="btn btn-light">Manage Books</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-info text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Around The Corner</h5>
                                    <p className="card-text">Manage Around The Corner</p>
                                    <Link to="/admin/around-corner" className="btn btn-light">Manage</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-secondary text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Forums</h5>
                                    <p className="card-text">Manage Forums, Forum Category</p>
                                    <Link to="/admin/forums" className="btn btn-light">Manage Forums</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-primary text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Basic Pages</h5>
                                    <p className="card-text">Manage Basic Pages</p>
                                    <Link to="/admin/pages" className="btn btn-light">Manage Pages</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-primary text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">FAQ's</h5>
                                    <p className="card-text">Manage FAQ</p>
                                    <Link to="/admin/faqs" className="btn btn-light">Manage FAQ's</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-dark text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">@ Group List</h5>
                                    <p className="card-text">Manage to add member to @ tag list</p>
                                    <Link to="/admin/taggroup" className="btn btn-light">Manage Group List</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-info text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Lender</h5>
                                    <p className="card-text">Manage Lender of website.</p>
                                    <Link to="/admin/manage/lender" className="btn btn-light">Manage</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-dark text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Dealers</h5>
                                    <p className="card-text">Manage Dealers related contents</p>
                                    <Link to="/admin/manage/dealer" className="btn btn-light">Manage</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 p-sm-2">
                            <div className="card bg-primary text-white shadow-lg">
                                <div className="card-body">
                                    <h5 className="card-title">Tratos</h5>
                                    <p className="card-text">Manage the tratos of the site.</p>
                                    <Link to="/deals" className="btn btn-light">Manage</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Dashboard;
