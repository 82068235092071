import React, { Component, Fragment } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import ReactDatatable from '@ashvin27/react-datatable';
import { Popconfirm, message } from "antd"
import API from '../../utils/API';
import Moment from 'moment';

/* import UserUpdateModal from "../partials/UserUpdateModal"; */
import { toast, ToastContainer } from "react-toastify";

class Deals extends Component {

    columns = [
        {
            key: "key",
            text: "",
            className: "id",
            align: "left",
            sortable: true,
        },
        {
            key: "title",
            text: "Title",
            className: "title capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "address",
            text: "Address",
            className: "address capitalize",
            align: "left",
            sortable: false,
        },
        {
            key: "availablefor",
            text: "Vacant",
            className: "vacant",
            align: "left",
            sortable: true
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            align: "left",
            sortable: true
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {record.status == 'Publish' && (
                            <Popconfirm
                                title="Are you sure to unpublish this Deal?"
                                onConfirm={this.unpublishConfirm}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button
                                    className="btn btn-danger btn-sm"
                                    title="Block"
                                    style={{ marginRight: '5px' }}
                                    onClick={() => this.blockUser(record)}>
                                    <i className="fa fa-ban"></i>
                                </button>
                            </Popconfirm>
                        )}
                        {record.status == 'Unpublish' && (
                            <Popconfirm
                                title="Are you sure to activate this deal?"
                                onConfirm={this.publishDeal}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button
                                    className="btn btn-success btn-sm"
                                    title="Unblock"
                                    style={{ marginRight: '5px' }}
                                    onClick={() => this.blockUser(record)}>
                                    <i className="fa fa-unlock"></i>
                                </button>
                            </Popconfirm>
                        )}
                    </Fragment>
                );
            }
        }
    ];

    config = {
        key_column: 'key',
        page_size: 10,
        length_menu: [10, 20, 50, 100],
        filename: "Deals",
        no_data_text: 'No deal found!',
        
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    state = {
        records: [],
        visible: false,
        currentRecord: {
            _id: '',
            title: '',
            address: '',
            availablefor: '',
            status: '',
        }

    };
    componentDidMount() {
        this.getData();
        this.viewRecord = this.viewRecord.bind(this)
        this.unpublishConfirm = this.unpublishConfirm.bind(this);
        this.publishDeal = this.publishDeal.bind(this);

    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {

        API.get("/users/deal-data")
            .then(res => {
                console.log('res: ',res)
                var dealsdata = [];
                res?.data?.deal.map((d, index) => {
                    dealsdata[index] = {
                        key: index + 1,
                        _id: d._id,
                        title: d.title,
                        address: d.address1+', '+d.address2+', '+d.state+ ', '+d.city+', '+d.zipcode,
                        availablefor: d.availablefor,
                        status: d.status == 1 ? 'Publish' : 'Unpublish',
                    }
                });
                this.setState({ records: dealsdata })
            })
            .catch()
    }

    viewRecord(record) {
        this.setState({ currentRecord: record });
        this.setState({ visible: true });
    }
    deleteRecord(record) {
        this.setState({ currentRecord: record });
    }
    blockUser(record) {
        this.setState({ currentRecord: record })
    }
    unpublishConfirm() {
        API.post("/users/dealunpublish", { id: this.state.currentRecord._id })
            .then(res => {
                if (res.status === 200) {
                    message.success('Deal Unpublish');
                    this.getData();
                }
            })
            .catch();
    }
    publishDeal() {
        API.post("/users/dealpublish", { id: this.state.currentRecord._id })
            .then(res => {
                if (res.status === 200) {
                    message.success('User Publish');
                    this.getData();
                }
            })
            .catch();
    }
    pageChange(pageData) {

    }


    render() {
        return (
            <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Deals Listing</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }
}


export default Deals;
