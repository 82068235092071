import React, { Component } from 'react';
import API from '../../../utils/API';
import { Modal, message } from 'antd'
import { withRouter } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import SponsorsPreview from './SponsorsPreview';
import SponsorImagePreview from './SponsorImagePreview';
class UpdateSponsors extends Component {


  state = {
    _id: this.props.match.params.id,
    sponsorname: "",
    description: "",
    author: "",
    /* artistname: "", */
    status: false,
    alias: "",
    sponsor: "",
    coverimage: "",
    catlists: [],
    selectedCategory: "",
    visible: false,
    coverImageBase64: "",
    visibleImage: false,
    loading: false,
    errorImage: false,
    errorDocument: false,
    sponsorUploadType: 'url',
    sponsorurl: undefined,
    duplicatesponsor: "",
    sponsorOrder: undefined,
    bannerImageBase64: "",
    bannerimage: "",
    errorBannerImage: false,
    visibleBannerImage: false,
    stateList : [],
    cityList : [],
    selectedState: '',
    selectedCity: ''
  };
  componentDidMount() {

    this.getSponsorDetail()
    this.getCategoryList();
    this.handleChange = this.handleChange.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleChangechk = this.handleChangechk.bind(this);
    this.seeImage = this.seeImage.bind(this)
    this.seeBannerImage = this.seeBannerImage.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleChangeUrl = this.handleChangeUrl.bind(this)
    var daaa = JSON.parse(localStorage.getItem('user'));
    if (localStorage.getItem("token")) {
      this.setState({
        author: daaa.email,
      });
    }
    API.get('/location/details/state').then(async result => {
      this.setState({stateList : result?.data?.statelist})
     }).catch(err => {
       console.log(err);
     })
  };

  getSponsorDetail() {
    API.get('/sponsors/contents/node/' + this.state._id)
      .then(result => {
        result.data.sponsors.map((sponsor, index) => {
          this.setState({ 
            sponsorname: sponsor.sponsorname,
            description: sponsor.description,
            selectedCategory: sponsor.selectedCategory,
            status: sponsor.status,
            sponsorurl: sponsor.sponsorurl,
            coverimage: sponsor.coverimage,
            bannerimage: sponsor.bannerimage,
            sponsorOrder: sponsor?.sponsorOrder !== undefined ? sponsor?.sponsorOrder : "",
            alias: sponsor.pathalias.alias.slice(1),
            selectedState: sponsor?.selectedState !== undefined ? sponsor?.selectedState : '',
            selectedCity: sponsor.selectedCity  !== undefined ? sponsor.selectedCity : ''
           }, function () {
            let match = this.state.selectedState.match(/\((.*?)\)/);
            if(match){
              let stateCode = match[1]
              API.get('/location/details/city/US/'+stateCode).then(async result => {
                this.setState({cityList: result.data.citylist});
              }).catch(err => {
                console.log(err);
              })
            }
          })
          if (sponsor.status == 'true') {
            this.setState({ status: true });
          } else {
            this.setState({ status: false });
          }
        });
        if (this?.state?.sponsor) {
          this.setState({ sponsorUploadType: 'url' })
          API.get('/sponsors/getsponsor/' + this.state._id)
            .then(result => {
              this.setState({ sponsorBase64: `data:application/pdf;base64,${result?.data?.base64}` })
            })
            .catch(e => {
              Modal.error({
                title: 'An error occurred',
                content: e.message,
              });
            });
        } else {
          this.setState({ sponsorUploadType: 'url' })
        }
        if (this?.state?.coverimage) {
          API.get('/sponsors/getcoverimage/' + this.state._id)
            .then(result => {

              this.setState({ coverImageBase64: result?.data?.base64})

            })
            .catch(e => {
              Modal.error({
                title: 'An error occurred',
                content: e.message,
              });
            });
        }
        if (this?.state?.bannerimage) {
            API.get('/sponsors/getbannerimage/' + this.state._id)
              .then(result => {
  
                this.setState({ bannerImageBase64: result?.data?.base64})
  
              })
              .catch(e => {
                Modal.error({
                  title: 'An error occurred',
                  content: e.message,
                });
              });
        }
      })
      .catch(e => {
        Modal.error({
          title: 'An error occurred',
          content: e.message,
        });
      });
  }

  getCategoryList() {
    API.get('/categories/')
      .then(result => {
        const catlists = result.data.categories;
        this.setState({ catlists });

      })
      .catch(e => {
        Modal.error({
          title: 'An error occurred',
          content: e.message,
        });
      });
  }

  courseCateSelect = () => {

    if (this.state.selectedCategory != '') {
      return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">

        <option value="">Select Category</option>
        {this.state.catlists.map(catlist =>
          <option
            value={catlist._id}
            key = {catlist._id}
            selected={this.state.selectedCategory == catlist._id ? true : false}
          >
            {catlist.name}
          </option>
        )
        }
      </select>
    } else {
      return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
        <option value="">Select Category</option>
        {this.state.catlists?.map(catlist => <option value={catlist._id} key={catlist._id}>{catlist.name}</option>)}
      </select>

    }
  }

  handlePublish(event) {

    if (this.state.sponsorUploadType == 'url' && this.state.sponsor !== undefined) {
      this.setState({ sponsor: undefined })
      this.setState({ loading: true })
      event.preventDefault();

      const courseData = this.state;
      API.post('/sponsors/update-sponsorurl', courseData)
        .then(result => {
          this.setState({ loading: false })

          this.props.history.goBack()
          this.setState({ loading: false })
          message.success('Sponsor updated', 3)
        })
        .catch(e => {
          this.setState({ loading: false })
          Modal.error({
            title: 'An error occurred',
            content: e.response.data.msg,
          });
        });

    } else {
      this.setState({ loading: true })
      event.preventDefault();

      const courseData = this.state;
      API.post('/sponsors/update', courseData)
      .then(result => {
        this.setState({ loading: false })

        this.props.history.goBack()
        this.setState({ loading: false })
        message.success('Sponsor updated', 3)
      })
      .catch(e => {
        this.setState({ loading: false })
        Modal.error({
          title: 'An error occurred',
          content: e.response.data.msg,
        });
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });

  }
  handleChangeUrl = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });

  }
  getImageBase64 = async (file) => {
    return await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (event.target.result) {
          resolve(event.target.result);
        } else {
          reject('Please try again.');
        }
      };
    });
  }
  handleImage = (event) => {
    if (event.target.files[0].size <= 10485760) {
      this.getImageBase64(event.target.files[0]).then(res => {
        this.setState({ coverImageBase64: res })
        this.setState({ coverimage: res })
      }).catch(error => {
        console.error(error);
      })
    } else {
      this.setState({ errorImage: true })
      this.setState({ coverimage: undefined })
    }
  }
  handleBannerImage = (event) => {
    if (event.target.files[0].size <= 10485760) {
      this.getImageBase64(event.target.files[0]).then(res => {
        this.setState({ bannerImageBase64: res })
        this.setState({ bannerimage: res })
      }).catch(error => {
        console.error(error);
      })
    } else {
      this.setState({ errorBannerImage: true })
      this.setState({ bannerimage: undefined })
    }
  }
  handleChangechk(event) {
    this.setState({ status: !this.state.status });
  }
  seeImage() {
    this.setState({ visibleImage: true })
  }
  seeBannerImage() {
    this.setState({ visibleBannerImage: true })
  }
  handleCancel() {
    this.setState({ visible: false })
    this.setState({ visibleImage: false, visibleBannerImage: false })
  }
  handleChangePosition = (event) => {
    const { name, value } = event.target;
    if (event.target.value != "") {
      this.setState({
        [name]: parseInt(Math.abs(value)),
      })
    } else {
      this.setState({
        [name]: "",
      })
    }

  }
  handleStateChange = (event) => {
    const { name, value } = event.target;
    let match = value.match(/\((.*?)\)/);
    this.setState({
      [name]: value,
      cityList: [],   // Reset cityList to an empty array
      selectedCity: "" // Reset selected city
    });
    if(match){
      let stateCode = match[1]
      API.get('/location/details/city/US/'+stateCode).then(async result => {
        this.setState({cityList: result.data.citylist});
      }).catch(err => {
        console.log(err);
      })
    }
  }
  render() {
    const {cityList} = this.state;
    return (
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Sponsor</h4>
              </div>
              <div className="modal-body">
                <div className="new-post">
                  <form onSubmit={this.handlePublish}>
                    <div className="form-group">
                      <h5 className="l-duration">Sponsor Order: </h5>
                      <input
                        className="form-control video-position"
                        pattern="[0-9]"
                        type="number"
                        min="1"
                        name="sponsorOrder"
                        value={this.state.sponsorOrder}
                        onChange={this.handleChangePosition}
                        placeholder="Sponsor order"
                      />
                    </div>
                    <div className="alert alert-info">
                      1. Order should be greater than 0
                    </div>
                    <div className="form-group">
                      <h5 className="new-title">Sponsor Name: <span className="requiredsymbol">*</span></h5>
                      <input
                        className="form-control new-title"
                        type="text"
                        name="sponsorname"
                        value={this.state.sponsorname}
                        onChange={this.handleChange}
                        required
                        placeholder="Sponsor Name"
                      />
                    </div>

                    <div>
                      <h5 className="new-body">Sponsor Description: </h5>
                      <textarea
                        style={{ height: '400px' }}
                        className="form-control new-title"
                        onChange={this.handleChange}

                        value={this.state.description}
                        name="description"
                        placeholder=
                        "Start typing sponsor detail here..."


                      />
                    </div>
                    <div className="form-group col-md-6 p-0 mt-3">
                      <h5 className="cat-select" >Category: <span className="requiredsymbol">*</span></h5>
                      {this.courseCateSelect()}
                    </div>
                    <div className="form-group col-md-6 p-0 mt-3">
                      <h5 className="state-city-select" >Location <span className="requiredsymbol">*</span></h5>
                      <select id="state-city-select" className="custom-select" required onChange={this.handleStateChange} name="selectedState">
                        <option value="" key="0">Select State</option>
                        {this.state.stateList.map ( (res, key) => <option  key = {key} iso = {res.isoCode} value= {res.name + (' ('+res.isoCode.toUpperCase()+')')} selected={this.state.selectedState == res.name + (' ('+res.isoCode.toUpperCase()+')') ? true : false}>{res.name} ({res.isoCode})</option>)}
                      </select>
                      <select id="city-select" className="custom-select" required onChange={this.handleChange} name="selectedCity">
                      <option value="" key="0">Select City</option>
                      {cityList.map((c, k) => (
                        <option key={k} value={c.name} selected={this.state.selectedCity == c.name ? true : false}>{c.name}</option>
                      ))}
                      </select>
                    </div>
                    <div className="form-group">
                    </div>
                    <div className="form-group">
                      <h5 className="new-title">Sponsor URL:</h5>
                      <input
                        className="form-control new-title"
                        type="url"
                        name="sponsorurl"
                        value={this.state.sponsorurl}
                        onChange={this.handleChangeUrl}

                        placeholder="Sponsor url (ex. https://example..)"
                      />
                    </div>
                    <div className="form-group">
                      <h5 className="new-title">Upload thumbnail image: <span className="requiredsymbol">*</span></h5>
                      <input
                        className="form-control new-title"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={this.handleImage}
                        id="selectcoverimages"
                        defaultValue={this.state.coverimage}
                        placeholder="Sponsor Name"
                        hidden
                      />
                    </div>
                    <label className="text-white bg-secondary p-2 mb-2" htmlFor="selectcoverimages"> Update thumbnail image</label>
                    {this.state.errorImage ? <div className="alert alert-danger">
                      File size should be 10MB or less than 10MB
                    </div> : <div className="alert alert-info">
                      File size should be 10MB or less than 10MB
                    </div>}
                    <Modal
                      visible={this.state.visibleImage}
                      okText="Submit"
                      onCancel={this.handleCancel}
                      centered="true"
                      wrapClassName={'profileeditpop'}
                      footer={null}

                    >
                      <SponsorImagePreview seepdf={this.state.coverImageBase64} />
                    </Modal>
                    <div style={{ cursor: 'pointer', display:'inline-block',marginBlock:'20px' }} onClick={this.seeImage}><img src={this.state.coverImageBase64} style = {{width :"100px",height:"100px"}}/> Preview Thumbnail Image</div>

                    {/* Banner Image */}
                    <div className="form-group">
                      <h5 className="new-title">Upload banner image: <span className="requiredsymbol">*</span></h5>
                      <input
                        className="form-control new-title"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={this.handleBannerImage}
                        id="selectbannerimages"
                        defaultValue={this.state.bannerimage}
                        placeholder="Sponsor Banner Image"
                        hidden
                      />
                    </div>
                    <label className="text-white bg-secondary p-2 mb-2" htmlFor="selectbannerimages"> Update banner image</label>
                    {this.state.errorImage ? <div className="alert alert-danger">
                      File size should be 10MB or less than 10MB
                    </div> : <div className="alert alert-info">
                      File size should be 10MB or less than 10MB
                    </div>}
                    <Modal
                      visible={this.state.visibleBannerImage}
                      okText="Submit"
                      onCancel={this.handleCancel}
                      centered="true"
                      wrapClassName={'profileeditpop'}
                      footer={null}

                    >
                      <SponsorImagePreview seepdf={this.state.bannerImageBase64} />
                    </Modal>
                    <div style={{ cursor: 'pointer', display:'inline-block',marginBlock:'20px' }} onClick={this.seeBannerImage}><img src={this.state.bannerImageBase64} style = {{width :"100px",height:"100px"}}/> Preview Banner Image</div>

                    <div className="form-group">
                      <label className="lable-title">Path <span className="requiredsymbol">*</span></label>
                      <div className="input-box">
                        <span className="prefix">/</span>
                        <input
                          className="form-control"
                          type="text"
                          name="alias"
                          value={this.state.alias}
                          onChange={this.handleChange}
                          required
                          placeholder="sponsors/my_sponsors"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: "16px" }}className="form-group publish-box">
                      <input type="checkbox" checked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                      <label className="custom-control-label" htmlFor="customCheck1">Publish</label>
                    </div>
                    {this.state.loading && <div style={{ textAlign: 'center' }} className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>}
                    <div className="form-group">
                      <input disabled={this.state.loading || this.state.coverimage == undefined || (this.state.sponsorOrder == "" || this.state.sponsorOrder == undefined) ? false : this.state.sponsorOrder < 1}
                        type="submit"
                        value="Update"
                        className="btn btn-outline-primary btn-lg"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    );
  }
}

export default withRouter(UpdateSponsors);