import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import Sidebar from '../../components/Sidebar/Sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import { Modal, Popconfirm, message, Button, Row, Col } from 'antd';
import API from '../../utils/API';
import AddCategory from './AddCategory';
import Moment from 'moment';


class Category extends Component {
  state = {
    datalists: [],
    currentRecord: {
      _id: '',
      name: '',
      createdAt: '',
    },
    updatevisible: false,
    name: '',
    topcategory: false,
    loading: false,
    subcategoryvisible: false,
    subcatname: '',
    subcatdesc: '',
    hideSubcategories: false,
    subcategorylist: [],
    updateSubCategory: false,
    subCatData: [],
    topsupcategory: false,
  }
  columns = [
    {
      key: "_id",
      text: "Id",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Category Name",
      className: "capitalize",
      align: "left",
      sortable: true,
    },
    {
      key: "subcategoryview",
      text: "Subcategory",
      className: "capitalize text-center",
      align: "left",
      sortable: true,
    },

    {
      key: "createdAt",
      text: "Created Date",
      className: "date",
      align: "left",
      sortable: true
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: datalist => {
        return (
          <Fragment>
            <Button
              style={{ marginRight: '5px' }}
              onClick={() => this.editRecord(datalist)}
              className="btn btn-primary btn-sm">
              <i className="fa fa-edit"></i>
            </Button>
            <Popconfirm
              title="Are you sure to delete this Category?"
              onConfirm={this.confirm}
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"

            >
              <button
                className="btn btn-danger btn-sm"
                title="Delete"
                onClick={() => this.deleteRecord(datalist)}>
                <i className="fa fa-trash"></i>
              </button>
            </Popconfirm>
            <Button
              style={{ marginLeft: '5px' }}
              title="Add Subcategory"
              onClick={() => this.addSubcategory(datalist)}
              className="btn btn-primary btn-sm">
              <FontAwesomeIcon icon={faList} />
            </Button>
          </Fragment>
        );
      }
    }
  ];
  config = {
    key_column: '_id',
    page_size: 10,
    length_menu: [10, 20, 50, 100],
    filename: "Forum Category",
    no_data_text: 'No Page found!',

    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last"
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  componentDidMount() {
    this.getData();
    this.confirm = this.confirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.hideSubcategoriesList = this.hideSubcategoriesList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFinishRequest = this.onFinishRequest.bind(this);
    this.onFinishSubcat = this.onFinishSubcat.bind(this);
    this.onFinishUpdateSubcat = this.onFinishUpdateSubcat.bind(this);
    this.onCancelSubCat = this.onCancelSubCat.bind(this);
    this.handleChangechk = this.handleChangechk.bind(this);
    this.handleChangechksubcat = this.handleChangechksubcat.bind(this);
  };
  componentWillReceiveProps(nextProps) {
    this.getData()
  }

  getData() {
    API.get('/categories/')
      .then(result => {
        const catlists = result.data.categories;
        var catdata = [];
        catlists.map((cat, index) => {
          catdata[index] = {
            _id: cat._id,
            name: cat.name,
            topcategory: (cat.topcategory ? true : false),
            subcategoryview: (cat.subcategories.length > 0 ?
              <Button
                className="btn btn-primary btn-sm"
                onClick={() => this.showSubcategory(cat)}
              >
                View Subcategory List
              </Button> : ''),
            createdAt: Moment(cat.createdAt).format('MMM D, YYYY')
          }
        });
        this.setState({ datalists: catdata })
      })
      .catch(e => {
        Modal.error({
          title: 'An error occurred',
          content: e.message,
        });
      });
  }
  showSubcategory(cat) {
    this.setState({ subcategorylist: cat.subcategories });
    this.setState({ hideSubcategories: true });
  }
  editRecord(datalist) {
    this.setState({ currentRecord: datalist });
    this.setState({ updatevisible: true });
    this.setState({ name: datalist.name });
    this.setState({ topcategory: datalist.topcategory });
  }
  addSubcategory(datalist) {
    this.setState({ currentRecord: datalist });
    this.setState({ subcategoryvisible: true });
  }
  deleteRecord(datalist) {
    this.setState({ currentRecord: datalist });
  }
  confirm() {
    API.post("/categories/cat-delete", { id: this.state.currentRecord._id })
      .then(res => {
        if (res.status === 200) {
          this.getData();
          message.success('Forum Category deleted');
        }
      })
      .catch(e => {
        Modal.error({
          title: 'An error occurred',
          content: e.message,
        });
      });
  }
  onCancel = () => {
    this.setState({ updatevisible: false });
    this.setState({ subcategoryvisible: false });
    this.setState({ subcatname: '' });
    this.setState({ subcatdesc: '' });
  }
  hideSubcategoriesList = () => {
    this.setState({ hideSubcategories: false });
    this.setState({ subcategorylist: [] });
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onFinishRequest(event) {
    event.preventDefault();
    this.setState({ loading: true })
    API.post('/categories/update', {
      id: this.state.currentRecord._id,
      name: this.state.name,
      topcategory: this.state.topcategory,
    })
      .then(result => {
        this.setState({ loading: false });
        this.setState({ updatevisible: false });
        message.success('Forum Category Updated');
        this.getData();
      })
      .catch(e => {
        this.setState({ loading: false });
        this.setState({ updatevisible: false });
        Modal.error({
          title: 'An error occurred',
          content: e.response.data.msg,
        });
      });
  }
  onFinishSubcat(event) {
    event.preventDefault();
    this.setState({ loading: true });
    this.setState({ updatevisible: false });
    API.post('/subcategories/add', {
      name: this.state.subcatname,
      description: this.state.subcatdesc,
      category: this.state.currentRecord._id,
      topcategory: this.state.topsupcategory,
    })
      .then(result => {
        this.setState({ loading: false });
        this.setState({ subcategoryvisible: false });
        message.success('Subcategory Added');
        this.setState({ subcatname: '' });
        this.setState({ subcatdesc: '' });
        this.getData();
      })
      .catch(e => {
        this.setState({ loading: false });
        this.setState({ subcategoryvisible: false });
        Modal.error({
          title: 'An error occurred',
          content: e.response.data.msg,
        });
      });

  }
  onFinishUpdateSubcat(data) {
    API.post('/subcategories/update', {
      name: this.state.subcatname,
      description: this.state.subcatdesc,
      id: data._id,
      topcategory: this.state.topsupcategory,
    })
      .then(result => {
        this.setState({ loading: false });
        this.setState({ updateSubCategory: false });
        message.success('Subcategory Added');
        this.setState({ subcatname: '' });
        this.setState({ subcatdesc: '' });
        this.getData();
      })
      .catch(e => {
        this.setState({ loading: false });
        this.setState({ updateSubCategory: false });
        Modal.error({
          title: 'An error occurred',
          content: e.response.data.msg,
        });
      });

  }
  cancel(e) {
  }
  onCancelSubCat() {
    this.setState({ updateSubCategory: false })
  }

  pageChange(pageData) {

  }

  handleChangechk(event) {
    this.setState({ topcategory: !this.state.topcategory });
  }
  handleChangechksubcat(event) {
    this.setState({ topsupcategory: !this.state.topsupcategory });
  }
  render() {
    const updatePopup = () => {
      if (this.state.currentRecord._id) {
        return <Modal
          visible={this.state.updatevisible}
          okText="Update"
          onCancel={this.onCancel}
          onOk={this.onFinishRequest}
          centered="true"
          closable={false}
          footer={null}
        >
          <form onSubmit={this.onFinishRequest}>
            <h3 className="mt-2 text-primary">Update Category</h3>
            <div className="form-group">
              <h5 className="new-title">Category Name </h5>
              <input
                className="form-control"
                type="text"
                name="name"
                value={this.state.name || ''}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input type="checkbox" checked={this.state.topcategory} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
              <label style={{ marginLeft: "16px" }} className="custom-control-label" for="customCheck1">Top Category</label>
            </div>
            <div className="form-group">
              <input
                type="button"
                value="Cancel"
                className="btn"
                onClick={this.onCancel}
              />
              <input
                type="submit"
                value="Update"
                disabled={this.state.loading}
                className="btn btn-outline-primary btn-lg"
              />

            </div>
          </form>
        </Modal>
      }
    }
    const addSubcatPopup = () => {
      if (this.state.currentRecord._id) {
        return <Modal
          visible={this.state.subcategoryvisible}
          centered="true"
          closable={false}
          footer={null}
        >
          <form onSubmit={this.onFinishSubcat}>
            <h3 className="mt-2 text-primary">Add Subcategory</h3>
            <div className="form-group">
              <h5 className="new-title">Subcategory Name </h5>
              <input
                className="form-control"
                type="text"
                name="subcatname"
                value={this.state.subcatname || ''}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <h5 className="new-title">Subcategory Description </h5>
              <textarea
                style={{ height: '400px' }}
                className="form-control"
                type="text"
                name="subcatdesc"
                value={this.state.subcatdesc || ''}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input type="checkbox" defaultChecked={this.state.topsupcategory} onChange={this.handleChangechksubcat} className="custom-control-input" id="customCheck1" />
              <label style={{ marginLeft: "16px" }} className="custom-control-label" for="customCheck1">Top Category</label>
            </div>
            <div className="form-group">
              <input
                type="button"
                value="Cancel"
                className="btn"
                onClick={this.onCancel}
              />
              <input
                type="submit"
                value="Save"
                disabled={this.state.loading}
                className="btn btn-outline-primary btn-lg"
              />

            </div>
          </form>
        </Modal>
      }
    }
    const updateSubcatPopup = () => {

      return <Modal
        visible={this.state.updateSubCategory}
        centered="true"
        closable={false}
        footer={null}
      >
        <form onSubmit={() => this.onFinishUpdateSubcat(this.state.subCatData)}>
          <h3 className="mt-2 text-primary">Update Subcategory</h3>
          <div className="form-group">
            <h5 className="new-title">Subcategory Name </h5>
            <input
              className="form-control"
              type="text"
              name="subcatname"
              defaultValue={this.state.subCatData.name || ''}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <h5 className="new-title">Subcategory Description </h5>
            <textarea
              style={{ height: '400px' }}
              className="form-control"
              type="text"
              name="subcatdesc"
              defaultValue={this.state.subCatData.description || ''}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input type="checkbox" checked={this.state.topsupcategory} onChange={this.handleChangechksubcat} className="custom-control-input" id="customCheck2" />
            <label style={{ marginLeft: "16px" }} className="custom-control-label" for="customCheck2">Top Category</label>
          </div>
          <div className="form-group">
            <input
              type="button"
              value="Cancel"
              className="btn"
              onClick={this.onCancelSubCat}
            />
            <input
              type="submit"
              value="Update"
              disabled={this.state.loading}
              className="btn btn-outline-primary btn-lg"
            />

          </div>
        </form>
      </Modal>

    }
    const subcategoriesList = () => {
      if (this.state.subcategorylist.length > 0) {
        return <Modal
          visible={this.state.hideSubcategories}
          okText="Ok"
          onOk={this.hideSubcategoriesList}
          keyboard="false"
          centered="true"
          cancelButtonProps={{
            style: {
              display: "none",
            },
          }}
          closable={false}
        >
          <div>
            <ol>
              {this.state.subcategorylist.map(list =>
                <Row class="mt-16">
                  <Col><li >{list.name}</li></Col>
                  <Col><div style={{ cursor: 'pointer', color: '#1890ff' }} class=" ml-5" onClick={() => updateSubCat(list)}>update</div></Col>

                </Row>
              )}
            </ol>



          </div>
        </Modal>
      }
    }
    const updateSubCat = (subcat) => {

      this.setState({ subCatData: subcat })
      this.setState({ subcatname: subcat.name })
      this.setState({ subcatdesc: subcat.description })
      this.setState({ topsupcategory: subcat.topcategory })
      this.setState({ updateSubCategory: true })

    }
    return (
      <div>
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <AddCategory />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
              <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-category-modal">Add Category</button>
              <h1 className="mt-2 text-primary">Category</h1>
              <ReactDatatable
                config={this.config}
                records={this.state.datalists}
                columns={this.columns}
                onPageChange={this.pageChange.bind(this)}
              />
            </div>
          </div>
          {updatePopup()}
          {addSubcatPopup()}
          {subcategoriesList()}
          {this.state.updateSubCategory && updateSubcatPopup()}
        </div>
      </div>
    );
  }
};

export default Category;