import React, { Component, Fragment } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import API from '../../../utils/API';
import {Modal, Popconfirm, Button, Table} from 'antd';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DealerInvite from './DealerInvite';
import DealerDeal from './DealerDeal'
import './dealer.css';
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Invitation Status',
    dataIndex: 'invitationaccept',
  },
  {
    title: 'Total Deals',
    dataIndex: 'totalDeals'
  },
  {
    title: 'Sold Deals',
    dataIndex: 'soldDeals'
  }
];
class Dealer extends Component{
  state ={
    loaded: false,
    dealerinvite: false
  }
  dataSource = [];
  dataSourceGrouped=[];

  componentDidMount() {
    this.getData();
  };
  getData() {
    this.dataSourceGrouped = [];
    let d = [];
    API.get('/users/fetchtata/dealer')
    .then(result => {
      console.log('result: ',result)
      if(result?.data?.users){
        result.data.users.map((u,key)=>{
          this.dataSourceGrouped.push({
            key: key,
            name: u?.firstname+' '+u?.lastname,
            email: u?.email,
            invitationaccept:<>{u.dealerinvitationaccept != true ?
              <Popconfirm
                title="Resend invitation link?"
                onConfirm={() => this.resendLink(u?.email,u?.firstname,u?.lastname )}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Resend invitation</Button>
              </Popconfirm>
              :<div className='l-status'> <span className='green'>Accepted</span></div>}</>
          });
          if(key+1 == result.data.users.length){
            this.setState({loaded:true})
          }
        })
      }
    })
    .catch(e => {
      Modal.error({
        title: 'An error occurred',
        content: e.message,
      });
    });
  }
  resendLink = (email,firstname,lastname) => {
    API.post('/users/b/dealer', {
      email: email.toLowerCase().trim(),
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      username: email.toLowerCase().trim(), //assign email as username
    }).then((res) => {
      Modal.success({
        content: 'Invitation Link Sent successfully',
      });
    })
  }
  render(){
    const dataSourceGrouped = this.dataSourceGrouped;
    return (
      <div>
          <div className="d-flex" id="wrapper">
              <Sidebar/>
              <div id="page-content-wrapper">
                  <div className="container-fluid">
                      <h1 className="mt-2 text-primary">Dealers List</h1>
                      <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-user-modal" onClick={(e) => {this.setState({'dealerinvite': true})}}><FontAwesomeIcon icon={faPlus} /> Invite Dealer</button>
                      <div style={{ display: 'block', padding: 30, margin: 'auto', }}></div>

                      <div>
                      {this.state.loaded && <Table 
                        dataSource={dataSourceGrouped}
                        columns={columns}
                        className='responsive-table'
                        pagination={{
                          position: ['none', 'bottomCenter'],
                          pageSize:10,
                        }}
                        expandable={{
                          expandedRowRender: (record) => <div style={{ margin: 0 }}>{record.expendDataDetail}</div>,
                          expandIcon: ({ expanded, onExpand, record }) =>
                          expanded ? (
                            record.interestedLoan > 0 && <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                          ) : (
                            record.interestedLoan > 0 && <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                          )
    
                        }}
                        />}
                      </div>
                  </div>
                  <Modal
                  visible={this.state.dealerinvite}
                  footer={null}
                  onCancel={(e)=>this.setState({dealerinvite:false})}
                  destroyOnClose={true}>
                    <DealerInvite/>
                  </Modal>
              </div>
          </div>
      </div>
    );
  }

};

export default Dealer;

