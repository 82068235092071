import React, { Component } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from '../../../utils/API';
import { message, Modal } from 'antd'
import { withRouter } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import BlogImagePreview from './BlogImagePreview';
class UpdateBlogs extends Component {


    state = {
        _id: this.props.match.params.id,
        blogname: "",
        description: "",
        author: "",
        artistname: "",
        status: false,
        alias: "",
        coverimage: "",
        catlists: [],
        selectedCategory: "",
        blogBase64: "",
        visible: false,
        coverImageBase64: "",
        visibleImage: false,
        loading: false,
        error: false,
        blogOrder: undefined


    };
    componentDidMount() {

        this.getBlogDetail()
        this.getCategoryList();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.seeImage = this.seeImage.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        var daaa = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem("token")) {
            this.setState({
                author: daaa.email,
            });
        }

    };

    getBlogDetail() {
        API.get('/blogs/contents/node/' + this.state._id)
            .then(result => {
                //setCourseData(result.data.courses);
                //this.setState({courseData: result.data.courses});
                result.data.blogs.map((course, index) => {
                    this.setState({ blogname: course.blogname });
                    this.setState({ description: course.description });
                    this.setState({ selectedCategory: course.selectedCategory });
                    this.setState({ status: course.status });
                    this.setState({ artistname: course.artistname });
                    this.setState({ coverimage: course.coverimage })
                    this.setState({ blogOrder: course?.blogOrder !== undefined ? course?.blogOrder : '' })
                    this.setState({ alias: course.pathalias.alias.slice(1) })


                    if (course.status == 'true') {
                        this.setState({ status: true });
                    } else {
                        this.setState({ status: false });
                    }
                });

                API.get('/blogs/getimagecover/' + this.state._id)
                    .then(result => {
                        this.setState({ coverImageBase64:result.data.base64})
                    })
                    .catch(e => {
                        Modal.error({
                            title: 'An error occurred',
                            content: e.message,
                        });
                    });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }

    getCategoryList() {
        API.get('/categories/')
            .then(result => {
                const catlists = result.data.categories;
                this.setState({ catlists });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }
    handleClickCover = event => {
        document.getElementById("selectcoverimage").click()
    }

    courseCateSelect = () => {
        if (this.state.selectedCategory != '') {
            return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                <option value="">Select Category</option>
                {this.state.catlists.map(catlist =>
                    <option
                        value={catlist._id}
                        selected={this.state.selectedCategory == catlist._id ? true : false}
                    >
                        {catlist.name}
                    </option>
                )
                }
            </select>
        } else {
            return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                <option value="">Select Category</option>
                {this.state.catlists?.map(catlist => <option value={catlist._id}>{catlist.name}</option>)}
            </select>

        }
    }

    handlePublish(event) {
        event.preventDefault();
        this.setState({ loading: true })
        const courseData = this.state;
        API.post('/blogs/update', courseData)
            .then(result => {
                this.props.history.goBack()
                this.setState({ loading: false })
                message.success('Blog updated', 3)
            })
            .catch(e => {
                this.setState({ loading: false })
                // $('#add-book-modal').modal('hide');
                Modal.error({
                    title: 'An error occurred',
                    content: e.response.data.msg,
                });
            });
    }
    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({ description: data });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }
    handleChangePosition = (event) => {
        const { name, value } = event.target;
        if (event.target.value != "") {
            this.setState({
                [name]: parseInt(Math.abs(value)),
            })
        } else {
            this.setState({
                [name]: "",
            })
        }

    }
    getImageBase64 = async (file) => {
        return await new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                if (event.target.result) {
                    resolve(event.target.result);
                } else {
                    reject('Please try again.');
                }
            };
        });
    }

    handleImage = (event) => {

        if (event.target.files[0].size <= 10485760) {
            // setUpload(false)
            //   setImg({
            //       src: URL.createObjectURL(e.target.files[0]),
            //       alt: e.target.files[0].name
            //   }); 


            this.getImageBase64(event.target.files[0]).then(res => {

                this.setState({ coverImageBase64: res })
                this.setState({ coverimage: res })
            }).catch(error => {
                console.error(error);
            })
        } else {
            this.setState({ error: true })
            this.setState({ coverimage: undefined })
        }
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }

    seeImage() {
        this.setState({ visibleImage: true })
    }
    handleCancel() {
        this.setState({ visible: false })
        this.setState({ visibleImage: false })
    }

    render() {
        return (

            <div className="d-flex" id="wrapper">
                <Sidebar />

                <div id="page-content-wrapper">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Blog</h4>

                            </div>
                            <div className="modal-body">
                                <div className="new-post">
                                    <form onSubmit={this.handlePublish}>
                                        <div className="form-group">
                                            <h5 className="l-duration">Blog Order: </h5>
                                            <input
                                                className="form-control video-position"
                                                pattern="[0-9]"
                                                min="1"
                                                type="number"
                                                name="blogOrder"
                                                value={this.state.blogOrder}
                                                onChange={this.handleChangePosition}
                                                placeholder="Blog order"
                                            />
                                        </div>
                                        <div class="alert alert-info">
                                            1. Order should be greater than 0
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Blog Name: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="blogname"
                                                value={this.state.blogname}
                                                onChange={this.handleChange}
                                                required
                                                placeholder="Blog Name"
                                            />
                                        </div>

                                        <div>
                                            <h5 className="new-body">Blog Description: </h5>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onChange={this.handleEditorChange}
                                                data={this.state.description}
                                                config={{
                                                    placeholder:
                                                        "Start typing course detail here...",
                                                    toolbar: [
                                                        "Heading",
                                                        "|",
                                                        "Bold",
                                                        "Italic",
                                                        "Link",
                                                        "NumberedList",
                                                        "BulletedList",
                                                        "|",
                                                        "BlockQuote",
                                                        "InsertTable",
                                                        "Undo",
                                                        "Redo",
                                                    ],
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Author: </h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="artistname"
                                                value={this.state.artistname}
                                                onChange={this.handleChange}
                                                placeholder="Author Name"
                                            />
                                        </div>
                                        <div className="form-group col-md-6 p-0 mt-3">
                                            <h5 className="cat-select" >Category: <span className="requiredsymbol">*</span></h5>
                                            {this.courseCateSelect()}
                                        </div>
                                        <div class="form-group">

                                            {/* <div>
                        <input type="radio" value="free" name="visibilityoption" id="free" required onChange={this.handleChange}/>
                        <label htmlFor="free">Free</label>
                        <input type="radio" value="paid" name="visibilityoption" id="paid" onChange={this.handleChange}/>
                        <label htmlFor="paid">Paid</label>
                    </div> */}
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Upload banner image: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                onChange={this.handleImage}
                                                id="selectcoverimages"
                                                hidden
                                                defaultValue={this.state.coverimage}
                                                placeholder="Blog Name"
                                            />
                                        </div>
                                        <label class="text-white bg-secondary p-2 mb-2" htmlFor="selectcoverimages"> Update banner image</label>
                                        {this.state.errorDocument ? <div class="alert alert-danger">
                                            File size should be 10MB or less than 10MB
                                        </div> : <div class="alert alert-info">
                                            File size should be 10MB or less than 10MB
                                        </div>}
                                        <Modal
                                            visible={this.state.visibleImage}
                                            okText="Submit"
                                            onCancel={this.handleCancel}
                                            centered="true"
                                            wrapClassName={'profileeditpop'}
                                            footer={null}

                                        >
                                            <BlogImagePreview seepdf={this.state.coverImageBase64} />
                                        </Modal>
                                        <div style={{ cursor: 'pointer', display:'inline-block',marginBlock:'20px' }} onClick={this.seeImage}>
                                            <img src={this.state.coverImageBase64} style = {{width :"100px",height:"100px"}}/>Preview Banner Image
                                        </div>
                                        <div class="form-group">
                                            <label className="lable-title">Path <span className="requiredsymbol">*</span></label>
                                            <div className="input-box">
                                                <span class="prefix">/</span>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="alias"
                                                    value={this.state.alias}
                                                    onChange={this.handleChange}
                                                    required
                                                    placeholder="blogs/my_blogs"
                                                />
                                            </div>
                                        </div>
                                        <div style={{ marginLeft: "16px" }} className="form-group publish-box">
                                            <input type="checkbox" checked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label" htmlFor="customCheck1">Publish</label>
                                        </div>
                                        {this.state.loading && <div style={{ textAlign: 'center' }} class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                        <div className="form-group">
                                            <input disabled={this.state.loading || (this.state.blogOrder == "" || this.state.blogOrder == undefined) ? false : this.state.blogOrder < 1}
                                                type="submit"
                                                value="Update"
                                                className="btn btn-outline-primary btn-lg"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default withRouter(UpdateBlogs);