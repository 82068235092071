import React, { Component } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from '../../utils/API';
import { Modal } from 'antd'
import './css/edu.css';
import $ from 'jquery';

class AddEducation extends Component {
    state = {
        title: "",
        body: undefined,
        author: "",
        status: false,
        alias: "",
        videos: [{ videoTitle: "", videoProgressTitle: "", videoDescription: "", videoDuration: "", videoURL: "", videoKeyTakeaway: "", videoPosition: "" }],
        catlists: [],
        selectedCategory: "",
        keywords: "",
        visibilityoption: "",
        errorMessage: false,
        courseOrder: undefined,
        levelLists: ['Principiante','Intermedio', 'Avanzado'],
        selectedLevel: "",
    };
    componentDidMount() {
        this.getCategoryList();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        var daaa = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem("token")) {
            this.setState({
                author: daaa.email,
            });
        }
    };

    getCategoryList() {
        API.get('/categories/')
            .then(result => {
                const catlists = result.data.categories;
                this.setState({ catlists });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }

    createVideoUI() {
        return this.state.videos.map((el, i) => (
            <div className={"mb-2 item-row item-row-" + i} key={i}>
                {i ?
                    (<input type="button" value="Remove" className="btn btn-outline-primary remove-btn" onClick={this.removeClick.bind(this, i)} />)
                    : ("")
                }
                <div className="form-group col-md-4">
                    <h5 className="l-duration">Video Position: <span className="requiredsymbol">*</span></h5>
                    <input
                        className="form-control video-position"
                        required
                        pattern="[0-9]"
                        type="number"
                        name="videoPosition"
                        value={el.videoPosition || ''}
                        onChange={this.handleChangePosition.bind(this, i)}
                        placeholder="Position"
                    />
                </div>
                <div className="alert alert-info">
                    Number should be greater than 0 and not be decimal number
                </div>

                <div className="form-group">
                    <h5 className="new-title">Video Title: <span className="requiredsymbol">*</span></h5>
                    <input
                        className="form-control video-title"
                        type="text"
                        name="videoTitle"
                        value={el.videoTitle || ''}
                        onChange={this.handleChangeVideo.bind(this, i)}
                        required
                        placeholder="Video Title"
                    />
                </div>
                <div className="form-group">
                    <h5 className="new-title">Progress Bar Title: <span className="requiredsymbol">*</span></h5>
                    <input
                        className="form-control video-title"
                        type="text"
                        name="videoProgressTitle"
                        value={el.videoProgressTitle || ''}
                        onChange={this.handleChangeVideo.bind(this, i)}
                        required
                        placeholder="Progress Bar Title"
                    />
                </div>
                <div className="form-group">
                    <h5 className="new-title">Video Description: </h5>
                    <textarea
                        className="form-control video-desc"
                        type="text"
                        name="videoDescription"
                        value={el.videoDescription || ''}
                        onChange={this.handleChangeVideo.bind(this, i)}
                        required
                        placeholder="Type Video description here..."
                    />
                </div>
                <div className="form-group">
                    <h5 className="l-duration">Video Duration:</h5>
                    <input
                        className="form-control video-duration"
                        type="text"
                        name="videoDuration"
                        value={el.videoDuration || ''}
                        onChange={this.handleChangeVideo.bind(this, i)}
                        placeholder="ex: 8 min"
                    />
                </div>
                <div className="form-group">
                    <h5 className="l-duration">Vimeo URL: <span className="requiredsymbol">*</span></h5>
                    <div className="input-box">
                        <span className="prefix">https://vimeo.com/</span>
                        <input
                            className="form-control video-url"
                            type="number"
                            min="1"
                            name="videoURL"
                            value={el.videoURL || ''}
                            onChange={this.handleChangeVideo.bind(this, i)}
                            required
                            placeholder="123456789"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <h5>Key Takeaways:</h5>
                    <CKEditor
                        editor={ClassicEditor}
                        onChange={this.handleVideoEditorChange.bind(this, i)}
                        data={el.videoKeyTakeaway || ''}
                        config={{
                            placeholder:
                                "Start typing Key Takeaways note here...",
                            toolbar: [
                                "|",
                                "Bold",
                                "Italic",
                                "Link",
                                "NumberedList",
                                "BulletedList",
                                "|",
                                "BlockQuote",
                                "InsertTable",
                                "Undo",
                                "Redo",
                            ],
                        }}
                    />
                </div>

            </div>
        ))
    }
    handleChangeVideo(i, e) {
        const { name, value } = e.target;
        let videos = [...this.state.videos];
        videos[i] = { ...videos[i], [name]: value };
        this.setState({ videos });
    }
    handleChangePosition(i, e) {

        // if((0< e.target.value) && parseInt(e.target.value) == e.target.value){
        this.setState({ errorMessage: false })
        const { name, value } = e.target;
        let videos = [...this.state.videos];
        videos[i] = { ...videos[i], ['videoPosition']: parseInt(Math.abs(e.target.value)) };
        this.setState({ videos });

    }
    handleVideoEditorChange = (i, e, editor) => {
        const data = editor.getData();
        let videos = [...this.state.videos];
        videos[i] = { ...videos[i], ['videoKeyTakeaway']: data };
        this.setState({ videos });
    }
    addClick() {
        this.setState(prevState => ({
            videos: [...prevState.videos, { videoTitle: "", videoProgressTitle: "", videoDescription: "", videoDuration: "", videoURL: "", videoKeyTakeaway: "", videoPosition: "" }]
        }))
    }
    removeClick(i) {
        let videos = [...this.state.videos];
        videos.splice(i, 1);
        this.setState({ videos });
    }

    handlePublish(event) {
        event.preventDefault();
        const courseData = this.state;
        API.post('/courses/add', courseData)
            .then(result => {
                $('#add-education-modal').modal('hide');
                window.location.reload();
            })
            .catch(e => {
                $('#add-education-modal').modal('hide');
                console.log("errror: ",e);
                Modal.error({
                    title: 'An error occurred',
                    content: e.response.data.msg,
                });
            });
    }
    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({ body: data });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }
    handleChangePositionCouese = (event) => {
        const { name, value } = event.target;
        if (event.target.value != "") {
            this.setState({
                [name]: parseInt(Math.abs(value)),
            })
        } else {
            this.setState({
                [name]: "",
            })
        }

    }
    render() {
        return (

            <div>
                <div className="modal fade" id="add-education-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Create New Course</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="new-post">
                                    <form onSubmit={this.handlePublish}>
                                        <div className="form-group">
                                            <h5 className="l-duration">Course Order: </h5>
                                            <input
                                                className="form-control video-position"
                                                pattern="[0-9]"
                                                type="number"
                                                name="courseOrder"
                                                min="1"
                                                value={this.state.courseOrder}
                                                onChange={this.handleChangePositionCouese}
                                                placeholder="Course order"
                                            />
                                        </div>
                                        <div className="alert alert-info">
                                            1. Order should be greater than 0
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Course Title: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="title"
                                                value={this.state.title}
                                                onChange={this.handleChange}
                                                required
                                                placeholder="Course Title"
                                            />
                                        </div>
                                        <div>
                                            <h5 className="new-body">Course Description: </h5>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onChange={this.handleEditorChange}

                                                config={{
                                                    placeholder:
                                                        "Start typing course detail here...",
                                                    toolbar: [
                                                        "Heading",
                                                        "|",
                                                        "Bold",
                                                        "Italic",
                                                        "Link",
                                                        "NumberedList",
                                                        "BulletedList",
                                                        "|",
                                                        "BlockQuote",
                                                        "MediaEmbed",
                                                        "ImageUpload",
                                                        "InsertTable",
                                                        "Undo",
                                                        "Redo",
                                                    ],
                                                }}
                                            />
                                        </div>
                                        <div className="form-group col-md-6 p-0 mt-3">
                                            <h5 className="cat-select" >Category <span className="requiredsymbol">*</span></h5>
                                            <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                                                <option value="">Select Category</option>
                                                {this.state.catlists.map(catlist => <option value={catlist._id} key={catlist._id}>{catlist.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-6 p-0 mt-3">
                                            <h5 className="cat-select" >Level <span className="requiredsymbol">*</span></h5>
                                            <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedLevel">
                                                <option value="">Select Level</option>
                                                {this.state.levelLists.map(level => <option value={level} key={level}>{level}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <h5>Tags/Keywords</h5>
                                            <input
                                                type="text"
                                                name="keywords"
                                                className="form-control"
                                                value={this.state.keywords}
                                                onChange={this.handleChange}
                                                placeholder="Comma seperated keywords"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <h4 className="intro-video">Type of Course <span className="requiredsymbol">*</span></h4>
                                            <div>
                                                <input type="radio" value="free" name="visibilityoption" id="free" required onChange={this.handleChange} />
                                                <label htmlFor="free">&nbsp;Free &nbsp;</label>
                                                <input type="radio" value="paid" name="visibilityoption" id="paid" onChange={this.handleChange} />
                                                <label htmlFor="paid">&nbsp;Paid</label>
                                            </div>
                                        </div>
                                        <fieldset className="form-group video-input-box">
                                            <h4 className="intro-video">Course Videos</h4>
                                            {this.createVideoUI()}
                                            <input type='button' value='Add More' className="btn btn-outline-primary float-right mt-2 mb-2" onClick={this.addClick.bind(this)} />
                                        </fieldset>

                                        <div className="form-group publish-box">
                                            <input type="checkbox" defaultChecked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                            <label style={{ marginLeft: "16px" }} className="custom-control-label" htmlFor="customCheck1">Publish</label>
                                        </div>
                                        <div className="form-group">
                                            <label className="lable-title">Path <span className="requiredsymbol">*</span></label>
                                            <div className="input-box">
                                                <span className="prefix">/</span>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="alias"
                                                    value={this.state.alias}
                                                    onChange={this.handleChange}
                                                    required
                                                    placeholder="education/my_course"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                disabled={(this.state.courseOrder == "" || this.state.courseOrder == undefined) ? false : this.state.courseOrder < 1}
                                                type="submit"
                                                value="Save"
                                                className="btn btn-outline-primary btn-lg"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default AddEducation;