import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import Sidebar from '../../../components/Sidebar/Sidebar';
import API from '../../../utils/API';
import {Modal} from 'antd';
import {Link} from 'react-router-dom';
import './css/forums.css';

const Forums = () => {
  const [categorylist, setCategoryList] = useState([]);
  React.useEffect(()=>{
    API.get('/categories/')
    .then(result => {      
     if(result.data){
        setCategoryList(result.data.categories);
     }
    })
    .catch(e => {
      Modal.error({
        title: 'An error occurred',
        content: e.message,
      });
    });
  },[])
  return (
    <div>
        <div className="d-flex" id="wrapper">
            <Sidebar/>
            <div id="page-content-wrapper">
                <div className="container-fluid">
                    <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                    <h1 className="mt-2 text-primary">Forums</h1>
                    <div className="d-flex">

                      <ul id="tabs" className="nav nav-tabs" role="tablist">
                        {categorylist.map((cat, index) =>{
                          if(index == 0){
                            return <li className="nav-item">
                            <a id={"tab-"+(index)} href={"#pane-"+(index)} className="nav-link active" data-toggle="tab" role="tab">{cat.name}</a>
                          </li>
                          }else{
                           return  <li className="nav-item">
                            <a id={"tab-"+(index)} href={"#pane-"+(index)} className="nav-link" data-toggle="tab" role="tab">{cat.name}</a>
                          </li>
                          }
                        }
                        )}
                      </ul>

                      <div id="content" className="tab-content" role="tablist">
                        {categorylist.map((cat,index) => {
                            if(index == 0){
                              return <div id={"pane-"+(index)} className="card tab-pane fade show active" role="tabpanel" aria-labelledby={"tab-"+(index)}>
                                <div id={"collapse-"+(index)} className="collapse show" role="tabpanel" aria-labelledby={"heading-"+(index)}>
                                <div className="card-body">
                                  <ul>
                                  {cat.subcategories.length > 0 
                                  ?
                                  cat.subcategories.map(subcat => {
                                    return <li><div><Link to={'/admin/subcategory?sid='+(subcat.shortid)} >{subcat.name}</Link></div><div>{subcat.description}</div></li>
                                  })
                                  
                                  : 
                                  <div>No Subcategory</div>
                                  }
                                </ul></div>
                                </div>
                              </div>
                            }else{
                              return <div id={"pane-"+(index)} className="card tab-pane fade" role="tabpanel" aria-labelledby={"tab-"+(index)}>
                              <div id={"collapse-"+(index)} className="collapse show" role="tabpanel" aria-labelledby={"heading-"+(index)}>
                                <div className="card-body">
                                  <ul>
                                  {cat.subcategories.length > 0 
                                  ?
                                  cat.subcategories.map(subcat => {
                                    return <li><div><Link to={'/admin/subcategory?sid='+(subcat.shortid)} >{subcat.name}</Link></div><div>{subcat.description}</div></li>
                                  })
                                  
                                  : 
                                  <div>No Subcategory</div>
                                  }
                                </ul></div>
                              </div>
                            </div>
                            }
                            
                          }
                        )}
                      </div>

                      </div>
                    </div>
            </div>
        </div>
    </div>
  );
};

export default Forums;
