import React, { Component, Fragment } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import Sidebar from '../../../components/Sidebar/Sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import API from '../../../utils/API';
import {Modal, Popconfirm, message} from 'antd';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import AddTipOfTheWeek from './AddTipOfTheWeek';
import CategoryName from '../../../components/CategoryName/CategoryName';

class TipOfTheWeek extends Component{
  state ={
    datalists: [],
    currentRecord: {
      _id: '',
      title: '',
      selectedCategory: '',
      keywords: '',
      createdAt: '',
    },
  }
  columns = [
    {
        key: "_id",
        text: "Id",
        className: "id",
        align: "left",
        sortable: true,
    },
    {
        key: "title",
        text: "Title",
        className: "capitalize",
        align: "left",
        sortable: true,
    },
    {
        key: "selectedCategory",
        text: "Category",
        className: "capitalize",
        align: "left",
        sortable: true,
    },
    {
      key: "artistname",
      text: "Artist Name",
      className: "capitalize",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "capitalize",
      align: "left",
      sortable: true,
    },
    {
        key: "createdAt",
        text: "Created Date",
        className: "date",
        align: "left",
        sortable: true
    },
    {
        key: "action",
        text: "Action",
        className: "action",
        width: 200,
        align: "left",
        sortable: false,
        cell: datalist => {
            return (
                <Fragment>
                    <Link 
                      to={'/admin/tipoftheweek/'+datalist._id+'/edit'}
                      style={{marginRight: '5px'}}
                      className="btn btn-primary btn-sm">
                          <i className="fa fa-edit"></i>
                    </Link>
                    <Popconfirm
                      title="Are you sure to delete this Tip Of The Week?"
                      onConfirm={this.confirm}
                      onCancel={this.cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button
                        className="btn btn-danger btn-sm"
                        title="Delete"
                        onClick={() => this.deleteRecord(datalist)}>
                            <i className="fa fa-trash"></i>
                      </button>
                    </Popconfirm>
                </Fragment>
            );
        }
    }
  ];
  config = {
    key_column: '_id',
    page_size: 10,
    length_menu: [ 10, 20, 50, 100 ],
    filename: "Courses",
    no_data_text: 'No Course found!',
    language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last"
        }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  componentDidMount() {
    this.getData();
    this.confirm = this.confirm.bind(this);
    this.alphabetically = this.alphabetically.bind(this)
  };
  componentWillReceiveProps(nextProps) {
    this.getData()
  }
  alphabetically(ascending) {

    return function (a, b) {
  
      // equal items sort equally
      if (a.tipOfTheWeekOrder === b.tipOfTheWeekOrder) {
          return 0;
      }
      // nulls sort after anything else
      else if (a.tipOfTheWeekOrder === null) {
          return 1;
      }
      else if (b.tipOfTheWeekOrder === null) {
          return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (ascending) {
          return a.tipOfTheWeekOrder < b.tipOfTheWeekOrder ? -1 : 1;
      }
      // if descending, highest sorts first
      else { 
          return a.tipOfTheWeekOrder < b.tipOfTheWeekOrder ? 1 : -1;
      }
  
    };
  
  }
  getData() {
    Moment.locale('en');
    API.get('/tipoftheweek/contents')
    .then(result => {
     
      const courses= result.data.tipoftheweek.sort(this.alphabetically(true));
      var coursesdata = [];
      courses.map((course, index) =>{
        coursesdata[index] = {
          _id: course._id,
          title: course.title,
          selectedCategory: (course.selectedCategory.length > 0 ? course?.selectedCategory[0]?.name : ''),          status: (course.status == 'true' ? 'Published' : 'Unpublished'),
          artistname: course.artistname,
          createdAt: Moment(course.createdAt).format('MMM D, YYYY')
        }
      });
      this.setState({datalists: coursesdata});
    })
    .catch(e => {
      Modal.error({
        title: 'An error occurred',
        content: e.message,
      });
    });
  }
  viewRecord(datalist) {
    this.setState({ currentRecord: datalist});
  }
  editRecord(datalist) {
    this.setState({ currentRecord: datalist});
  }
  deleteRecord(datalist) {
    this.setState({ currentRecord: datalist});
  }
  confirm() {
    API.post("/tipoftheweek/content-delete", {id: this.state.currentRecord._id})
    .then(res => {
        if (res.status === 200) {
          this.getData();
          message.success('Tip of the week deleted');
        }
    })
    .catch(e => {
        Modal.error({
          title: 'An error occurred',
          content: e.message,
        });
      });
  }
  
  cancel(e) {

  }
  pageChange(pageData) {
  }
  render(){
    return (
      <div>
          <div className="d-flex" id="wrapper">
              <Sidebar/>
              <AddTipOfTheWeek />
              <div id="page-content-wrapper">
                  <div className="container-fluid">
                      <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                      <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-book-modal">Add New Tip Of The Week</button>
                      <h1 className="mt-2 text-primary">Tip Of The Week</h1>
                      <ReactDatatable
                              config={this.config}
                              records={this.state.datalists}
                              columns={this.columns}
                              onPageChange={this.pageChange.bind(this)}
                          />
                  </div>
              </div>
          </div>
      </div>
    );
  }

};

export default TipOfTheWeek;

