import React, { Component, Fragment } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import API from '../../../utils/API';
import {Modal, Popconfirm, Button, Table} from 'antd';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LenderInvite from './LenderInvite';
import LoaninterestedDetail from './LoaninterestedDetail'
import './lender.css';
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Invitation Accepted',
    dataIndex: 'invitationaccept',
  },
  {
    title: 'Interested in Loan',
    dataIndex: 'interestedLoan'
  },
  {
    title: 'Approved Loan Amount',
    dataIndex: 'approved'
  }
];
class Lender extends Component{
  state ={
    currentValue: '',
    currentId: '',
    add_disabled: true,
    option : [],
    loaded: false,
    updateId: '',
    lenderinvite: false,
    viewdetailopen: false,
    expendedData: []
  }
  dataSource = [];
  dataSourceGrouped=[];

  componentDidMount() {
    this.getData();
  };

  calculateLoan(loaninterested) {
    let totalloan = 0;
    loaninterested.map(l => {
      if(l?.loanamount){
        totalloan = totalloan + l.loanamount
      }
    })
    return '$ '+totalloan;
  }
  showfullcalculation(loaninterested) {
    this.setState({viewdetailopen: true, expendedData: loaninterested})
    

  }
  getData() {
    this.dataSourceGrouped = [];
    let d = [];
    API.get('/users/fetchtata/lender')
    .then(result => {
      if(result?.data?.users){
        result.data.users.map((u,key)=>{
          //this.dataSourceGrouped.push({ key: key, fullname:u?.user?.firstname+' '+u?.user?.lastname, email:u?.user?.email, id:u?.user?._id, active: u?.user?.confirmemail,invitationaccept: u?.invitationaccept })

          this.dataSourceGrouped.push({
            key: key,
            name: u?.user?.firstname+' '+u?.user?.lastname,
            email: u?.user?.email,
            invitationaccept:<>{u.invitationaccept != true ?
              <Popconfirm
                title="Resend invitation link?"
                description="Are you sure to delete this task?"
                onConfirm={() => this.resendLink(u.id)}
                onCancel={(e) => this.cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Resend invitation</Button>
              </Popconfirm>
              :<div className='l-status'>{u?.user?.confirmemail == '1' ? <span className='green'>Accepted</span>: <span className="red">Waiting</span>}</div>}</>,
              interestedLoan: u?.interestedLoan?.length,
            approved: this.calculateLoan(u?.interestedLoan),
            expendDataDetail: u?.interestedLoan?.length > 0 && <LoaninterestedDetail expendedData={u?.interestedLoan}/>
          });
          if(key+1 == result.data.users.length){
            this.setState({loaded:true})
          }
        })
      }
    })
    .catch(e => {
      Modal.error({
        title: 'An error occurred',
        content: e.message,
      });
    });
  }
 
  resendLink = (id) => {
    API.post('users/b/li/resend/link',{
      id
    }).then((res) => {
      Modal.success({
        content: 'Invitation Link Sent successfully',
      });
    })
  }
  cancel = (e) =>{
    
  };
  render(){
    const dataSourceGrouped = this.dataSourceGrouped;
    return (
      <div>
          <div className="d-flex" id="wrapper">
              <Sidebar/>
              <div id="page-content-wrapper">
                  <div className="container-fluid">
                      <h1 className="mt-2 text-primary">Lenders List</h1>
                      <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-user-modal" onClick={(e) => {this.setState({'lenderinvite': true})}}><FontAwesomeIcon icon={faPlus} /> Invite Lender</button>
                      <div style={{ display: 'block', padding: 30, margin: 'auto', }}></div>

                      <div>
                      {this.state.loaded && <Table 
                        dataSource={dataSourceGrouped}
                        columns={columns}
                        className='responsive-table'
                        pagination={{
                          position: ['none', 'bottomCenter'],
                          pageSize:10,
                        }}
                        expandable={{
                          expandedRowRender: (record) => <div style={{ margin: 0 }}>{record.expendDataDetail}</div>,
                          expandIcon: ({ expanded, onExpand, record }) =>
                          expanded ? (
                            record.interestedLoan > 0 && <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                          ) : (
                            record.interestedLoan > 0 && <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                          )
    
                        }}
                        />}
                        {/* {this.state.loaded && dataSourceGrouped.map((u) =>{
                          
                            return <div>
                              <h3>{u.fullname}</h3>
                              <a href={"mailto:"+u.email}>{u.email}</a>
                              {u.invitationaccept != true ?
                            <Popconfirm
                              title="Resend invitation link?"
                              description="Are you sure to delete this task?"
                              onConfirm={() => this.resendLink(u.id)}
                              onCancel={(e) => this.cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button danger>Resend invitation</Button>
                            </Popconfirm>
                            :<div className='l-status'>{u.active == '1' ? <span className='green'>Accepted</span>: <span className="red">Waiting</span>}</div>}
                            </div>
                           
                        })} */}
                      </div>
                  </div>
                  <Modal
                  visible={this.state.lenderinvite}
                  footer={null}
                  onCancel={(e)=>this.setState({lenderinvite:false})}
                  destroyOnClose={true}>
                    <LenderInvite/>
                  </Modal>
                  {/* <Modal
                  visible={this.state.viewdetailopen}
                  >
                    <LoaninterestedDetail expendedData={this.state.expendedData}/>
                  </Modal> */}
              </div>
          </div>
      </div>
    );
  }

};

export default Lender;

