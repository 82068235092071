import React, { Component } from 'react';
import API from '../../../utils/API';
import { Modal, message } from 'antd'
import { withRouter } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import BooksPreview from './BooksPreview';
import BookImagePreview from './BookImagePreview';
class UpdateBooks extends Component {


    state = {
        _id: this.props.match.params.id,
        bookname: "",
        description: "",
        author: "",
        artistname: "",
        status: false,
        alias: "",
        book: "",
        coverimage: "",
        catlists: [],
        selectedCategory: "",
        bookBase64: "",
        visible: false,
        coverImageBase64: "",
        visibleImage: false,
        loading: false,
        errorImage: false,
        errorDocument: false,
        bookUploadType: 'url',
        bookurl: undefined,
        duplicatebook: "",
        bookOrder: undefined


    };
    componentDidMount() {

        this.getBookDetail()
        this.getCategoryList();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.seeBook = this.seeBook.bind(this);
        this.seeImage = this.seeImage.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleChangeUrl = this.handleChangeUrl.bind(this)
        var daaa = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem("token")) {
            this.setState({
                author: daaa.email,
            });
        }

    };

    getBookDetail() {
        API.get('/books/contents/node/' + this.state._id)
            .then(result => {
                //setCourseData(result.data.courses);
                //this.setState({courseData: result.data.courses});
                result.data.books.map((course, index) => {
                    this.setState({ bookname: course.bookname });
                    this.setState({ description: course.description });
                    this.setState({ selectedCategory: course.selectedCategory });
                    this.setState({ book: course?.book });
                    this.setState({ duplicatebook: course?.book });
                    this.setState({ status: course.status });
                    this.setState({ artistname: course.artistname });
                    this.setState({ bookurl: course.bookurl });
                    this.setState({ coverimage: course.coverimage })
                    this.setState({ bookOrder: course?.bookOrder !== undefined ? course?.bookOrder : "" })
                    this.setState({ alias: course.pathalias.alias.slice(1) })

                    if (course.status == 'true') {
                        this.setState({ status: true });
                    } else {
                        this.setState({ status: false });
                    }
                });
                if (this.state.book) {
                    this.setState({ bookUploadType: 'url' })
                    API.get('/books/getbook/' + this.state._id)
                        .then(result => {

                            this.setState({ bookBase64: `data:application/pdf;base64,${result?.data?.base64}` })

                        })
                        .catch(e => {
                            Modal.error({
                                title: 'An error occurred',
                                content: e.message,
                            });
                        });
                } else {
                    this.setState({ bookUploadType: 'url' })
                }
                if (this.state.coverimage) {
                    API.get('/books/getcoverimage/' + this.state._id)
                        .then(result => {

                            this.setState({ coverImageBase64: result?.data?.base64})

                        })
                        .catch(e => {
                            Modal.error({
                                title: 'An error occurred',
                                content: e.message,
                            });
                        });
                }
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }

    getCategoryList() {
        API.get('/categories/')
            .then(result => {
                const catlists = result.data.categories;
                this.setState({ catlists });

            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }
    handleClickBook = event => {
        document.getElementById("selectbook").click()
    };
    handleClickCover = event => {
        document.getElementById("selectcoverimage").click()
    }

    courseCateSelect = () => {

        if (this.state.selectedCategory != '') {
            return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">

                <option value="">Select Category</option>
                {this.state.catlists.map(catlist =>
                    <option
                        value={catlist._id}
                        selected={this.state.selectedCategory == catlist._id ? true : false}
                    >
                        {catlist.name}
                    </option>
                )
                }
            </select>
        } else {
            return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                <option value="">Select Category</option>
                {this.state.catlists?.map(catlist => <option value={catlist._id}>{catlist.name}</option>)}
            </select>

        }
    }

    handlePublish(event) {

        if (this.state.bookUploadType == 'url' && this.state.book !== undefined) {
            this.setState({ book: undefined })
            this.setState({ loading: true })
            event.preventDefault();

            const courseData = this.state;
            API.post('/books/update-bookurl', courseData)
                .then(result => {
                    this.setState({ loading: false })

                    this.props.history.goBack()
                    this.setState({ loading: false })
                    message.success('Book updated', 3)
                })
                .catch(e => {
                    this.setState({ loading: false })
                    Modal.error({
                        title: 'An error occurred',
                        content: e.response.data.msg,
                    });
                });

        } else {
            this.setState({ loading: true })
            event.preventDefault();

            const courseData = this.state;
            API.post('/books/update', courseData)
                .then(result => {
                    this.setState({ loading: false })

                    this.props.history.goBack()
                    this.setState({ loading: false })
                    message.success('Book updated', 3)
                })
                .catch(e => {
                    this.setState({ loading: false })
                    Modal.error({
                        title: 'An error occurred',
                        content: e.response.data.msg,
                    });
                });
        }
    }
    handleEditorChange = (event, editor) => {

        this.setState({ description: event });

    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });

    }
    handleChangeUrl = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });

    }
    getImageBase64 = async (file) => {
        return await new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                if (event.target.result) {
                    resolve(event.target.result);
                } else {
                    reject('Please try again.');
                }
            };
        });
    }
    handleDocument = (event) => {
        this.setState({ errorDocument: false })
        if (event.target.files[0].size <= 10485760) {
 

            this.getImageBase64(event.target.files[0]).then(res => {

                this.setState({ bookBase64: res })
                this.setState({ book: res })

            }).catch(error => {
                console.error(error);
            })
        } else {
            this.setState({ errorDocument: true })
            this.setState({ book: undefined })
        }
    }
    handleImage = (event) => {
        if (event.target.files[0].size <= 10485760) {
            // setUpload(false)
            //   setImg({
            //       src: URL.createObjectURL(e.target.files[0]),
            //       alt: e.target.files[0].name
            //   }); 


            this.getImageBase64(event.target.files[0]).then(res => {

                this.setState({ coverImageBase64: res })
                this.setState({ coverimage: res })

            }).catch(error => {
                console.error(error);
            })
        } else {
            this.setState({ errorImage: true })
            this.setState({ coverimage: undefined })
        }
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }
    seeBook() {
        this.setState({ visible: true })
    }
    seeImage() {
        this.setState({ visibleImage: true })
    }
    handleCancel() {
        this.setState({ visible: false })
        this.setState({ visibleImage: false })
    }
    handleChangePosition = (event) => {
        const { name, value } = event.target;
        if (event.target.value != "") {
            this.setState({
                [name]: parseInt(Math.abs(value)),
            })
        } else {
            this.setState({
                [name]: "",
            })
        }

    }

    render() {
        return (

            <div className="d-flex" id="wrapper">
                <Sidebar />

                <div id="page-content-wrapper">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Book</h4>

                            </div>
                            <div className="modal-body">
                                <div className="new-post">
                                    <form onSubmit={this.handlePublish}>
                                        <div className="form-group">
                                            <h5 className="l-duration">Book Order: </h5>
                                            <input
                                                className="form-control video-position"
                                                pattern="[0-9]"
                                                type="number"
                                                min="1"
                                                name="bookOrder"
                                                value={this.state.bookOrder}
                                                onChange={this.handleChangePosition}
                                                placeholder="Book order"
                                            />
                                        </div>
                                        <div class="alert alert-info">
                                            1. Order should be greater than 0
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Book Name: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="bookname"
                                                value={this.state.bookname}
                                                onChange={this.handleChange}
                                                required
                                                placeholder="Book Name"
                                            />
                                        </div>

                                        <div>
                                            <h5 className="new-body">Book Description: </h5>
                                            <textarea
                                                style={{ height: '400px' }}
                                                style={{ height: '400px' }}
                                                className="form-control new-title"
                                                onChange={this.handleChange}

                                                value={this.state.description}
                                                name="description"
                                                placeholder=
                                                "Start typing book detail here..."


                                            />
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Author: </h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="artistname"
                                                value={this.state.artistname}
                                                onChange={this.handleChange}

                                                placeholder="Author Name"
                                            />
                                        </div>
                                        <div className="form-group col-md-6 p-0 mt-3">
                                            <h5 className="cat-select" >Category: <span className="requiredsymbol">*</span></h5>
                                            {this.courseCateSelect()}
                                        </div>
                                        <div class="form-group">

                                            {/* <div>
                        <input type="radio" value="free" name="visibilityoption" id="free" required onChange={this.handleChange}/>
                        <label htmlFor="free">Free</label>
                        <input type="radio" value="paid" name="visibilityoption" id="paid" onChange={this.handleChange}/>
                        <label htmlFor="paid">Paid</label>
                    </div> */}
                                        </div>
                                        {/* {this.state.bookUploadType == 'pdf'? */}
                                        {/* <div>
                
                <h5 className="new-title">Upload book type: <span className="requiredsymbol">*</span></h5>
                        <input  type="radio" value="pdf" name="bookUploadType" id="pdf" required onChange={this.handleChange}/>
                        <label for="pdf">&nbsp;PDF &nbsp;</label>
                        <input  type="radio" value="url" name="bookUploadType" id="url" onChange={this.handleChange}/>
                        <label for="url">&nbsp;URL</label>
                    </div> */}
                                        {/* //  : */}
                                        {/* <div> 
                <h5 className="new-title">Upload book type: <span className="requiredsymbol">*</span></h5>
                        <input  type="radio" value="pdf" name="bookUploadType" id="pdf2" required onChange={this.handleChange}/>
                        <label for="pdf2">&nbsp;PDF &nbsp;</label>
                        <input defaultChecked   type="radio" value="url" name="bookUploadType" id="url2" onChange={this.handleChange}/>
                        <label for="url2">&nbsp;URL</label>
                    </div> */}
                                        {/* // } */}
                                        {/* {this.state.bookUploadType == 'pdf'?<div>
              <div className="form-group">
                    <h5 className="new-title">Upload book: <span className="requiredsymbol">*</span></h5>
                    <input
                        className="form-control new-title"
                        type="file"
                        accept="application/pdf"
                        onChange={this.handleDocument}
                        hidden
                        placeholder="Book Name"
                       
                        id = "selectbooka"
                    />
                    <label class="text-white bg-secondary p-2 mb-2"  htmlFor="selectbooka"> Update Book</label>
                </div>
                {this.state.errorDocument ? <div class="alert alert-danger">
    File size should be 10MB or less than 10MB
  </div>:<div class="alert alert-info">
    File size should be 10MB or less than 10MB
  </div>} */}
                                        {/* </div>: */}
                                        <div className="form-group">
                                            <h5 className="new-title">Book URL: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                required
                                                type="url"
                                                name="bookurl"
                                                value={this.state.bookurl}
                                                onChange={this.handleChangeUrl}

                                                placeholder="Book url (ex. https://example..)"
                                            />
                                        </div>
                                        {/* } */}
                                        <Modal
                                            visible={this.state.visible}
                                            okText="Submit"
                                            onCancel={this.handleCancel}
                                            centered="true"
                                            wrapClassName={'profileeditpop'}
                                            footer={null}
                                            width={612}
                                            mask={false}
                                        >
                                            <BooksPreview seepdf={this.state.bookBase64} />
                                        </Modal>
                                        {/* {this.state.book&&<div style = {{cursor:'pointer'}} onClick = {this.seeBook}>see pdf</div>} */}
                                        <div className="form-group">
                                            <h5 className="new-title">Upload banner image: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                onChange={this.handleImage}
                                                id="selectcoverimages"
                                                defaultValue={this.state.coverimage}
                                                placeholder="Book Name"
                                                hidden
                                            />
                                        </div>
                                        <label class="text-white bg-secondary p-2 mb-2" htmlFor="selectcoverimages"> Update banner image</label>
                                        {this.state.errorImage ? <div class="alert alert-danger">
                                            File size should be 10MB or less than 10MB
                                        </div> : <div class="alert alert-info">
                                            File size should be 10MB or less than 10MB
                                        </div>}       <Modal
                                            visible={this.state.visibleImage}
                                            okText="Submit"
                                            onCancel={this.handleCancel}
                                            centered="true"
                                            wrapClassName={'profileeditpop'}
                                            footer={null}

                                        >
                                            <BookImagePreview seepdf={this.state.coverImageBase64} />
                                        </Modal>
                                        <div style={{ cursor: 'pointer', display:'inline-block',marginBlock:'20px' }} onClick={this.seeImage}><img src={this.state.coverImageBase64} style = {{width :"100px",height:"100px"}}/> Preview Banner Image</div>
                                        <div class="form-group">
                                            <label className="lable-title">Path <span className="requiredsymbol">*</span></label>
                                            <div className="input-box">
                                                <span class="prefix">/</span>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="alias"
                                                    value={this.state.alias}
                                                    onChange={this.handleChange}
                                                    required
                                                    placeholder="books/my_books"
                                                />
                                            </div>
                                        </div>
                                        <div style={{ marginLeft: "16px" }} className="form-group publish-box">
                                            <input type="checkbox" checked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label" htmlFor="customCheck1">Publish</label>
                                        </div>
                                        {this.state.loading && <div style={{ textAlign: 'center' }} class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                        <div className="form-group">
                                            <input disabled={this.state.loading || this.state.coverimage == undefined || (this.state.bookOrder == "" || this.state.bookOrder == undefined) ? false : this.state.bookOrder < 1}
                                                type="submit"
                                                value="Update"
                                                className="btn btn-outline-primary btn-lg"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default withRouter(UpdateBooks);