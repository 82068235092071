import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import Sidebar from '../../components/Sidebar/Sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import API from '../../utils/API';
import { message } from 'antd';
import Moment from 'moment';

class Submissions extends Component {
    columns = [
        /* {
            key: "_id",
            text: "Id",
            className: "id",
            align: "left",
            sortable: true,
        }, */
        {
            key: "fullname",
            text: "Name",
            className: "capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "email",
            text: "Email",
            className: "capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "reason",
            text: "Reason for Contacting",
            className: "capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "message",
            text: "Message",
            className: "capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "createdAt",
            text: "Submitted On",
            className: "capitalize",
            align: "left",
            sortable: true,
        },
        

    ];

    config = {
        key_column: '_id',
        page_size: 10,
        length_menu: [10, 20, 50, 100],
        filename: "Submissions",
        no_data_text: 'No record found!',
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    state = {
        records: []
    };

    state = {
        currentRecord: {
            _id: '',
            firstname: '',
            lastname: '',
            email: '',
            registerDate: '',
        }
    };
    // this.getData = this.getData.bind(this);

    componentDidMount() {
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        API.get("/contacts/")
            .then(res => {
                //this.setState({ records: res.data.submissions })
                const contacts= res.data.submissions;
                var contactdata = [];
                contacts.map((item, index) =>{
                    contactdata[index] = {
                    _id: item._id,
                    fullname: item.fullname,
                    email: item.email,
                    reason: item.reason,
                    message:item.message,
                    createdAt: Moment(item.createdAt).format('MMM D, YYYY')
                    }
                });
                this.setState({ records: contactdata })
            })
            .catch()
    }

    viewRecord(record) {
        this.setState({ currentRecord: record });
    }
    editRecord(record) {
        this.setState({ currentRecord: record });
    }

    pageChange(pageData) {

    }

    render() {
        return (
            <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Contact Us Submissions</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Submissions;
