import React, { createContext, useReducer, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './components/Header/Header';
import reducer from './utils/reducer';

// Pages
import Dashboard from './pages/Dashboard/Dashboard';
import Users from './pages/Users/Users';
import UsersReport from './pages/Users/UsersReport';
import AdminUsers from './pages/Users/AdminUsers';
import Front from './pages/Front/Front';
import Page404 from './pages/Page404/Page404';
import PrivateRoute from './components/private-route/PrivateRoute';
import Education from './pages/Educations/Education';
import Podcast from './pages/AdminPages/Podcast/Podcast';
import Blogs from './pages/AdminPages/Blogs/Blogs';
import Books from './pages/AdminPages/Books/Books';
import Sponsors from './pages/AdminPages/Sponsors/Sponsors';
import Forums from './pages/AdminPages/Forums/Forums';
import AroundCorner from './pages/AdminPages/AroundCorner';
import BasicPages from './pages/BasicPages/BasicPages';
import Faq from './pages/Faq/Faq';
import Category from './pages/Category/Category';
import Submissions from './pages/Contactus/Submissions';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper';
import AddPage from './pages/BasicPages/AddPage';
import FaqCategory from './pages/Faq/FaqCategory';
import FaqUpdate from './pages/Faq/FaqUpdate';
import BasicPageUpdate from './pages/BasicPages/BasicPageUpdate';
import CourseUpdate from './pages/Educations/UpdateEducation';
import Interests from './pages/Interests/Interests';   //added by lav
import UpdateBooks from './pages/AdminPages/Books/UpdateBooks';
import UpdateSponsors from './pages/AdminPages/Sponsors/UpdateSponsors';
import UpdatePodcasts from './pages/AdminPages/Podcast/UpdatePodcast';
import UpdateBlogs from './pages/AdminPages/Blogs/UpdateBlog';
import Subcategory from './pages/Subcategory/Subcategory';
import Topic from './pages/Topic/Topic';
import Roles from './pages/AdminPages/Roles/Roles';
import TipOfTheWeek from './pages/AdminPages/TipOfTheWeek/TipOfTheWeek';
import UpdateTipOfTheWeek from './pages/AdminPages/TipOfTheWeek/UpdateTipOfTheWeek';
import Webinar from './pages/AdminPages/Webinar/Webinar';
import UpdateWebinar from './pages/AdminPages/Webinar/UpdateWebinar';
import Resources from './pages/Resources/Resources';
import UpdateResource from './pages/Resources/UpdateResource';
import DefaultTagGroup from './pages/AdminPages/Taggroup/Taggroup';
import Lenders from './pages/AdminPages/Lenders/Lender';
import Dealers from './pages/AdminPages/Dealers/Dealer';
import Deals from './pages/PropertyDeals/Deals';

// Auth context
export const AuthContext = createContext();
const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // Check if there's a token on localStorage and authenticate with it
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      const token = JSON.parse(localStorage.getItem('token'));
      const user = JSON.parse(localStorage.getItem('user'));
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          token,
        },
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <div className="App">
          <Layout
          style= {{ 
            backgroundColor: '#f0f2f5',
          }}
          >
          {state.isAuthenticated && (
              <Header/>
            )}
          
            <Switch>
              <Route path="/" exact component={Front} /> 
              <Switch>
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute exact path="/users" component={Users} />
                  <PrivateRoute exact path="/users/report" component={UsersReport} />
                  <PrivateRoute exact path="/admin/forumcategory" component={Category} />
                    {/* added by lav */}
                  <PrivateRoute exact path="/admin/interests" component={Interests} />  
                  <PrivateRoute exact path="/admin/roles" component={Roles} />  
                  <PrivateRoute exact path="/users/admin" component={AdminUsers} />
                  <PrivateRoute exact path='/admin/educations' component={Education}/>
                  <PrivateRoute exact path="/admin/podcasts" component={Podcast}/>
                  <PrivateRoute exact path="/admin/Blogs" component={Blogs}/>
                  <PrivateRoute exact path="/admin/books" component={Books}/>
                  <PrivateRoute exact path="/admin/sponsors" component={Sponsors}/>
                  <PrivateRoute exact path='/admin/resources' component={Resources}/>
                  <PrivateRoute exact path="/admin/forums" component={Forums}/>
                  <PrivateRoute path="/admin/subcategory" component={Subcategory} />
                  <PrivateRoute path="/admin/topic" component={Topic} />
                  <PrivateRoute exact path="/admin/pages" component={BasicPages}/>
                  <PrivateRoute exact path="/admin/around-corner" component={AroundCorner}/>
                  <PrivateRoute exact path="/admin/contact/result" component={Submissions}/>
                  <PrivateRoute exact path="/admin/faqs" component={Faq}/>
                  <PrivateRoute exact path="/admin/faq/:id/edit" component={FaqUpdate}/>
                  <PrivateRoute exact path="/admin/pages/:id/edit" component={BasicPageUpdate}/>
                  <PrivateRoute exact path="/admin/education/course/:id/edit" component={CourseUpdate}/>
                  <PrivateRoute exact path="/admin/resources/:id/edit" component={UpdateResource}/>
                  <PrivateRoute exact path="/admin/books/:id/edit" component={UpdateBooks}/>
                  <PrivateRoute exact path="/admin/sponsors/:id/edit" component={UpdateSponsors}/>
                  <PrivateRoute exact path="/admin/podcasts/:id/edit" component={UpdatePodcasts}/>
                  <PrivateRoute exact path="/admin/blogs/:id/edit" component={UpdateBlogs}/>
                  <PrivateRoute exact path="/admin/faqcategory" component={FaqCategory}/>
                  <PrivateRoute exact path="/admin/tipoftheweek" component={TipOfTheWeek}/>
                  <PrivateRoute exact path="/admin/tipoftheweek/:id/edit" component={UpdateTipOfTheWeek}/>
                  <PrivateRoute exact path="/admin/webinar" component={Webinar}/>
                  <PrivateRoute exact path="/admin/webinar/:id/edit" component={UpdateWebinar}/>
                  <PrivateRoute exact path="/admin/taggroup" component={DefaultTagGroup}/>
                  <PrivateRoute exact path="/admin/manage/lender" component={Lenders}/>
                  <PrivateRoute exact path="/admin/manage/dealer" component={Dealers}/>
                  <PrivateRoute exact path="/deals" component={Deals} />
                  <PrivateRoute exact path="/admin/add/page" component={AddPage} />
                  
              </Switch>      
              <Route path="*" exact component={Page404} />
            </Switch>         
          </Layout>
        </div>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
