import React, { Component } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from '../../utils/API';
import { Modal, Form, message } from 'antd';
import { withRouter } from 'react-router-dom';

class FaqUpdate extends Component {
    state = {
        _id: this.props.match.params.id,
        question: '',
        answer: '',
        status: false,
        snumber: '',
        showform: "false",
    };
    componentDidMount() {
        this.getData();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);


    };

    getData() {
        API.get('/faqs/contents/faq/' + this.state._id)
            .then(result => {
                const faqs = result.data.faqs;
                faqs.map((faq, index) => {
                    this.setState({ showform: 'true' });
                    this.setState({ _id: faq._id });
                    this.setState({ question: faq.question });
                    this.setState({ answer: faq.answer });
                    this.setState({ status: faq.status });
                    this.setState({ snumber: faq.snumber });
                    if (faq.status == 'true') {
                        this.setState({ status: true });
                    } else {
                        this.setState({ status: false });
                    }
                });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }
    /* Add FAQ */
    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({ answer: data });

    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }

    handlePublish(event) {
        const faqData = this.state;

        API.post('/faqs/update', faqData)
            .then(result => {
                message.success('Faq Updated');
                this.props.history.push('/admin/faqs');
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.response.data.msg,
                });
            });
    }
    /* Add FAQ End */


    render() {
        return (
            <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />

                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Update FAQ</h1>
                            <div className="new-post">
                                <Form
                                    name="faqform"
                                    layout="vertical"
                                    onFinish={this.handlePublish}
                                    size={'default'}>
                                    <div className="form-group">
                                        <h5 className="new-title">Question <span className="requiredsymbol">*</span></h5>
                                        <input
                                            className="form-control new-title"
                                            type="text"
                                            name="question"
                                            defaultValue={this.state.question}
                                            onChange={this.handleChange}
                                            required
                                            placeholder="FAQ Question"
                                        />
                                    </div>
                                    <div>
                                        <h5 className="new-body">Answer</h5>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            onChange={this.handleEditorChange}
                                            data={this.state.answer}
                                            config={{
                                                placeholder:
                                                    "Start typing faq answer here...",
                                                toolbar: [
                                                    "Heading",
                                                    "|",
                                                    "Bold",
                                                    "Italic",
                                                    "Link",
                                                    "NumberedList",
                                                    "BulletedList",
                                                    "|",
                                                    "BlockQuote",
                                                    "MediaEmbed",
                                                    "InsertTable",
                                                    "Undo",
                                                    "Redo",
                                                ],
                                            }}
                                        />
                                    </div>
                                    <div className="form-group sno-box">
                                        <label className="">Sequence Number</label>
                                        <input
                                            type="number"
                                            name="snumber"
                                            defaultValue={this.state.snumber}
                                            min={1}
                                            required
                                            onChange={this.handleChange}
                                            className="form-control" />
                                    </div>
                                    <div className="form-group publish-box">
                                        <input type="checkbox" checked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                        <label className="custom-control-label" for="customCheck1">Publish</label>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="submit"
                                            value="Save"
                                            className="btn btn-outline-primary btn-lg"
                                        />
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        /* }else{
            return(
                <div></div>
            )
        } */

    }

}
export default withRouter(FaqUpdate);