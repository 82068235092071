import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from "../../../utils/API";
import { message, Modal } from "antd";
import { withRouter } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
class UpdateWebinar extends Component {
  state = {
    _id: this.props.match.params.id,
    title: "",
    description: "",
    author: "",
    artistname: "",
    status: false,
    alias: "",
    catlists: [],
    selectedCategory: "",
    loading: false,
    error: false,
    webinarOrder: undefined,
    videoURL: "",
    videoDuration: "",
    publishedDate:""
  };
  componentDidMount() {
    this.getBlogDetail();
    this.getCategoryList();
    this.handleChange = this.handleChange.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleChangechk = this.handleChangechk.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.seeImage = this.seeImage.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    var daaa = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("token")) {
      this.setState({
        author: daaa.email,
      });
    }
  }

  getBlogDetail() {
    API.get("/webinar/contents/node/" + this.state._id)
      .then((result) => {
        console.log(result.data);
        //setCourseData(result.data.courses);
        //this.setState({courseData: result.data.courses});
        result.data.webinar.map((course, index) => {
          this.setState({ title: course.title });
          this.setState({ description: course.description });
          this.setState({ selectedCategory: course.selectedCategory[0] });
          this.setState({ status: course.status });
          this.setState({ artistname: course.artistname });
          this.setState({ videoURL: course.videoURL });
          this.setState({ publishedDate: course.publishedDate });
          this.setState({ videoDuration: course.videoDuration });
          this.setState({
            webinarOrder:
              course?.webinarOrder !== undefined ? course?.webinarOrder : "",
          });
          this.setState({ alias: course.pathalias.alias.slice(1) });

          if (course.status == "true") {
            this.setState({ status: true });
          } else {
            this.setState({ status: false });
          }
        });
      })
      .catch((e) => {
        Modal.error({
          title: "An error occurred",
          content: e.message,
        });
      });
  }

  getCategoryList() {
    API.get("/categories/")
      .then((result) => {
        const catlists = result.data.categories;
        this.setState({ catlists });
      })
      .catch((e) => {
        Modal.error({
          title: "An error occurred",
          content: e.message,
        });
      });
  }
  handleClickCover = (event) => {
    document.getElementById("selectcoverimage").click();
  };

  courseCateSelect = () => {
    if (this.state.selectedCategory != "") {
      return (
        <select
          id="cat-select"
          className="custom-select"
          required
          onChange={this.handleChange}
          name="selectedCategory"
        >
          <option value="">Select Category</option>
          {this.state.catlists.map((catlist) => (
            <option
              value={catlist._id}
              selected={
                this.state.selectedCategory._id == catlist._id ? true : false
              }
            >
              {catlist.name}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <select
          id="cat-select"
          className="custom-select"
          required
          onChange={this.handleChange}
          name="selectedCategory"
        >
          <option value="">Select Category</option>
          {this.state.catlists?.map((catlist) => (
            <option value={catlist._id}>{catlist.name}</option>
          ))}
        </select>
      );
    }
  };

  handlePublish(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const courseData = this.state;
    API.post("/webinar/update", courseData)
      .then((result) => {
        this.props.history.goBack();
        this.setState({ loading: false });
        message.success("Webinar updated", 3);
      })
      .catch((e) => {
        this.setState({ loading: false });
        // $('#add-book-modal').modal('hide');
        Modal.error({
          title: "An error occurred",
          content: e.response.data.msg,
        });
      });
  }
  handleEditorChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ description: data });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };
  handleChangePosition = (event) => {
    const { name, value } = event.target;
    if (event.target.value != "") {
      this.setState({
        [name]: parseInt(Math.abs(value)),
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  };
  getImageBase64 = async (file) => {
    return await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (event.target.result) {
          resolve(event.target.result);
        } else {
          reject("Please try again.");
        }
      };
    });
  };

  handleChangechk(event) {
    this.setState({ status: !this.state.status });
  }

  seeImage() {
    this.setState({ visibleImage: true });
  }
  handleCancel() {
    this.setState({ visible: false });
    this.setState({ visibleImage: false });
  }

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Sidebar />

        <div id="page-content-wrapper">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Webinar</h4>
              </div>
              <div className="modal-body">
                <div className="new-post">
                  <form onSubmit={this.handlePublish}>
                    <div className="form-group">
                      <h5 className="l-duration">Webinar Order: </h5>
                      <input
                        className="form-control video-position"
                        pattern="[0-9]"
                        min="1"
                        type="number"
                        name="webinarOrder"
                        value={this.state.webinarOrder}
                        onChange={this.handleChangePosition}
                        placeholder="Webinar order"
                      />
                    </div>
                    <div class="alert alert-info">
                      1. Order should be greater than 0
                    </div>
                    <div className="form-group">
                      <h5 className="new-title">
                        Title: <span className="requiredsymbol">*</span>
                      </h5>
                      <input
                        className="form-control new-title"
                        type="text"
                        name="title"
                        value={this.state.title}
                        onChange={this.handleChange}
                        required
                        placeholder="Title"
                        maxLength="100"
                      />
                    </div>
                    <div>
                      <h5 className="new-body">Webinar Description: </h5>
                      <textarea
                        style={{ height: "400px" }}
                        onChange={this.handleChange}
                        value={this.state.description}
                        name="description"
                        className="form-control new-title"
                        placeholder="Start typing book detail here..."
                      />
                    </div>
                    <div className="form-group">
                      <h5 className="new-title">Author: </h5>
                      <input
                        className="form-control new-title"
                        type="text"
                        name="artistname"
                        value={this.state.artistname}
                        onChange={this.handleChange}
                        placeholder="Author Name"
                      />
                    </div>
                    {/* <div className="form-group">
                      <h5 className="l-duration">Video Duration:</h5>
                      <input
                        className="form-control video-duration"
                        type="text"
                        name="videoDuration"
                        value={this.state.videoDuration}
                        onChange={this.handleChange}
                        placeholder="ex: 8 min"
                      />
                    </div> */}
                    <div className="form-group">
                      <h5 className="l-duration">
                        Vimeo URL: <span className="requiredsymbol">*</span>
                      </h5>
                      <div className="input-box">
                        <span class="prefix">https://vimeo.com/</span>
                        <input
                          className="form-control video-url"
                          type="number"
                          min="1"
                          name="videoURL"
                          value={this.state.videoURL}
                          onChange={this.handleChange}
                          required
                          placeholder="123456789"
                        />
                      </div>
                      <div className="form-group">
                      <h5 className="new-title">
                        Published Date:{" "}
                      
                      </h5>
                      <input
                        className="form-control new-title"
                        type="date"
                        name="publishedDate"
                        value={this.state.publishedDate}
                        onChange={this.handleChange}
                      
                        placeholder="Published Date"
                      />
                    </div>
                    </div>
                    <div className="form-group col-md-6 p-0 mt-3">
                      <h5 className="cat-select">
                        Category: <span className="requiredsymbol">*</span>
                      </h5>
                      {this.courseCateSelect()}
                    </div>
                    <div class="form-group">
                      {/* <div>
                        <input type="radio" value="free" name="visibilityoption" id="free" required onChange={this.handleChange}/>
                        <label htmlFor="free">Free</label>
                        <input type="radio" value="paid" name="visibilityoption" id="paid" onChange={this.handleChange}/>
                        <label htmlFor="paid">Paid</label>
                    </div> */}
                    </div>
                    <div class="form-group">
                      <label className="lable-title">
                        Path <span className="requiredsymbol">*</span>
                      </label>
                      <div className="input-box">
                        <span class="prefix">/</span>
                        <input
                          className="form-control"
                          type="text"
                          name="alias"
                          value={this.state.alias}
                          onChange={this.handleChange}
                          required
                          placeholder="webinar/my_webinars"
                        />
                      </div>
                    </div>
                    <div
                      style={{ marginLeft: "16px" }}
                      className="form-group publish-box"
                    >
                      <input
                        type="checkbox"
                        checked={this.state.status}
                        onChange={this.handleChangechk}
                        className="custom-control-input"
                        id="customCheck1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        Publish
                      </label>
                    </div>
                    {this.state.loading && (
                      <div
                        style={{ textAlign: "center" }}
                        class="spinner-border"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                    <div className="form-group">
                      <input
                        disabled={
                          this.state.loading ||
                          this.state.webinarOrder == "" ||
                          this.state.webinarOrder == undefined
                            ? false
                            : this.state.webinarOrder < 1
                        }
                        type="submit"
                        value="Update"
                        className="btn btn-outline-primary btn-lg"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UpdateWebinar);
