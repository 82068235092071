import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import Sidebar from '../../components/Sidebar/Sidebar';
import { Modal, Popconfirm, message, Button } from 'antd';
import API from '../../utils/API';
import AddInterests from './AddInterests';
import Moment from 'moment';
import ReactDatatable from '@ashvin27/react-datatable';


class Interests extends Component {

  state = {
    datalists: [],
    currentRecord: {
      _id: '',
      name: '',
      createdAt: '',
    },
    updatevisible: false,
    loading: false,
    name: '',
  }
  columns = [
    {
      key: "_id",
      text: "Id",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Category Name",
      className: "capitalize",
      align: "left",
      sortable: true,
    },
    {
      key: "createdAt",
      text: "Created Date",
      className: "date",
      align: "left",
      sortable: true
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: datalist => {
        return (
          <Fragment>
            <Button
              style={{ marginRight: '5px' }}
              onClick={() => this.editRecord(datalist)}
              className="btn btn-primary btn-sm">
              <i className="fa fa-edit"></i>
            </Button>
            <Popconfirm
              title="Are you sure to delete this Interest?"
              onConfirm={this.confirm}
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
            >
              <button
                className="btn btn-danger btn-sm"
                title="Delete"
                onClick={() => this.deleteRecord(datalist)}>
                <i className="fa fa-trash"></i>
              </button>
            </Popconfirm>
          </Fragment>
        );
      }
    }
  ];
  config = {
    key_column: '_id',
    page_size: 10,
    length_menu: [10, 20, 50, 100],
    filename: "Forum Category",
    no_data_text: 'No Page found!',

    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last"
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  componentDidMount() {
    this.getData();
    this.confirm = this.confirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFinishRequest = this.onFinishRequest.bind(this);
  };
  componentWillReceiveProps(nextProps) {
    this.getData()
  }
  getData() {
    API.get('/interests/')
      .then(result => {
        const catlists = result.data.interets;
        var catdata = [];
        catlists.map((cat, index) => {
          catdata[index] = {
            _id: cat._id,
            name: cat.name,
            createdAt: Moment(cat.createdAt).format('MMM D, YYYY')
          }
        });
        this.setState({ datalists: catdata })
      })
      .catch(e => {
        Modal.error({
          title: 'An error occurred',
          content: e.message,
        });
      });
  }
  editRecord(datalist) {
    this.setState({ currentRecord: datalist });
    this.setState({ updatevisible: true });
    this.setState({ name: datalist.name });
  }
  deleteRecord(datalist) {
    this.setState({ currentRecord: datalist });
  }
  confirm() {
    API.post("/interests/in-delete", { id: this.state.currentRecord._id })
      .then(res => {
        if (res.status === 200) {
          this.getData();
          message.success('Interest deleted');
        }
      })
      .catch(e => {
        Modal.error({
          title: 'An error occurred',
          content: e.message,
        });
      });
  }
  onCancel = () => {
    this.setState({ updatevisible: false });
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onFinishRequest(event) {
    event.preventDefault();
    this.setState({ loading: true })
    API.post('/interests/in-update', {
      id: this.state.currentRecord._id,
      name: this.state.name,
    })
      .then(result => {
        this.setState({ loading: false });
        this.setState({ updatevisible: false });
        message.success('Interest Updated');
        this.getData();
      })
      .catch(e => {
        this.setState({ loading: false });
        this.setState({ updatevisible: false });
        Modal.error({
          title: 'An error occurred',
          content: e.response.data.msg,
        });
      });
  }

  cancel(e) {
  }
  pageChange(pageData) {
  }

  render() {
    const updatePopup = () => {
      if (this.state.currentRecord._id) {
        return <Modal
          visible={this.state.updatevisible}
          okText="Update"
          onCancel={this.onCancel}
          onOk={this.onFinishRequest}
          centered="true"
          closable={false}
          footer={null}
        >
          <form onSubmit={this.onFinishRequest}>
            <h3 className="mt-2 text-primary">Update Interest</h3>
            <div className="form-group">
              <h5 className="new-title">Interest Name </h5>
              <input
                className="form-control"
                type="text"
                name="name"
                value={this.state.name || ''}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="button"
                value="Cancel"
                className="btn"
                onClick={this.onCancel}
              />
              <input
                type="submit"
                value="Update"
                disabled={this.state.loading}
                className="btn btn-outline-primary btn-lg"
              />

            </div>
          </form>
        </Modal>
      }
    }
    return (
      <div>
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <AddInterests />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
              <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-category-modal">Add Interests</button>
              <h1 className="mt-2 text-primary">User Interests</h1>
              <ReactDatatable
                config={this.config}
                records={this.state.datalists}
                columns={this.columns}
                onPageChange={this.pageChange.bind(this)}
              />
            </div>
          </div>
          {updatePopup()}
        </div>
      </div>
    );
  }
};

export default Interests;