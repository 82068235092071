import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import Sidebar from '../../components/Sidebar/Sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from '../../utils/API';
import { Modal, Form, Popconfirm, message } from 'antd';
import $ from 'jquery';
import { Redirect, Link } from 'react-router-dom';

class Faq extends Component {
    columns = [
        {
            key: "snumber",
            text: "S.No",
            className: "id",
            align: "left",
            sortable: true,
        },
        {
            key: "question",
            text: "Question",
            className: "capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "answer",
            text: "Answer",
            className: "capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        <Link
                            to={'/admin/faq/' + record._id + '/edit'}
                            style={{ marginRight: '5px' }}
                            className="btn btn-primary btn-sm">
                            <i className="fa fa-edit"></i>
                        </Link>
                        <Popconfirm
                            title="Are you sure to delete this Faq?"
                            onConfirm={this.confirm}
                            onCancel={this.cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <button
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Popconfirm>
                    </Fragment>
                );
            }
        }
    ];

    config = {
        key_column: 'snumber',
        page_size: 10,
        length_menu: [10, 20, 50, 100],
        filename: "FAQ",
        no_data_text: 'No record found!',
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: true,
        show_filter: false,
        show_pagination: true,
        show_info: true,
    };

    state = {
        records: [],
        question: '',
        answer: '',
        status: true,
        snumber: 1,
        author: '',
        makeupdate: false,
        currentRecord: {
            _id: '',
            question: '',
            answer: '',
            status: '',
            snumber: '',
            registerDate: '',
        }
    };
    // this.getData = this.getData.bind(this);

    componentDidMount() {
        this.getData();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.confirm = this.confirm.bind(this);
        if (localStorage.getItem("token")) {
            var daaa = JSON.parse(localStorage.getItem('user'));
            this.setState({
                author: daaa.email,
            });
        }
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        API.get('/faqs/contents')
            .then(result => {
                const faqs = result.data.faqs;
                var faqsdata = [];
                faqs.map((faq, index) => {
                    faqsdata[index] = {
                        _id: faq._id,
                        question: faq.question,
                        answer: faq.answer,
                        status: faq.status,
                        snumber: faq.snumber,
                        createdAt: faq.createdAt
                    }
                });
                this.setState({ records: faqsdata });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }

    deleteRecord(record) {
        this.setState({ currentRecord: record });
    }
    editRecord(record) {
        this.setState({ currentRecord: record });
        this.setState({ makeupdate: true });
        return <Redirect to={"/faq/" + this.state.currentRecord._id + "edit"} />;
    }
    confirm() {
        API.post("/faqs/content-delete", { id: this.state.currentRecord._id })
            .then(res => {
                if (res.status === 200) {
                    this.getData();
                    message.success('Faq deleted');
                }
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });

    }

    cancel(e) {
        /* message.error('Click on No'); */
    }

    pageChange(pageData) {

    }

    /* Add FAQ */
    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({ answer: data });
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }

    handlePublish(event) {

        const faqData = this.state;
        API.post('/faqs/add', faqData)
            .then(result => {

                $('#add-faq-modal form')[0].reset();
                $('#add-faq-modal').modal('hide');
                this.getData();
                //window.location.reload();
            })
            .catch(e => {
                $('#add-faq-modal').modal('hide');
                Modal.error({
                    title: 'An error occurred',
                    content: e.response.data.msg,
                });
            });

    }
    /* Add FAQ End */


    render() {
        return (
            <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />

                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <button onClick={this.addNew} className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-faq-modal">Add New FAQ</button>
                            <h1 className="mt-2 text-primary">FAQ's</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>

                    {/* Add FAQ */}
                    <div className="modal fade" id="add-faq-modal" data-reset="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Create New FAQ</h4>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <div className="new-post">
                                        {/* <form onSubmit={this.handlePublish}> */}
                                        <Form
                                            name="faqform"
                                            layout="vertical"
                                            onFinish={this.handlePublish}
                                            size={'default'}>
                                            <div className="form-group">
                                                <h5 className="new-title">Question <span className="requiredsymbol">*</span></h5>
                                                <input
                                                    className="form-control new-title"
                                                    type="text"
                                                    name="question"
                                                    defaultValue={this.state.currentRecord.question}
                                                    onChange={this.handleChange}
                                                    required
                                                    placeholder="FAQ Question"
                                                />
                                            </div>
                                            <div>
                                                <h5 className="new-body">Answer</h5>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    onChange={this.handleEditorChange}
                                                    data={this.state.currentRecord.answer}
                                                    config={{
                                                        placeholder:
                                                            "Start typing faq answer here...",
                                                        toolbar: [
                                                            "Heading",
                                                            "|",
                                                            "Bold",
                                                            "Italic",
                                                            "Link",
                                                            "NumberedList",
                                                            "BulletedList",
                                                            "|",
                                                            "BlockQuote",
                                                            "MediaEmbed",
                                                            "InsertTable",
                                                            "Undo",
                                                            "Redo",
                                                        ],
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group sno-box">
                                                <label className="">Sequence Number</label>
                                                <input
                                                    type="number"
                                                    name="snumber"
                                                    defaultValue={this.state.snumber}
                                                    min={1}
                                                    required
                                                    onChange={this.handleChange}
                                                    className="form-control" />
                                            </div>
                                            <div className="form-group publish-box">
                                                <input type="checkbox" defaultChecked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                                <label className="custom-control-label" for="customCheck1">Publish</label>
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="submit"
                                                    value="Save"
                                                    className="btn btn-outline-primary btn-lg"
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add FAQ */}
                </div>
            </div>
        );
    }

}
export default Faq;