import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import Sidebar from '../../components/Sidebar/Sidebar';

const AroundCorner = () => {
  return (
    <div>
        <div className="d-flex" id="wrapper">
            <Sidebar/>
            <div id="page-content-wrapper">
                <div className="container-fluid">
                    <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                    <h1 className="mt-2 text-primary">Around The Corner</h1>
                    <div className="row px-2">
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default AroundCorner;
