import React, { Component, Fragment } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import API from '../../../utils/API';
import {Modal, Popconfirm, message, Card, Col, Row, Form, Input, Button, AutoComplete} from 'antd';

class Taggroup extends Component{
  state ={
    currentValue: '',
    currentId: '',
    add_disabled: true,
    option : [],
    loaded: false,
    updateId: ''
  }
  dataSource = [];
  dataSourceGrouped=[];
  
  componentDidMount() {
    this.getData();
    this.setCurrentValue = this.setCurrentValue.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
  };

  getData() {
    this.dataSourceGrouped = [];
    API.get('/users/fetchtata')
    .then(result => {
      if(result?.data?.users){
        result.data.users.map((u,key)=>{
          if(!u.taggroup){
            this.dataSource.push({ key: key, value:u.firstname+' '+u.lastname+' ('+u.email+') ', id:u._id})
          }else if(u.taggroup){
            this.dataSourceGrouped.push({ key: key, fullname:u?.firstname+' '+u?.lastname, email:u?.email, id:u._id, avatar:u?.avatar})
          }
          if(key+1 == result.data.users.length){
            this.setState({loaded:true})
          }
        })
      }
    })
    .catch(e => {
      Modal.error({
        title: 'An error occurred',
        content: e.message,
      });
    });
  }
  handleSubmit = e => {
    console.log('ssss')
    console.log(this.state.currentId);
    API.post('/users/usertag/tata',{id:this.state.currentId})
    .then((result)=>{
      //window.location.reload();
      this.setState({ currentValue: ''});
      this.setState({currentId: ''})
      this.setState({add_disabled: true})
      this.getData()
    })
  };

  setCurrentValue = (val, option) => {
    this.setState({ currentValue: option.value});
    this.setState({currentId: option.id})
    this.setState({add_disabled: false})
  };
 
  handleSearch = val => {
    this.setState({currentId: ''})
    this.setState({add_disabled: true})
    let filtered = this.dataSource.filter(
      obj =>
        obj.key !== 0 &&
        obj.value
          .toString()
          .toLowerCase()
          .includes(val)
    );
    this.setState({option:filtered});
  };

  deleteConfirm = () => {
    API.post("/users/userdtaggroup/up", { id: this.state.updateId })
    .then(res => {
        if (res.status === 200) {
            message.success('User removed from @ group list');
        }
    })
    .catch();
    this.getData();
  }
  render(){
    const dataSourceGrouped = this.dataSourceGrouped;
    console.log('dataSourceGrouped: ',dataSourceGrouped)
    return (
      <div>
          <div className="d-flex" id="wrapper">
              <Sidebar/>
              <div id="page-content-wrapper">
                  <div className="container-fluid">
                      <h1 className="mt-2 text-primary">Tag Group Member List</h1>
                      <div style={{ display: 'block', padding: 30, margin: 'auto', }}>
                        <Row gutter={16}>
                          <Col span={8}>
                            <Form layout="inline">
                              <Form.Item>
                                <AutoComplete
                                  options={this.state.option}
                                  style={{ width: 350 }}
                                  onSelect={(val, option)=> {
                                      this.setCurrentValue(val, option)
                                  }}
                                  onSearch={this.handleSearch}
                                  placeholder="Search by Name or Email Id"
                                />
                              </Form.Item>
                              <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={this.state.add_disabled} onClick={this.handleSubmit}>
                                  Add
                                </Button>
                              </Form.Item>
                            </Form>
                          </Col>
                        </Row>                   
                      </div>

                      <Row gutter={16}>
                        {this.state.loaded && dataSourceGrouped.map((u) =>{
                          return <Col span={5}>
                            <Card>
                              <h3>{u.fullname}</h3>
                              <a href={"mailto:"+u.email}>{u.email}</a>
                              <Popconfirm
                              title="Want to remove this user from @ group list?"
                              onConfirm={this.deleteConfirm}
                              okText="Yes"
                              cancelText="No"
                              >
                              <button
                                  className="btn btn-danger btn-sm"
                                  title="Delete"
                                  style={{ marginRight: '5px' }}
                                  onClick={() => this.setState({updateId: u.id})}>
                                  <i className="fa fa-trash"></i>
                              </button>
                            </Popconfirm>
                            </Card>
                           </Col>
                        })}
                      </Row>
                  </div>
              </div>
          </div>
      </div>
    );
  }

};

export default Taggroup;

