import React, { useState } from 'react';
import { Form, Input, Modal, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import API from '../../../utils/API';

const LenderInvite = props => {
  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    setLoading(true);
    API.post('/users/b/li', {
      email: values.email.toLowerCase().trim(),
      firstname: values.firstname.trim(),
      lastname: values.lastname.trim(),
      username: values.email.toLowerCase().trim(), //assign email as username
      commission: values.commission ? values.commission : 0
    })
      .then(() => {
        window.location.reload();
      })
      .catch(e => {
        setLoading(false);
        Modal.error({
          title: 'Invitation Failed',
          content: e.response.data.msg,
        });
      });
  };

  return (
          <div className="">
            <div className="">
              <Form
                name="signup"
                layout="vertical"
                onFinish={onFinish}
                size = {'default'}
              >
                <Form.Item
                  label="Nombre"
                  name="firstname"
                  className="formitem"
                  rules={[
                    {
                      required: true,
                      message: 'Nombre',
                    },
                  ]}
                >
                  <Input disabled={loading} placeholder="Enter your First Name" className="formtext"/>
                </Form.Item>
                <Form.Item
                  label="Apellido"
                  name="lastname"
                  className="formitem"
                  rules={[
                    {
                      required: true,
                      message: 'Enter your Last Name',
                      min: 3,
                    },
                  ]}
                >
                  <Input disabled={loading} placeholder="Apellido" className="formtext"/>
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  className="formitem"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid email',
                      type: 'email',
                    },
                  ]}
                >
                  <Input disabled={loading} placeholder="Enter your Email ID" className="formtext"/>
                </Form.Item>
                <Form.Item
                  label="Comision"
                  name="commission"
                  rules={[
                    {
                      required: true,
                      message: 'This is required',
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    placeholder='Commission'
                    disabled={loading}
                  />
                </Form.Item>
                <Form.Item
                  style={{textAlign: 'center' }} 
                  className="formaction"             
                >
                  <input disabled={loading} type="submit" value="Sent Invite" className="formsubmit"></input>
                </Form.Item>
              </Form>
              
            </div>
          </div>
          
  );
};

export default LenderInvite;
