import React, { useContext } from 'react';
import { Menu, Modal } from 'antd';
import styles from './Header.module.css';
import { AuthContext } from '../../App';
import { NavLink, useLocation, useHistory } from 'react-router-dom';

// antd
/* const { Header: AntHeader } = Layout; */

const Header = () => {
  const { dispatch, state } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  /* let boardsKey = '/';

  if (location.pathname === '/subcategory' || location.pathname === '/topic') {
    boardsKey = location.pathname;
  }
 */
  const logout = () => {
    Modal.confirm({
      title: 'Logout?',
      onOk: () => {
        dispatch({
          type: 'LOGOUT',
        });
        history.push('/');
      },
    });
  };

  return (
    <header>
      <div className={styles.headerarea}>
        <div className={styles.logoarea}>
          <div className={styles.LogoContainer}>
            <a href="/" className={styles.Logo}>
              En La<span>Cancha</span>
            </a>
          </div>
        </div>
        <div className={styles.menuarea}>
        </div>
        <div className={styles.signuparea}>
          <Menu
            mode="horizontal"
            activeKey={location.pathname}
            selectedKeys={[location.pathname]}
            className={styles.menu}
          >
            {state.isAuthenticated && (
              <Menu.Item onClick={logout}>
                Logout
              </Menu.Item>
            )}
            {state.isAuthenticated && (
              <Menu.Item key="/user">
                <NavLink to="/">{state.user.firstname}</NavLink>
              </Menu.Item>
            )}
            {!state.isAuthenticated && (
              <Menu.Item key="/register">
                <NavLink to="/register">Signup</NavLink>
              </Menu.Item>
            )}
            {!state.isAuthenticated && (
              <Menu.Item key="/login">
                <NavLink to="/login">Login</NavLink>
              </Menu.Item>
            )}
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
