import React, { Component } from 'react';
import API from '../../../utils/API';
import { Modal } from 'antd'
import $ from 'jquery';

class AddSponsors extends Component {
  state = {
    sponsorname: "",
    description: "",
    author: "",
    artistname: "",
    status: false,
    alias: "",
    sponsor: undefined,
    coverimage: undefined,
    catlists: [],
    selectedCategory: "",
    loading: false,
    errorImage: false,
    errorDocument: false,
    sponsorBase64: undefined,
    visible: false,
    coverImageBase64: undefined,
    visibleImage: false,
    sponsorUploadType: "pdf",
    sponsorurl: undefined,
    sponsorOrder: "",
    errorBannerImage: false,
    bannerimage: undefined,
    bannerImageBase64: undefined,
    stateList : [],
    cityList : [],
    selectedState: '',
    selectedCity: ''

  };
  componentDidMount() {
    this.getCategoryList();
    this.handleChange = this.handleChange.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleChangechk = this.handleChangechk.bind(this);
    var daaa = JSON.parse(localStorage.getItem('user'));
    if (localStorage.getItem("token")) {
      this.setState({
        author: daaa.email,
      });
    }
  API.get('/location/details/state').then(async result => {
    this.setState({stateList : result?.data?.statelist})
   }).catch(err => {
     console.log(err);
   })
  };

  getCategoryList() {
    API.get('/categories/')
    .then(result => {
      const catlists = result.data.categories;
      this.setState({ catlists });
    })
    .catch(e => {
      Modal.error({
        title: 'An error occurred',
        content: e.message,
      });
    });
  }
  handleStateChange = (event) => {
    const { name, value } = event.target;
    let match = value.match(/\((.*?)\)/);
    this.setState({
      [name]: value,
      cityList: [],   // Reset cityList to an empty array
      selectedCity: "" // Reset selected city
    });
    if(match){
      let stateCode = match[1]
      API.get('/location/details/city/US/'+stateCode).then(async result => {
        this.setState({cityList: result.data.citylist});
      }).catch(err => {
        console.log(err);
      })
    }
  }
  handlePublish(event) {
    this.setState({ loading: true })
    event.preventDefault();
    const courseData = this.state;
    API.post('/sponsors/add', courseData)
      .then(result => {
        this.setState({ loading: false })
        $('#add-sponsor-modal').modal('hide');
        window.location.reload();
      })
      .catch(e => {
        this.setState({ loading: false })
        $('#add-sponsor-modal').modal('hide');
        Modal.error({
          title: 'An error occurred',
          content: e.response.data.msg,
        });
      });
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  }
  getImageBase64 = async (file) => {
    return await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (event.target.result) {
          resolve(event.target.result);
        } else {
          reject('Please try again.');
        }
      };
    });
  }
  handleChangePosition = (event) => {
    const { name, value } = event.target;
    if (event.target.value != "") {
      this.setState({
        [name]: parseInt(Math.abs(value)),
      })
    } else {
      this.setState({
        [name]: "",
      })
    }

  }
  handleImage = (event) => {
    this.setState({ errorImage: false })
    if (event.target.files[0].size <= 10485760) {

      this.getImageBase64(event.target.files[0]).then(res => {

        this.setState({ coverImageBase64: res })
        this.setState({ coverimage: res })

      }).catch(error => {
        console.error(error);
      })
    } else {
      this.setState({ coverimage: undefined })
      this.setState({ errorImage: true })
    }
  }
  handleBannerImage = (event) => {
    this.setState({ errorBannerImage: false })
    if (event.target.files[0].size <= 10485760) {

      this.getImageBase64(event.target.files[0]).then(res => {

        this.setState({ bannerImageBase64: res })
        this.setState({ bannerimage: res })

      }).catch(error => {
        console.error(error);
      })
    } else {
      this.setState({ bannerimage: undefined })
      this.setState({ errorBannerImage: true })
    }
  }
  handleChangechk(event) {
    this.setState({ status: !this.state.status });
  }

  render() {
    const {cityList} = this.state;
    return (

      <div>
        <div className="modal fade" id="add-sponsor-modal" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add New Sponsor</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <div className="new-post">
                  <form onSubmit={this.handlePublish}>
                    <div className="form-group">
                      <h5 className="l-duration">Sponsor Order: </h5>
                      <input
                        className="form-control video-position"
                        pattern="[0-9]"
                        type="number"
                        min="1"
                        value={this.state.sponsorOrder}
                        name="sponsorOrder"
                        onChange={this.handleChangePosition}
                        placeholder="Sponsor order"
                      />
                    </div>
                    <div className="alert alert-info">
                      1. Order should be greater than 0
                    </div>
                    <div className="form-group">
                      <h5 className="new-title">Sponsor Name: <span className="requiredsymbol">*</span></h5>
                      <input
                        className="form-control new-title"
                        type="text"
                        name="sponsorname"
                        value={this.state.sponsorname}
                        onChange={this.handleChange}
                        required
                        placeholder="Sponsor Name"
                      />
                    </div>

                    <div>
                      <h5 className="new-body">Sponsor Description: </h5>
                      <textarea
                        style={{ height: '400px' }}

                        onChange={this.handleChange}
                        value={this.state.description}
                        name="description"
                        className="form-control new-title"
                        placeholder=
                        "Start typing sponsor detail here..."


                      />
                    </div>
                    <div className="form-group col-md-6 p-0 mt-3">
                      <h5 className="cat-select" >Category <span className="requiredsymbol">*</span></h5>
                      <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                        <option value="" key="0">Select Category</option>
                        {this.state.catlists.map(catlist => <option value={catlist._id} key={catlist._id}>{catlist.name}</option>)}
                      </select>
                    </div>
                    <div className="form-group">
                      <h5 className="new-title">Sponsor URL:</h5>
                      <input
                        className="form-control new-title"
                        type="url"
                        name="sponsorurl"
                        value={this.state.sponsorurl}
                        onChange={this.handleChange}

                        placeholder="Sponsor url (ex. https://example..)"
                      />
                    </div>
                    <div className="form-group col-md-6 p-0 mt-3">
                      <h5 className="state-city-select" >Location <span className="requiredsymbol">*</span></h5>
                      <select id="state-city-select" className="custom-select" required onChange={this.handleStateChange} name="selectedState">
                        <option value="" key="0">Select State</option>
                        {this.state.stateList.map ( (res, key) => <option  key = {key} iso = {res.isoCode} value= {res.name + (' ('+res.isoCode.toUpperCase()+')')}>{res.name} ({res.isoCode})</option>)}
                      </select>
                      <select id="city-select" className="custom-select" required onChange={this.handleChange} name="selectedCity">
                      <option value="" key="0">Select City</option>
                      {cityList.map((c, k) => (
                        <option key={k} value={c.name}>{c.name}</option>
                      ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <h5 className="new-title">Upload Thumbnail image: <span className="requiredsymbol">*</span></h5>
                      <input
                        className="form-control new-title"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={this.handleImage}
                        required
                        placeholder="Sponsor Thumbnail"
                      />
                    </div>
                    {this.state.errorImage ? <div className="alert alert-danger">
                      Image size should be 10MB or less than 10MB
                    </div> : <div className="alert alert-info">
                      Image size should be 10MB or less than 10MB
                    </div>}

                    <div className="form-group">
                      <h5 className="new-title">Upload Banner image: <span className="requiredsymbol">*</span></h5>
                      <input
                        className="form-control new-title"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={this.handleBannerImage}
                        required
                        placeholder="Sponsor Banner Image"
                      />
                    </div>
                    {this.state.errorBannerImage ? <div className="alert alert-danger">
                      Image size should be 10MB or less than 10MB
                    </div> : <div className="alert alert-info">
                      Image size should be 10MB or less than 10MB
                    </div>}


                    <div className="form-group">
                      <label className="lable-title">Path <span className="requiredsymbol">*</span></label>
                      <div className="input-box">
                        <span className="prefix">/</span>
                        <input
                          className="form-control"
                          type="text"
                          name="alias"
                          value={this.state.alias}
                          onChange={this.handleChange}
                          required
                          placeholder="sponsors/my_sponsors"
                        />
                      </div>
                      <div className="form-group publish-box">
                        <input type="checkbox" defaultChecked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                        <label style={{ marginLeft: "16px" }} className="custom-control-label" htmlFor="customCheck1">Publish</label>
                      </div>

                    </div>
                    {this.state.loading && <div style={{ textAlign: 'center' }} className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>}
                    <div className="form-group">
                      <input disabled={this.state.loading || this.state.coverimage == undefined || (this.state.sponsorOrder == "" || this.state.sponsorOrder == undefined) ? false : this.state.sponsorOrder < 1}
                        type="submit"
                        value="Save"
                        className="btn btn-outline-primary btn-lg"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    );
  }
}

export default AddSponsors;