import React, { Component } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from '../../../utils/API';
import { Modal } from 'antd'

import $ from 'jquery';

class AddBlogs extends Component {


    state = {
        blogname: "",
        description: "",
        author: "",
        artistname: "",
        status: false,
        alias: "",
        coverimage: undefined,
        catlists: [],
        selectedCategory: "",
        loading: false,
        error: false,
        blogOrder: ""

    };
    componentDidMount() {
        this.getCategoryList();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        var daaa = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem("token")) {
            this.setState({
                author: daaa.email,
            });
        }

    };

    getCategoryList() {
        API.get('/categories/')
            .then(result => {
                const catlists = result.data.categories;
                this.setState({ catlists });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }



    handlePublish(event) {
        this.setState({ loading: true })
        event.preventDefault();
        const courseData = this.state;
        API.post('/blogs/add', courseData)
            .then(result => {
                this.setState({ loading: false })
                $('#add-book-modal').modal('hide');
                window.location.reload();
            })
            .catch(e => {
                this.setState({ loading: false })
                $('#add-book-modal').modal('hide');
                Modal.error({
                    title: 'An error occurred',
                    content: e.response.data.msg,
                });
            });
    }
    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({ description: data });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
        Math.abs(this.state.blogOrder)
    }
    handleChangePosition = (event) => {
        const { name, value } = event.target;
        if (event.target.value != "") {
            this.setState({
                [name]: parseInt(Math.abs(value)),
            })
        } else {
            this.setState({
                [name]: "",
            })
        }

    }
    getImageBase64 = async (file) => {
        return await new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                if (event.target.result) {
                    resolve(event.target.result);
                } else {
                    reject('Please try again.');
                }
            };
        });
    }

    handleImage = (event) => {
        this.setState({ error: false })
        if (event.target.files[0].size <= 10485760) {


            this.getImageBase64(event.target.files[0]).then(res => {


                this.setState({ coverimage: res })

            }).catch(error => {
                console.error(error);
            })
        } else {
            this.setState({ coverimage: undefined })
            this.setState({ error: true })
        }
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }
    render() {
        return (

            <div>
                <div className="modal fade" id="add-book-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add New Blog</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="new-post">
                                    <form onSubmit={this.handlePublish}>
                                        <div className="form-group">
                                            <h5 className="l-duration">Blog Order: </h5>
                                            <input
                                                className="form-control video-position"
                                                pattern="[0-9]"
                                                type="number"
                                                name="blogOrder"
                                                min="1"
                                                value={this.state.blogOrder}
                                                onChange={this.handleChangePosition}
                                                placeholder="Blog order"
                                            />
                                        </div>
                                        <div class="alert alert-info">
                                            1. Order should be greater than 0.
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Blog Name: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="blogname"
                                                value={this.state.blogname}
                                                onChange={this.handleChange}
                                                required
                                                placeholder="Blog Name"
                                            />
                                        </div>

                                        <div>
                                            <h5 className="new-body">Blog Description: </h5>
                                            <CKEditor

                                                editor={ClassicEditor}
                                                onChange={this.handleEditorChange}
                                                config={{
                                                    placeholder:
                                                        "Start typing blog description here...",
                                                    toolbar: [
                                                        "Heading",
                                                        "|",
                                                        "Bold",
                                                        "Italic",
                                                        "Link",
                                                        "NumberedList",
                                                        "BulletedList",
                                                        "|",
                                                        "BlockQuote",
                                                        "MediaEmbed",
                                                        "ImageUpload",
                                                        "InsertTable",
                                                        "Undo",
                                                        "Redo",
                                                    ],
                                                }}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Author: </h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="artistname"
                                                value={this.state.artistname}
                                                onChange={this.handleChange}

                                                placeholder="Author Name"
                                            />
                                        </div>
                                        <div className="form-group col-md-6 p-0 mt-3">
                                            <h5 className="cat-select" >Category <span className="requiredsymbol">*</span></h5>
                                            <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                                                <option value="">Select Category</option>
                                                {this.state.catlists.map(catlist => <option value={catlist._id}>{catlist.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Upload banner image: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                onChange={this.handleImage}
                                                required
                                                placeholder="Blog Name"
                                            />
                                        </div>
                                        {this.state.errorDocument ? <div class="alert alert-danger">
                                            File size should be 10MB or less than 10MB
                                        </div> : <div class="alert alert-info">
                                            File size should be 10MB or less than 10MB
                                        </div>}
                                        <div class="form-group">
                                            <label className="lable-title">Path <span className="requiredsymbol">*</span></label>
                                            <div className="input-box">
                                                <span class="prefix">/</span>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="alias"
                                                    value={this.state.alias}
                                                    onChange={this.handleChange}
                                                    required
                                                    placeholder="blogs/my_blogs"
                                                />
                                            </div>
                                            <div className="form-group publish-box">
                                                <input type="checkbox" defaultChecked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                                <label style={{ marginLeft: "16px" }} className="custom-control-label" htmlFor="customCheck1">Publish</label>
                                            </div>

                                        </div>
                                        {this.state.loading && <div style={{ textAlign: 'center' }} class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                        <div className="form-group">
                                            <input disabled={this.state.loading || this.state.coverimage == undefined || (this.state.blogOrder == "" || this.state.blogOrder == undefined) ? false : this.state.blogOrder < 1}
                                                type="submit"
                                                value="Save"
                                                className="btn btn-outline-primary btn-lg"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default AddBlogs;