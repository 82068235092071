import React from 'react';
import Moment from 'moment';

const LoaninterestedDetail = props => {
let totalloan = 0;
let totalcommision = 0;
  return (
          <div className="interested-main-wrapper">
            <div className='main-wrapper-head'>
              <div className='transaction'>Transaction Status</div>
              <div className='created'>Shown Interest on</div>
              <div className='transactiondate'>Approved on</div>
              <div className='loanamount'>Approved Loan Amount($)</div>
              <div className='commission'>Commission(%)</div>
              <div className='commission'>Commission Amount($)</div>
            </div>
            {props.expendedData.map((l,key) =>{
              if(l?.loanamount){
                totalloan = totalloan + l.loanamount
              }
              if(l?.loanamount){
                if(l?.commission){
                  totalcommision = totalcommision + (l?.loanamount * l?.commission / 100)
                }
                
              }
              return <div className='interested-item' key={key}>
              <div className='transaction'>{l?.transaction}</div>
              <div className='created'>{Moment(l?.created).format('MMM D, YYYY')}</div>
              <div className='transactiondate'>{l?.transactiondate ? Moment(l.transactiondate).format('MMM D, YYYY') : ''}</div>
              <div className='loanamount'>{l?.loanamount ? '$ '+l?.loanamount : ''}</div>
              <div className='commission'>{l?.commission ? l?.commission + '%' : '0%'}</div>
              <div className='commission-value'>{(l?.commission && l?.loanamount) ? '$ '+(l?.loanamount * l?.commission / 100): ''}</div>
            </div>
            })}
            <div className='main-wrapper-bottom'>
              <div className='transaction'></div>
              <div className='created'></div>
              <div className='transactiondate'>Approved Loan Amount</div>
              <div className='loanamount'>{'$ '+totalloan}</div>
              <div className='commission'>Total Commision</div>
              <div className='commission-value'>{'$ '+totalcommision}</div>
            </div>
          </div>
          
  );
};

export default LoaninterestedDetail;
