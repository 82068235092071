import React, { useState } from 'react';
import styles from './Signup.module.css';
import { Form, Input, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import API from '../../utils/API';
import $ from 'jquery';

const AdminSignup = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    setLoading(true);
    API.post('/users/register/admin', {
      email: values.email,
      firstname: values.firstname,
      lastname: values.lastname,
      username: values.email, //assign email as username
      password: values.password,
    })
      .then(() => {
        history.push('/users/admin');
        $('#add-user-modal').modal('hide');
      })
      .catch(e => {
        setLoading(false);
        $('#add-user-modal').modal('hide');
        Modal.error({
          title: 'Registration Failed',
          content: e.response.data.msg,
        });
      });
  };

  return (
          <div className={ styles.registrationformwrap }>
            <div className={ [styles.registrationform, styles.adminform].join(" ") }>
              <Form
                name="signup"
                layout="vertical"
                onFinish={onFinish}
                size = {'default'}
              >
                <Form.Item
                  label="First Name"
                  name="firstname"
                  className={styles.formitem}
                  rules={[
                    {
                      required: true,
                      message: 'Enter your First Name',
                    },
                  ]}
                >
                  <Input disabled={loading} placeholder="Enter your First Name" className={styles.formtext}/>
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastname"
                  className={styles.formitem}
                  rules={[
                    {
                      required: true,
                      message: 'Enter your Last Name',
                      min: 3,
                    },
                  ]}
                >
                  <Input disabled={loading} placeholder="Enter your Last Name" className={styles.formtext}/>
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  className={styles.formitem}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid email',
                      type: 'email',
                    },
                  ]}
                >
                  <Input disabled={loading} placeholder="Enter your Email ID" className={styles.formtext}/>
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  className={styles.formitem}
                  rules={[
                    {
                      required: true,
                      message: 'Password must be at least 8 characters long',
                      min: 8,
                    },
                  ]}
                >
                  <Input.Password disabled={loading} placeholder="Enter your Password" className={styles.formtext}/>
                </Form.Item>
                <Form.Item
                  style={{textAlign: 'center' }} 
                  className={styles.formaction}             
                >
                  <input type="submit" value="Submit" className={styles.formsubmit}></input>
                </Form.Item>
              </Form>
              
            </div>
          </div>
          
  );
};

export default AdminSignup;
