import React, { Component } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from '../../utils/API';
import { Modal, message } from 'antd'
import Sidebar from '../../components/Sidebar/Sidebar';
import './css/edu.css';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';

class UpdateEducation extends Component {
    state = {
        _id: this.props.match.params.id,
        title: "",
        body: undefined,
        author: "",
        status: false,
        alias: "",
        videos: [{ videoTitle: "", videoProgressTitle: "", videoDescription: "", videoDuration: "", videoURL: "", videoKeyTakeaway: "", videoPosition: '' }],
        catlists: [],
        levelLists: ['Principiante','Intermedio', 'Avanzado'],
        selectedCategory: "",
        keywords: "",
        visibilityoption: "",
        courseOrder: "",
        selectedLevel: ""
    };
    componentDidMount() {
        this.getCategoryList();
        this.getData();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        var daaa = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem("token")) {
            this.setState({
                author: daaa.email,
            });
        }

    };
    getData() {
        API.get('/courses/contents/node/' + this.state._id)
            .then(result => {
                //setCourseData(result.data.courses);
                //this.setState({courseData: result.data.courses});
                result.data.courses.map((course, index) => {
                    this.setState({ title: course.title });
                    this.setState({ body: course.body });
                    this.setState({ selectedCategory: (course.selectedCategory.length > 0 ? course.selectedCategory[0]._id : '') });
                    this.setState({selectedLevel: course.level});
                    this.setState({ videos: course.videos.sort((a, b) => a?.videoPosition > b?.videoPosition ? 1 : -1) });
                    this.setState({ keywords: course.keywords });
                    this.setState({ courseOrder: course?.courseOrder !== undefined ? course?.courseOrder : "" })
                    this.setState({ visibilityoption: course.visibilityoption });
                    this.setState({ alias: course.pathalias.alias.slice(1) })
                    if (course.status == 'true') {
                        this.setState({ status: true });
                    } else {
                        this.setState({ status: false });
                    }
                });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }
    getCategoryList() {
        API.get('/categories/')
            .then(result => {
                const catlists = result.data.categories;
                this.setState({ catlists });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }

    createVideoUI() {
        return this.state.videos.map((el, i) => (
            <div className={"mb-2 item-row item-row-" + i} key={i}>
                <input type="button" value="Remove" className="btn btn-outline-primary remove-btn" onClick={this.removeClick.bind(this, i)} />
                <div className="form-group col-md-2">
                    <h5 className="l-duration">Video Position: <span className="requiredsymbol">*</span></h5>
                    <input
                        className="form-control video-position"
                        required
                        pattern="[0-9]"
                        type="number"
                        name="videoPosition"
                        value={el.videoPosition || ''}
                        onChange={this.handleChangePosition.bind(this, i)}
                        placeholder="Position"
                    />
                </div>
                <div class="alert alert-info">
                    Number should be greater than 0 and not be decimal number
                </div>
                <div className="form-group">
                    <h5 className="new-title">Video Title: <span className="requiredsymbol">*</span></h5>
                    <input
                        className="form-control video-title"
                        type="text"
                        name="videoTitle"
                        value={el.videoTitle || ''}
                        onChange={this.handleChangeVideo.bind(this, i)}
                        required
                        placeholder="Video Title"
                    />
                </div>
                <div className="form-group">
                    <h5 className="new-title">Progress Bar Title: <span className="requiredsymbol">*</span></h5>
                    <input
                        className="form-control video-title"
                        type="text"
                        name="videoProgressTitle"
                        value={el.videoProgressTitle || ''}
                        onChange={this.handleChangeVideo.bind(this, i)}
                        required
                        placeholder="Progress Bar Title"
                    />
                </div>
                <div className="form-group">
                    <h5 className="new-title">Video Description: </h5>
                    <textarea
                        className="form-control video-desc"
                        type="text"
                        style={{ height: '400px' }}
                        name="videoDescription"
                        value={el.videoDescription || ''}
                        onChange={this.handleChangeVideo.bind(this, i)}

                        placeholder="Type Video description here..."
                    />
                </div>
                <div className="form-group">
                    <h5 className="l-duration">Video Duration:</h5>
                    <input
                        className="form-control video-duration"
                        type="text"
                        name="videoDuration"
                        value={el.videoDuration || ''}
                        onChange={this.handleChangeVideo.bind(this, i)}

                        placeholder="ex: 8 min"
                    />
                </div>
                <div className="form-group">
                    <h5 className="l-duration">Vimeo URL: <span className="requiredsymbol">*</span></h5>
                    <div className="input-box">
                        <span className="prefix">https://vimeo.com/</span>
                        <input
                            className="form-control video-url"
                            type="number"
                            min="1"
                            name="videoURL"
                            value={el.videoURL || ''}
                            onChange={this.handleChangeVideo.bind(this, i)}
                            required
                            placeholder="123456789"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <h5>Key Takeaways:</h5>
                    <CKEditor
                        editor={ClassicEditor}
                        onChange={this.handleVideoEditorChange.bind(this, i)}
                        data={el.videoKeyTakeaway || ''}
                        config={{
                            placeholder:
                                "Start typing Key Takeaways note here...",
                            toolbar: [
                                "|",
                                "Bold",
                                "Italic",
                                "Link",
                                "NumberedList",
                                "BulletedList",
                                "|",
                                "BlockQuote",
                                "InsertTable",
                                "Undo",
                                "Redo",
                            ],
                        }}
                    />
                </div>

            </div>
        ))
    }
    handleChangeVideo(i, e) {
        const { name, value } = e.target;
        let videos = [...this.state.videos];
        videos[i] = { ...videos[i], [name]: value };
        this.setState({ videos });
    }
    handleChangePosition(i, e) {

        // if((0< e.target.value) && parseInt(e.target.value) == e.target.value){
        this.setState({ errorMessage: false })
        const { name, value } = e.target;
        let videos = [...this.state.videos];
        videos[i] = { ...videos[i], ['videoPosition']: parseInt(Math.abs(e.target.value)) };
        this.setState({ videos });
    }
    handleVideoEditorChange = (i, e, editor) => {
        const data = editor.getData();
        let videos = [...this.state.videos];
        videos[i] = { ...videos[i], ['videoKeyTakeaway']: data };
        this.setState({ videos });
    }
    addClick() {
        this.setState(prevState => ({
            videos: [...prevState.videos, { videoTitle: "", videoProgressTitle: "", videoDescription: "", videoDuration: "", videoURL: "", videoKeyTakeaway: "", videoPosition: "" }]
        }))
    }
    removeClick(i) {
        let videos = [...this.state.videos];
        videos.splice(i, 1);
        this.setState({ videos });
    }

    handlePublish(event) {
        event.preventDefault();
        const courseData = this.state;
        API.post('/courses/update', courseData)
            .then(result => {
                $('#add-education-modal').modal('hide');
                message.success('Course Updated');
                this.props.history.push('/admin/educations');
            })
            .catch(e => {
                $('#add-education-modal').modal('hide');
                Modal.error({
                    title: 'An error occurred',
                    content: e.response.data.msg,
                });
            });
    }
    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({ body: data });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }
    render() {
        const courseType = () => {
            if (this.state.visibilityoption == 'free') {
                return <div>
                    <input type="radio" value="free" name="visibilityoption" id="free" checked required onChange={this.handleChange} />
                    <label for="free">&nbsp;Free &nbsp;</label>
                    <input type="radio" value="paid" name="visibilityoption" id="paid" onChange={this.handleChange} />
                    <label for="paid">&nbsp;Paid</label>
                </div>
            } else if (this.state.visibilityoption == 'paid') {
                return <div>
                    <input type="radio" value="free" name="visibilityoption" id="free" required onChange={this.handleChange} />
                    <label for="free">Free</label>
                    <input type="radio" value="paid" name="visibilityoption" id="paid" checked onChange={this.handleChange} />
                    <label for="paid">Paid</label>
                </div>
            } else {
                return <div>
                    <input type="radio" value="free" name="visibilityoption" id="free" required onChange={this.handleChange} />
                    <label for="free">Free</label>
                    <input type="radio" value="paid" name="visibilityoption" id="paid" onChange={this.handleChange} />
                    <label for="paid">Paid</label>
                </div>
            }
        }
        const courseCateSelect = () => {
            if (this.state.selectedCategory != '') {
                return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                    <option value="">Select Category</option>
                    {this.state.catlists.map(catlist =>
                        <option
                            value={catlist._id}
                            selected={this.state.selectedCategory == catlist._id ? true : false}
                        >
                            {catlist.name}
                        </option>
                    )
                    }
                </select>
            } else {
                return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                    <option value="">Select Category</option>
                    {this.state.catlists.map(catlist => <option value={catlist._id}>{catlist.name}</option>)}
                </select>
            }

        }
        const courseLevelSelect = () => {
            if (this.state.selectedCategory != '') {
                return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedLevel">
                    <option value="">Select Level</option>
                    {this.state.levelLists.map(item =>
                        <option
                            value={item}
                            selected={this.state.selectedLevel == item ? true : false}
                        >
                            {item}
                        </option>
                    )
                    }
                </select>
            } else {
                return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                    <option value="">Select Category</option>
                    {this.state.catlists.map(catlist => <option value={catlist._id}>{catlist.name}</option>)}
                </select>
            }

        }
        const handleChangePositionCouese = (event) => {
            const { name, value } = event.target;
            if (event.target.value != "") {
                this.setState({
                    [name]: parseInt(Math.abs(value)),
                })
            } else {
                this.setState({
                    [name]: "",
                })
            }

        }
        return (
            <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />

                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Update Course</h1>
                            <div className="new-post">
                                <form onSubmit={this.handlePublish}>
                                    <div className="form-group">
                                        <h5 className="l-duration">Course Order: </h5>
                                        <input
                                            className="form-control video-position"
                                            pattern="[0-9]"
                                            type="number"
                                            name="courseOrder"
                                            min="1"
                                            value={this.state.courseOrder}
                                            onChange={handleChangePositionCouese}
                                            placeholder="Course order"
                                        />
                                    </div>
                                    <div class="alert alert-info">
                                        1. Order should be greater than 0
                                    </div>
                                    <div className="form-group">
                                        <h5 className="new-title">Course Title: <span className="requiredsymbol">*</span></h5>
                                        <input
                                            className="form-control new-title"
                                            type="text"
                                            name="title"
                                            value={this.state.title}
                                            onChange={this.handleChange}
                                            required
                                            placeholder="Course Title"
                                        />
                                    </div>
                                    <div>
                                        <h5 className="new-body">Course Description: </h5>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            onChange={this.handleEditorChange}
                                            data={this.state.body}
                                            config={{
                                                ckfinder: {
                                                    uploadUrl: 'http://localhost:5000/api/posts/uploads'
                                                },
                                                placeholder:
                                                    "Start typing course detail here...",
                                                toolbar: [
                                                    "Heading",
                                                    "|",
                                                    "Bold",
                                                    "Italic",
                                                    "Link",
                                                    "NumberedList",
                                                    "BulletedList",
                                                    "|",
                                                    "BlockQuote",
                                                    "InsertTable",
                                                    "Undo",
                                                    "Redo",
                                                ],
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6 p-0 mt-3">
                                        <h5 className="cat-select" >Category <span className="requiredsymbol">*</span></h5>
                                        {courseCateSelect()}
                                    </div>
                                    <div className="form-group col-md-6 p-0 mt-3">
                                        <h5 className="cat-select" >Level <span className="requiredsymbol">*</span></h5>
                                        {courseLevelSelect()}
                                    </div>
                                    <div className="form-group">
                                        <h5>Tags/Keywords</h5>
                                        <input
                                            type="text"
                                            name="keywords"
                                            className="form-control"
                                            value={this.state.keywords}
                                            onChange={this.handleChange}
                                            placeholder="Comma seperated keywords"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <h4 className="intro-video">Type of Course <span className="requiredsymbol">*</span></h4>
                                        {courseType()}
                                    </div>
                                    <fieldset className="form-group video-input-box">
                                        <h4 className="intro-video">Course Videos</h4>
                                        {this.createVideoUI()}
                                        <input type='button' value='Add More' className="btn btn-outline-primary float-right mt-2 mb-2" onClick={this.addClick.bind(this)} />
                                    </fieldset>

                                    <div className="form-group publish-box">
                                        <input type="checkbox" checked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                        <label className="custom-control-label" for="customCheck1">Publish</label>
                                    </div>
                                    <div class="form-group">
                                        <label className="lable-title">Path <span className="requiredsymbol">*</span></label>
                                        <div className="input-box">
                                            <span class="prefix">/</span>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="alias"
                                                value={this.state.alias}
                                                onChange={this.handleChange}
                                                placeholder="education/my_course"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="submit"
                                            disabled={(this.state.courseOrder == "" || this.state.courseOrder == undefined) ? false : this.state.courseOrder < 1}
                                            value="Update"
                                            className="btn btn-outline-primary btn-lg"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(UpdateEducation);