import React, { useContext, useState} from 'react';
import {Modal, Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../App';
import Page from '../../components/Page/Page';
import Login from '../../components/Login/Login';


const Home = (props) => {
  const { state } = useContext(AuthContext);
  if(state.isAuthenticated){
    return <Redirect to="/dashboard"/>;
  }
  return (
    <Page>
     <Login />
    </Page>
  );
};

export default Home;
