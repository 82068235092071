import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import Sidebar from '../../components/Sidebar/Sidebar';
import { Modal } from 'antd';
import API from '../../utils/API';
import AddFaqCategory from './AddFaqCategory';


class FaqCategory extends Component{

    state = {
      catlists: []
    }


  componentDidMount() {
    this.getData()
  };

  getData() {
    API.get('/faqs/category/')
      .then(result => {
        /* const catlists= result.data.category;
        this.setState({ catlists }); */
        return;
      })
      .catch(e => {
        Modal.error({
          title: 'An error occurred',
          content: e.message,
        });
      });
  }

  render(){
    return (
    <div>
        <div className="d-flex" id="wrapper">
            <Sidebar/>
            <AddFaqCategory />
            <div id="page-content-wrapper">
                <div className="container-fluid">
                    <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                    <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-faq-category-modal">Add FAQ Category</button>
                    <h1 className="mt-2 text-primary">FAQ Category</h1>
                    <div className="row px-2">
                    <ul>
                      { this.state.catlists.map(catlist => <li className={catlist.name}>{catlist.name}</li>)}
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
  }
};

export default FaqCategory;