import React, { useContext } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import { Modal } from 'antd';
import { AuthContext } from '../../App';
import {useHistory} from 'react-router-dom';

const Sidebar = () => {

    const { dispatch } = useContext(AuthContext);
    const history = useHistory();
    const onLogoutClick = () => {
        Modal.confirm({
          title: 'Logout?',
          onOk: () => {
            dispatch({
              type: 'LOGOUT',
            });
            history.push('/');
          },
        });
      };

    //const { user } = this.props.auth;
    return (
        <div className="border-right h-100" id="sidebar-wrapper">
            <div className="list-group list-group-flush">
                <Link to="/dashboard" className="list-group-item list-group-item-action">Dashboard</Link>
                <Link to="/users" className="list-group-item list-group-item-action">Users</Link>
                <Link to="/users/report" className="list-group-item list-group-item-action">Users Report</Link>
                <Link to="/admin/manage/lender" className="list-group-item list-group-item-action">Lenders</Link>               
                <Link to="/admin/educations" className="list-group-item list-group-item-action">Education/Videos</Link>
                <Link to="/admin/Podcasts" className="list-group-item list-group-item-action">Podcasts</Link>
                <Link to="/admin/Blogs" className="list-group-item list-group-item-action">Blogs</Link>
                <Link to="/admin/Books" className="list-group-item list-group-item-action">Books</Link>
                <Link to="/admin/Sponsors" className="list-group-item list-group-item-action">Sponsors</Link>
                <Link to="/admin/resources" className="list-group-item list-group-item-action">Resources</Link>
                <Link to="/admin/tipoftheweek" className="list-group-item list-group-item-action">Tip Of The Week</Link>
                <Link to="/admin/webinar" className="list-group-item list-group-item-action">Webinar</Link>
                <Link to="/admin/around-corner" className="list-group-item list-group-item-action">Around the Corner</Link>
                <Link to="/admin/forums" className="list-group-item list-group-item-action">Forums</Link>
                <Link to="/admin/forumcategory" className="list-group-item list-group-item-action">Category</Link>
                <Link to="/admin/taggroup" className="list-group-item list-group-item-action">Tag Group List</Link>
                {/* added by lav */}
                <Link to="/admin/interests" className="list-group-item list-group-item-action">Interests</Link>
                <Link to="/admin/roles" className="list-group-item list-group-item-action">Roles</Link>
                <Link to="/admin/pages" className="list-group-item list-group-item-action">Basic Pages</Link>
                <Link to="/admin/faqs" className="list-group-item list-group-item-action">FAQ's</Link>
                <Link to="/admin/faqcategory" className="list-group-item list-group-item-action">FAQ's Category</Link>
                
                <Link to="/admin/contact/result" className="list-group-item list-group-item-action">Contact Us</Link>
                <button className="list-group-item list-group-item-action" onClick={onLogoutClick}>Logout <FontAwesomeIcon icon={faSignOutAlt} /></button>
            </div>
        </div>
    );
    
}
export default Sidebar;