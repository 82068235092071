import React, { Component, Fragment } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { Link } from "react-router-dom";
import { Popconfirm, message, Modal } from "antd"
import { faUser } from "@fortawesome/free-solid-svg-icons";
import UserViewModal from './UserViewModal';
import API from '../../utils/API';
import Moment from 'moment';

/* import UserUpdateModal from "../partials/UserUpdateModal"; */
import { toast, ToastContainer } from "react-toastify";

class UsersReport extends Component {

    columns = [
        {
            key: "key",
            text: "S.no",
            className: "id",
            align: "left",
            sortable: true,
        },
        {
            key: "fullname",
            text: "Full Name",
            className: "fullname capitalize",
            align: "left",
            sortable: true,
        },
        {
            key: "email",
            text: "Email",
            className: "email",
            align: "left",
            sortable: true
        },
        {
            key: "phonenumber",
            text: "Phone Number",
            className: "phonenumber",
            align: "left",
            sortable: true
        },
        {
            key: "subscription_status",
            text: "Subscription Status",
            className: "sub",
            align: "left",
            sortable: true
        },
        {
            key: "subscriptiontype",
            text: "Subscription Type",
            className: "sub",
            align: "left",
            sortable: true
        },
        /* {
            key: "coupon",
            text: "coupon",
            className: "coupon",
            align: "left",
            sortable: true
        }, */
        {
            key: "subscribedOn",
            text: "Subscription Date",
            className: "date",
            align: "left",
            sortable: true
        },
        {
            key: "platform",
            text: "Registered From",
            className: "platform",
            align: "left",
            sortable: true
        },
        {
            key: "registerDate",
            text: "Registered Date",
            className: "date",
            align: "left",
            sortable: true
        },
    ];

    config = {
        key_column: 'key',
        page_size: 10,
        length_menu: [10, 20, 50, 100],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    state = {
        records: [],
        visible: false,
        currentRecord: {
            _id: '',
            fullname: '',
            email: '',
            registerDate: '',
        }

    };
    componentDidMount() {
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {

        API.get("/users/user-data/report")
            .then(res => {
                
                var usersdata = [];
                Moment.locale('en');
                res.data.data.map((u, index) => {
                    console.log('u.createdAt: ',u.createdAt)
                    let intensivetrainingvalue = u?.intensivetraining == true ? ', Entrenamiento': '';
                    let subscriptiontypevalue = u?.subscription_type ? u.subscription_type+intensivetrainingvalue : u?.intensivetraining == true ? 'Entrenamiento': 'Free';
                    usersdata[index] = {
                        key: index + 1,
                        _id: u._id,
                        fullname: u.firstname + ' ' + u.lastname,
                        email: u.email,
                        phonenumber: u?.userinfoid?.phonenumber ? u?.userinfoid?.phonenumber : '',
                        subscriptiontype: subscriptiontypevalue,
                        //coupon: u?.coupon,
                        platform: u?.subscribedfrom ? u?.subscribedfrom : 'Web',
                        registerDate: Moment(u.registerDate).format('MMM D, YYYY'),
                        subscription_status : u?.subscription_status ? u.subscription_status : 'No Subscription',
                        subscribedOn: u?.createdAt ? Moment(u.createdAt).format('MMM D, YYYY'):'',
                    }
                });
                this.setState({ records: usersdata })
            })
            .catch()
    }


    pageChange(pageData) {

    }


    render() {
        return (
            <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Users Report</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}


export default UsersReport;
