import React, { Component, Fragment } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import UserAddModal from '../Users/UserAddModal';
import { toast, ToastContainer } from "react-toastify";
import API from "../../utils/API";

class AdminUsers extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "Id",
                className: "id",
                align: "left",
                sortable: true,
            },
            {
                key: "firstname",
                text: "First Name",
                className: "firstname capitalize",
                align: "left",
                sortable: true,
            },
            {
                key: "lastname",
                text: "Last Name",
                className: "lastname capitalize",
                align: "left",
                sortable: true,
            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: true
            },
            {
                key: "registerDate",
                text: "Registered Date",
                className: "date",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-user-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.viewRecord(record)}
                                style={{ marginRight: '5px' }}>
                                View Profile
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            key_column: '_id',
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            filename: "Users",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                _id: '',
                firstname: '',
                lastname: '',
                email: '',
                registerDate: '',
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        API.get("/users/user-data/admin")
            .then(res => {
                this.setState({ records: res.data })
            })
            .catch()
    }

    viewRecord(record) {
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        axios
            .post("/api/user-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
    }

    render() {
        return (
            <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <UserAddModal />
                    {/* <UserAddModal/>
                    <UserUpdateModal record={this.state.currentRecord}/> */}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <Link to="/users">
                                <button className="btn btn-outline-primary float-right mt-3 mr-2"><FontAwesomeIcon icon={faUser} /> View Website Users</button>
                            </Link>
                            <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus} /> Add Admin Users</button>
                            <h1 className="mt-2 text-primary">Admin Users List</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}


export default AdminUsers;
