import React, { Component } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from '../../utils/API';
import {Modal} from 'antd';
import $ from 'jquery';

class AddPage extends Component {
    state = {
        title: "",
        body: "",
        author: "",
        date: new Date(),
        status: true,
        alias: "",
    };

 
    // Set author name from logged in user's profile
    componentDidMount() {
        this.handleChange = this.handleChange.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        var daaa= JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem("token")) {
            this.setState({
                author: daaa.email,
                isLoggedIn: true,
            });
        }
    }
    handlePublish(event) {
        event.preventDefault();
        this.setState({ date: new Date() });
        const pageData = this.state;
         API.post('/pages/add', pageData)
         .then(result => {
             $('#add-page-modal').modal('hide');
             window.location.reload();
         })
         .catch(e => {
            $('#add-page-modal').modal('hide');
            Modal.error({
              title: 'An error occurred',
              content: e.response.data.msg,
            });
          });

    }

    handleEditorChange = (event, editor) =>{
        const data = editor.getData();
        this.setState({ body: data });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }
    handleChangechk(event) {
        this.setState({status: !this.state.status});
      }
    render() {
      return (

        <div>
        <div className="modal fade" id="add-page-modal" data-reset="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Create New Page</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="new-post">
            <form onSubmit={this.handlePublish} >
                <div className="form-group">
                    <label className="new-title">Title: <span className="requiredsymbol">*</span></label>
                    <input
                        className="form-control new-title"
                        type="text"
                        name="title"
                        value={this.state.title}
                        onChange={this.handleChange}
                        required
                        placeholder="The Best Title"
                    />
                </div>
                <div>
                <CKEditor
                    editor={ClassicEditor}
                    onChange={this.handleEditorChange}
                    config={{
                        placeholder:
                            "Start typing your page detail here...",
                        toolbar: [
                            "Heading",
                            "|",
                            "Bold",
                            "Italic",
                            "Link",
                            "NumberedList",
                            "BulletedList",
                            "|",
                            "BlockQuote",
                            "MediaEmbed",
                            "ImageUpload",
                            "InsertTable",
                            "Undo",
                            "Redo",
                        ],
                    }}
            />
                </div>
                <div class="form-group">
                    <label className="lable-title">Path</label>
                    <div className="input-box">
                        <span class="prefix">/</span>
                        <input
                            className="form-control"
                            type="text"
                            name="alias"
                            value={this.state.alias}
                            onChange={this.handleChange}
                            required
                            placeholder="about"
                        />
                    </div>
                </div>
                <div className="form-group publish-box">
                    <input type="checkbox" defaultChecked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1"/>
                    <label className="custom-control-label" htmlFor="customCheck1">Publish</label>
                </div>
                <br />
                <div className="form-group">
                    <input
                        type="submit"
                        value="Save"
                        className="btn btn-outline-primary btn-lg"
                    />
                </div>
            </form>
        </div>
    </div>
                </div>
            </div>
        </div>
    </div>

        

      );
    }
}

export default AddPage;