import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import Sidebar from '../../components/Sidebar/Sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import DeleteBasicpage from './DeleteBasicpage';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import AddPage from './AddPage';
import API from '../../utils/API';
import Moment from 'moment';

class BasicPages extends Component {

  state = {
    datalists: [],
    currentRecord: {
      _id: '',
      title: '',
      status: '',
      createdAt: '',
    },
  }
  columns = [
    {
      key: "_id",
      text: "Id",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "title",
      text: "Course Title",
      className: "capitalize",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "capitalize",
      align: "left",
      sortable: true,
    },
    {
      key: "createdAt",
      text: "Created Date",
      className: "date",
      align: "left",
      sortable: true
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: datalist => {
        return (
          <Fragment>
            <Link
              to={'/admin/pages/' + datalist._id + '/edit'}
              style={{ marginRight: '5px' }}
              className="btn btn-primary btn-sm">
              <i className="fa fa-edit"></i>
            </Link>
            <button
              data-toggle="modal"
              data-target="#delete-basicpage-modal"
              className="btn btn-danger btn-sm"
              onClick={() => this.deleteRecord(datalist)}>
              <i className="fa fa-trash"></i>
            </button>
          </Fragment>
        );
      }
    }
  ];
  config = {
    key_column: '_id',
    page_size: 10,
    length_menu: [10, 20, 50, 100],
    filename: "Basic Pages",
    no_data_text: 'No Page found!',

    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last"
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  componentDidMount() {
    this.getData()
  };

  getData() {
    API.get('/pages/contents')
      .then(result => {
        const basiclists = result.data.basicpages;

        var basicdata = [];
        basiclists.map((basic, index) => {
          basicdata[index] = {
            _id: basic._id,
            title: basic.title,
            status: (basic.status == 'true' ? 'Published' : 'Unpublished'),
            createdAt: Moment(basic.createdAt).format('MMM D, YYYY')
          }
        });
        this.setState({ datalists: basicdata })
      })
      .catch(e => {
        Modal.error({
          title: 'An error occurred',
          content: e.message,
        });
      });
  }

  deleteRecord(datalist) {
    this.setState({ currentRecord: datalist });
  }

  render() {
    return (
      <div>
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <AddPage />
          <DeleteBasicpage record={this.state.currentRecord} />

          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
              <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-page-modal">Add New Page</button>
              <h1 className="mt-2 text-primary">Basic Pages</h1>
              <ReactDatatable
                records={this.state.datalists}
                columns={this.columns}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

};

export default BasicPages;
