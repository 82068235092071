import React from 'react'
import classnames from "classnames";
import { toast } from 'react-toastify';
import Signup from '../../components/Signup/Signup';

import 'react-toastify/dist/ReactToastify.css';
import AdminSignup from '../../components/Signup/AdminSignup';

class UserAddModal extends React.Component {

    

    render() {
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add User</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <AdminSignup />
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add User
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserAddModal;
