import React, { Component } from 'react';
import API from '../../../utils/API';
import { Modal } from 'antd'
import $ from 'jquery';

class AddBooks extends Component {


    state = {
        bookname: "",
        description: "",
        author: "",
        artistname: "",
        status: false,
        alias: "",
        book: undefined,
        coverimage: undefined,
        catlists: [],
        selectedCategory: "",
        loading: false,
        errorImage: false,
        errorDocument: false,
        bookBase64: undefined,
        visible: false,
        coverImageBase64: undefined,
        visibleImage: false,
        bookUploadType: "pdf",
        bookurl: undefined,
        bookOrder: ""

    };
    componentDidMount() {
        this.getCategoryList();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        var daaa = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem("token")) {
            this.setState({
                author: daaa.email,
            });
        }

    };

    getCategoryList() {
        API.get('/categories/')
            .then(result => {
                const catlists = result.data.categories;
                this.setState({ catlists });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }

    handleTypeChange(event) {
        this.setState({ bookUploadType: "pdf" })
    }

    handlePublish(event) {

        this.setState({ loading: true })
        event.preventDefault();
        const courseData = this.state;
        API.post('/books/add', courseData)
            .then(result => {
                this.setState({ loading: false })
                $('#add-book-modal').modal('hide');
                window.location.reload();
            })
            .catch(e => {
                this.setState({ loading: false })
                $('#add-book-modal').modal('hide');
                Modal.error({
                    title: 'An error occurred',
                    content: e.response.data.msg,
                });
            });
    }
    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({ description: data });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }
    getImageBase64 = async (file) => {
        return await new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                if (event.target.result) {
                    resolve(event.target.result);
                } else {
                    reject('Please try again.');
                }
            };
        });
    }
    handleChangePosition = (event) => {
        const { name, value } = event.target;
        if (event.target.value != "") {
            this.setState({
                [name]: parseInt(Math.abs(value)),
            })
        } else {
            this.setState({
                [name]: "",
            })
        }

    }

    handleDocument = (event) => {
        this.setState({ errorDocument: false })
        if (event.target.files[0].size <= 10485760) {
    
            this.getImageBase64(event.target.files[0]).then(res => {


                this.setState({ book: res })
            }).catch(error => {
                console.error(error);
            })
        } else {
            this.setState({ book: undefined })
            this.setState({ errorDocument: true })
        }
    }
    handleImage = (event) => {
        this.setState({ errorImage: false })
        if (event.target.files[0].size <= 10485760) {

            this.getImageBase64(event.target.files[0]).then(res => {

                this.setState({ coverImageBase64: res })
                this.setState({ coverimage: res })

            }).catch(error => {
                console.error(error);
            })
        } else {
            this.setState({ coverimage: undefined })
            this.setState({ errorImage: true })
        }
    }
    handleChangechk(event) {
        this.setState({ status: !this.state.status });
    }

    render() {
        return (

            <div>
                <div className="modal fade" id="add-book-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add New Book</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="new-post">
                                    <form onSubmit={this.handlePublish}>
                                        <div className="form-group">
                                            <h5 className="l-duration">Book Order: </h5>
                                            <input
                                                className="form-control video-position"
                                                pattern="[0-9]"
                                                type="number"
                                                min="1"
                                                value={this.state.bookOrder}
                                                name="bookOrder"
                                                onChange={this.handleChangePosition}
                                                placeholder="Book order"
                                            />
                                        </div>
                                        <div class="alert alert-info">
                                            1. Order should be greater than 0
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Book Name: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="bookname"
                                                value={this.state.bookname}
                                                onChange={this.handleChange}
                                                required
                                                placeholder="Book Name"
                                            />
                                        </div>

                                        <div>
                                            <h5 className="new-body">Book Description: </h5>
                                            <textarea
                                                style={{ height: '400px' }}

                                                onChange={this.handleChange}
                                                value={this.state.description}
                                                name="description"
                                                className="form-control new-title"
                                                placeholder=
                                                "Start typing book detail here..."


                                            />
                                        </div>
                                        <div className="form-group">
                                            <h5 className="new-title">Author: </h5>
                                            <input
                                                className="form-control new-title"
                                                type="text"
                                                name="artistname"
                                                value={this.state.artistname}
                                                onChange={this.handleChange}

                                                placeholder="Author Name"
                                            />
                                        </div>
                                        <div className="form-group col-md-6 p-0 mt-3">
                                            <h5 className="cat-select" >Category <span className="requiredsymbol">*</span></h5>
                                            <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                                                <option value="">Select Category</option>
                                                {this.state.catlists.map(catlist => <option value={catlist._id}>{catlist.name}</option>)}
                                            </select>
                                        </div>
                                        {/* <div>
                <h5 className="new-title">Upload book type: <span className="requiredsymbol">*</span></h5>
                        <input defaultChecked  type="radio" value="pdf" name="bookUploadType" id="pdf" required onChange={this.handleChange}/>
                        <label for="pdf">&nbsp;PDF &nbsp;</label>
                        <input  type="radio" value="url" name="bookUploadType" id="url" onChange={this.handleChange}/>
                        <label for="url">&nbsp;URL</label>
                    </div> */}
                                        {/* {this.state.bookUploadType == 'pdf' ? */}
                                        {/* <div> */}
                                        {/* <div className="form-group">
                    <h5 className="new-title">Upload book: <span className="requiredsymbol">*</span></h5>
                    <input
                        className="form-control new-title"
                        type="file"
                        accept="application/pdf"
                        onChange={this.handleDocument}
                        required = {this.state.book == undefined}
                        placeholder="Book Name"
                    />
                </div> */}
                                        {/* {this.state.errorDocument ? <div class="alert alert-danger">
    File size should be 10MB or less than 10MB
  </div>:<div class="alert alert-info">
    File size should be 10MB or less than 10MB
  </div>}</div>:  */}
                                        <div className="form-group">
                                            <h5 className="new-title">Book URL: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                required
                                                type="url"
                                                name="bookurl"
                                                value={this.state.bookurl}
                                                onChange={this.handleChange}

                                                placeholder="Book url (ex. https://example..)"
                                            />
                                        </div>
                                        {/* } */}

                                        <div className="form-group">
                                            <h5 className="new-title">Upload banner image: <span className="requiredsymbol">*</span></h5>
                                            <input
                                                className="form-control new-title"
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                onChange={this.handleImage}
                                                required
                                                placeholder="Book Name"
                                            />
                                        </div>
                                        {this.state.errorDocument ? <div class="alert alert-danger">
                                            File size should be 10MB or less than 10MB
                                        </div> : <div class="alert alert-info">
                                            File size should be 10MB or less than 10MB
                                        </div>}

                                        <div class="form-group">
                                            <label className="lable-title">Path <span className="requiredsymbol">*</span></label>
                                            <div className="input-box">
                                                <span class="prefix">/</span>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="alias"
                                                    value={this.state.alias}
                                                    onChange={this.handleChange}
                                                    required
                                                    placeholder="books/my_books"
                                                />
                                            </div>
                                            <div className="form-group publish-box">
                                                <input type="checkbox" defaultChecked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                                <label style={{ marginLeft: "16px" }} className="custom-control-label" htmlFor="customCheck1">Publish</label>
                                            </div>

                                        </div>
                                        {this.state.loading && <div style={{ textAlign: 'center' }} class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                        <div className="form-group">
                                            <input disabled={this.state.loading || this.state.coverimage == undefined || (this.state.bookOrder == "" || this.state.bookOrder == undefined) ? false : this.state.bookOrder < 1}
                                                type="submit"
                                                value="Save"
                                                className="btn btn-outline-primary btn-lg"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default AddBooks;