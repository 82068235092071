import React, { Component } from 'react';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import API from '../../utils/API';
import { Modal, message } from 'antd'
import Sidebar from '../../components/Sidebar/Sidebar';
import './css/edu.css';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';

class UpdateResource extends Component {
    state = {
        _id: this.props.match.params.id,
        author: "",
        status: false,
        checkDoc: true,
        checkCal: true,
        checkOther: true,
        documents: [{ documentTitle: "", documentVideoTitle: "", dvideoURL: "", dposition: "", dpdf:"", dlink: "" }],
        importantlist: [{ otherPosition: "", otherTitle: "",othervideoTitle: "", othervideolink: "", otherlink: "", otherpdf: ""}],
        calculations:[{cPosition: "", cTitle: "",cvideoTitle: "", cvideoURL: "", clink: "", calcpdf: ""}],
        catlists: [],
        selectedCategory: "",
        errorMessage: false,
        resourceOrder: undefined,
        errorDocument: false,
        errorDocumentCalculator: false,
        errorDocumentOther: false,
        wait:false
    };
    componentDidMount() {
        this.getCategoryList();
        this. getData();
        this.handleChange = this.handleChange.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleChangechk = this.handleChangechk.bind(this);
        this.handleChangeDoc = this.handleChangeDoc.bind(this);
        this.handleChangeCal = this.handleChangeCal.bind(this);
        this.handleChangeOther = this.handleChangeOther.bind(this);
        var daaa = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem("token")) {
            this.setState({
                author: daaa.email,
            });
        }

    };

    getData() {
        API.get('/courses/contents/node/resource/' + this.state._id)
            .then(result => {
                //setCourseData(result.data.courses);
                //this.setState({courseData: result.data.courses});
                result.data.resources.map((resource, index) => {
                    this.setState({ selectedCategory: (resource.selectedCategory.length > 0 ? resource.selectedCategory[0]._id : '') });
                    this.setState({ documents: resource.documents.sort((a, b) => a?.dposition > b?.dposition ? 1 : -1) });
                    this.setState({ calculations: resource.calculations.sort((a, b) => a?.cPosition > b?.cPosition ? 1 : -1) });
                    this.setState({ importantlist: resource.importantlist.sort((a, b) => a?.otherPosition > b?.otherPosition ? 1 : -1) });
                    this.setState({ resourceOrder: resource?.resourceOrder !== undefined ? resource?.resourceOrder : "" })
                    if (resource.status == 'true') {
                        this.setState({ status: true });
                    } else {
                        this.setState({ status: false });
                    }
                    if (resource.checkDoc == 'true') {
                        this.setState({ checkDoc: true });
                    } else {
                        this.setState({ checkDoc: false });
                    }
                    if (resource.checkCal == 'true') {
                        this.setState({ checkCal: true });
                    } else {
                        this.setState({ checkCal: false });
                    }
                    if (resource.checkOther == 'true') {
                        this.setState({ checkOther: true });
                    } else {
                        this.setState({ checkOther: false });
                    }
                });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }
    getCategoryList() {
        API.get('/categories/')
            .then(result => {
                const catlists = result.data.categories;
                this.setState({ catlists });
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }
//For Document
createDocumentsListUI() {
    return this.state.documents.map((el, i) => (
        <div className={"mb-2 item-row item-row-" + i} key={i}>
            {i ?
                (<input type="button" value="Remove" className="btn btn-outline-primary remove-btn" onClick={this.removeClick.bind(this, i)} />)
                : ("")
            }
            <div className="form-group col-md-5">
                <h5 className="l-duration">Position: {this.state.checkDoc && <span className="requiredsymbol">*</span>}</h5>
                <input
                    className="form-control video-position"
                    required={this.state.checkDoc}
                    pattern="[0-9]"
                    type="number"
                    name="dposition"
                    value={el.dposition || ''}
                    onChange={this.handleChangePosition.bind(this, i)}
                    placeholder="Position"
                />
            </div>
            <div class="alert alert-info">
                Number should be greater than 0 and not be decimal number
            </div>

            <div className="form-group">
                <h5 className="new-title">Document Title: {this.state.checkDoc && <span className="requiredsymbol">*</span>}</h5>
                <input
                    className="form-control video-title"
                    type="text"
                    name="documentTitle"
                    value={el.documentTitle || ''}
                    onChange={this.handleDocument.bind(this, i)}
                    required={this.state.checkDoc}
                    placeholder="Title"
                />
            </div>
            <div className="form-group">
                    <h5 className="new-title">Document Link</h5>
                    <input
                        className='form-control '
                        type="url"
                        value={el.dlink || ''}
                        onChange={this.handleDocument.bind(this, i)}
                        name="dlink"
                        placeholder="Document Link"/>
            </div>
            <div className="form-group">
                <h5 className="new-title">Video Title: {/* {this.state.checkDoc && <span className="requiredsymbol">*</span>} */}</h5>
                <input
                    className="form-control video-title"
                    type="text"
                    name="documentVideoTitle"
                    value={el.documentVideoTitle || ''}
                    onChange={this.handleDocument.bind(this, i)}
                    /* required={this.state.checkDoc} */
                    placeholder="Video Title"
                />
            </div>
            <div className="form-group">
                <h5 className="l-duration">Document Vimeo URL: {/* {this.state.checkDoc && <span className="requiredsymbol">*</span>} */}</h5>
                <div className="input-box">
                    <span class="prefix">https://vimeo.com/</span>
                    <input
                        className="form-control video-url"
                        type="number"
                        min="1"
                        name="dvideoURL"
                        value={el.dvideoURL || ''}
                        onChange={this.handleDocument.bind(this, i)}
                        /* required={this.state.checkDoc} */
                        placeholder="123456789"
                    />
                </div>
            </div>
            <div className="form-group">
                <h5 className="new-title">Upload Document: {this.state.checkDoc && <span className="requiredsymbol">*</span>}</h5>
                <div className='update-pdf-box'>
                    {el?.dpdf?.location
                    ? <div><a href={el?.dpdf?.location} target="_blank">View Already Uploaded Document</a></div> 
                    : null}
                    <input
                        className="form-control new-title"
                        type="file"
                        accept=".pdf,.docx, .doc, .xlsx, .xls"
                        name="dpdf"
                        onChange={this.handleDocumentpdf.bind(this, i)}
                        placeholder="PDF"
                        
                    />
                </div>
                
            </div>
            {this.state.errorDocument ? 
            <div class="alert alert-danger">File size should be 10MB or less than 10MB</div>
            :<div class="alert alert-info">File size should be 10MB or less than 10MB</div>
            } 
            
        </div>
    ))
}
//For Calculation
createCalculationListUI() {
    return this.state.calculations.map((el, i) => (
        <div className={"mb-2 item-row item-row-" + i} key={i}>
            {i ?
                (<input type="button" value="Remove" className="btn btn-outline-primary remove-btn" onClick={this.removeCalculationClick.bind(this, i)} />)
                : ("")
            }
            <div className="form-group col-md-5">
                <h5 className="l-duration">Position: {this.state.checkCal && <span className="requiredsymbol">*</span>}</h5>
                <input
                    className="form-control video-position"
                    id="documentPosition"
                    required={this.state.checkCal}
                    pattern="[0-9]"
                    type="number"
                    name="cPosition"
                    value={el.cPosition || ''}
                    onChange={this.handleChangePosition.bind(this, i)}
                    placeholder="Position"
                />
            </div>
            <div class="alert alert-info">
                Number should be greater than 0 and not be decimal number
            </div>

            <div className="form-group">
                <h5 className="new-title">Calculation Title: {this.state.checkCal && <span className="requiredsymbol">*</span>}</h5>
                <input
                    className="form-control video-title"
                    type="text"
                    name="cTitle"
                    value={el.cTitle || ''}
                    onChange={this.handleCalculation.bind(this, i)}
                    required={this.state.checkCal}
                    placeholder="Title"
                />
            </div>
            <div className="form-group">
                <h5 className="new-title">Calculator Link {this.state.checkCal && <span className="requiredsymbol">*</span>}</h5>
                <input
                    className='form-control '
                    type="url"
                    value={el.clink || ''}
                    onChange={this.handleCalculation.bind(this, i)}
                    required={this.state.checkCal}
                    name="clink"
                    placeholder="Calculator Link"/>
            </div>
            <div className="form-group">
                <h5 className="new-title">Video Title: {/* {this.state.checkCal && <span className="requiredsymbol">*</span>} */}</h5>
                <input
                    className="form-control video-title"
                    type="text"
                    name="cvideoTitle"
                    value={el.cvideoTitle || ''}
                    onChange={this.handleCalculation.bind(this, i)}
                    /* required={this.state.checkCal} */
                    placeholder="Video Title"
                />
            </div>
            <div className="form-group">
                <h5 className="l-duration">Calculator Vimeo URL: {/* {this.state.checkCal && <span className="requiredsymbol">*</span>} */}</h5>
                <div className="input-box">
                    <span class="prefix">https://vimeo.com/</span>
                    <input
                        className="form-control video-url"
                        type="number"
                        min="1"
                        name="cvideoURL"
                        value={el.cvideoURL || ''}
                        onChange={this.handleCalculation.bind(this, i)}
                        /* required={this.state.checkCal} */
                        placeholder="123456789"
                    />
                </div>
            </div>
            <div className="form-group">
                <h5 className="new-title">Upload Document:</h5>
                <div className='update-pdf-box'>
                    {el?.calcpdf?.location
                    ? <div><a href={el?.calcpdf?.location} target="_blank">View Already Uploaded Document</a></div> 
                    : null}
                    <input
                        className="form-control new-title"
                        type="file"
                        accept=".pdf,.docx, .doc, .xlsx, .xls"
                        name="calcpdf"
                        onChange={this.handleCalculatorpdf.bind(this, i)}
                        placeholder="PDF"
                        
                    />
                </div>
            </div>
            {this.state.errorDocumentCalculator ? 
            <div class="alert alert-danger">File size should be 10MB or less than 10MB</div>
            :<div class="alert alert-info">File size should be 10MB or less than 10MB</div>
            } 
            
        </div>
    ))
}
//For Calculation
createImportantListUI() {
    return this.state.importantlist.map((el, i) => (
        <div className={"mb-2 item-row item-row-" + i} key={i}>
            {i ?
                (<input type="button" value="Remove" className="btn btn-outline-primary remove-btn" onClick={this.removeImportantsClick.bind(this, i)} />)
                : ("")
            }
            <div className="form-group col-md-5">
                <h5 className="l-duration">Position: {this.state.checkOther && <span className="requiredsymbol">*</span>}</h5>
                <input
                    className="form-control video-position"
                    id="documentPosition"
                    required={this.state.checkOther}
                    pattern="[0-9]"
                    type="number"
                    name="otherPosition"
                    value={el.otherPosition || ''}
                    onChange={this.handleChangePosition.bind(this, i)}
                    placeholder="Position"
                />
            </div>
            <div class="alert alert-info">
                Number should be greater than 0 and not be decimal number
            </div>

            <div className="form-group">
                <h5 className="new-title">Title: {this.state.checkOther && <span className="requiredsymbol">*</span>}</h5>
                <input
                    className="form-control video-title"
                    type="text"
                    name="otherTitle"
                    value={el.otherTitle || ''}
                    onChange={this.handleOther.bind(this, i)}
                    required={this.state.checkOther}
                    placeholder="Title"
                />
            </div>
            <div className="form-group">
                <h5 className="new-title">Other Link {this.state.checkOther && <span className="requiredsymbol">*</span>}</h5>
                <input
                    className='form-control '
                    type="url"
                    value={el.otherlink || ''}
                    onChange={this.handleOther.bind(this, i)}
                    required={this.state.checkOther}
                    name="otherlink"
                    placeholder="Link"/>
            </div>
            <div className="form-group">
                <h5 className="new-title">Video Title: {/* {this.state.checkOther && <span className="requiredsymbol">*</span>} */}</h5>
                <input
                    className="form-control video-title"
                    type="text"
                    name="othervideoTitle"
                    value={el.othervideoTitle || ''}
                    onChange={this.handleOther.bind(this, i)}
                    /* required={this.state.checkOther} */
                    placeholder="Video Title"
                />
            </div>
            <div className="form-group">
                <h5 className="l-duration">Other Vimeo URL: {/* {this.state.checkOther && <span className="requiredsymbol">*</span>} */}</h5>
                <div className="input-box">
                    <span class="prefix">https://vimeo.com/</span>
                    <input
                        className="form-control video-url"
                        type="number"
                        min="1"
                        name="othervideolink"
                        value={el.othervideolink || ''}
                        onChange={this.handleOther.bind(this, i)}
                        /* required={this.state.checkOther} */
                        placeholder="123456789"
                    />
                </div>
            </div>
            <div className="form-group">
                <h5 className="new-title">Upload Document: {/* {this.state.checkOther && <span className="requiredsymbol">*</span>} */}</h5>
                <div className='update-pdf-box'>
                    {el?.otherpdf?.location
                    ? <div><a href={el?.otherpdf?.location} target="_blank">View Already Uploaded Document</a></div> 
                    : null}
                    <input
                        className="form-control new-title"
                        type="file"
                        accept=".pdf,.docx, .doc, .xlsx, .xls"
                        name="otherpdf"
                        onChange={this.handleOtherpdf.bind(this, i)}
                        placeholder="PDF"
                        
                    />
                </div>
            </div>
            {this.state.errorDocumentOther ? 
            <div class="alert alert-danger">File size should be 10MB or less than 10MB</div>
            :<div class="alert alert-info">File size should be 10MB or less than 10MB</div>
            } 
        </div>
    ))
}
handleDocument(i, e) {
    const { name, value } = e.target;
    let documents = [...this.state.documents];
    documents[i] = { ...documents[i], [name]: value };
    this.setState({ documents });
}
handleDocumentpdf = (i, event) => {
    this.setState({ errorDocument: false })
    const { name, value } = event.target;
    if (event.target.files[0].size <= 10485760) {

        this.getPdfBase64(event.target.files[0]).then(res => {
            let documents = [...this.state.documents];
            if(documents[i].dpdf.key !== undefined){
                documents[i] = { ...documents[i], [name]: {key: documents[i].dpdf.key, location: documents[i].dpdf.location,newpdf:res} };
            }else{
                documents[i] = { ...documents[i], [name]: {newpdf:res} };
            }
            this.setState({ documents });
        }).catch(error => {
            console.error(error);
        })
    } else {
        this.setState({ errorDocument: true })
    }
}

handleCalculatorpdf = (i, event) => {
    this.setState({ errorDocumentCalculator: false })
    const { name, value } = event.target;
    if (event.target.files[0].size <= 10485760) {
        this.getPdfBase64(event.target.files[0]).then(res => {
            let calculations = [...this.state.calculations];
            if(calculations[i]?.calcpdf?.key !== undefined){
                calculations[i] = { ...calculations[i], [name]: {key: calculations[i].calcpdf.key, location: calculations[i].calcpdf.location,newpdf:res} };
            }else{
                calculations[i] = { ...calculations[i], [name]: {newpdf:res} };
            }
            this.setState({ calculations });
        }).catch(error => {
            console.error(error);
        })
    } else {
        this.setState({ errorDocumentCalculator: true })
    }
}
handleOtherpdf = (i, event) => {
    this.setState({ errorDocumentOther: false })
    const { name, value } = event.target;
    if (event.target.files[0].size <= 10485760) {
        this.getPdfBase64(event.target.files[0]).then(res => {
            let importantlist = [...this.state.importantlist];
            if(importantlist[i].otherpdf.key !== undefined){
                importantlist[i] = { ...importantlist[i], [name]: {key: importantlist[i].otherpdf.key, location: importantlist[i].otherpdf.location,newpdf:res} };
            }else{
                importantlist[i] = { ...importantlist[i], [name]: {newpdf:res} };
            }
            this.setState({ importantlist });
        }).catch(error => {
            console.error(error);
        })
    } else {
        this.setState({ errorDocumentOther: true })
    }
}
getPdfBase64 = async (file) => {
    return await new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            if (event.target.result) {
                resolve(event.target.result);
            } else {
                reject('Please try again.');
            }
        };
    });
}
handleCalculation(i, e) {
    const { name, value } = e.target;
    let calculations = [...this.state.calculations];
    calculations[i] = { ...calculations[i], [name]: value };
    this.setState({ calculations });
}
handleOther(i, e) {
    const { name, value } = e.target;
    let importantlist = [...this.state.importantlist];
    importantlist[i] = { ...importantlist[i], [name]: value };
    this.setState({ importantlist });
}
handleChangePosition(i, e) {

    // if((0< e.target.value) && parseInt(e.target.value) == e.target.value){
    this.setState({ errorMessage: false })
    const { name, value } = e.target;
    if(name == "dposition"){
        let documents = [...this.state.documents];
        documents[i] = { ...documents[i], ['dposition']: parseInt(Math.abs(e.target.value)) };
        this.setState({ documents });
    }else if(name == "cPosition"){
        let calculations = [...this.state.calculations];
        calculations[i] = { ...calculations[i], ['cPosition']: parseInt(Math.abs(e.target.value)) };
        this.setState({ calculations });
    }else if(name == "otherPosition"){
        let importantlist = [...this.state.importantlist];
        importantlist[i] = { ...importantlist[i], ['otherPosition']: parseInt(Math.abs(e.target.value)) };
        this.setState({ importantlist });
    }


}
addClick() {
    this.setState(prevState => ({
        documents: [...prevState.documents, { documentTitle: "", documentVideoTitle: "", dvideoURL: "", dposition: "", dpdf:"" }]
    }))
}
addCalculationsClick() {
    this.setState(prevState => ({
        calculations: [...prevState.calculations, { cPosition: "", cTitle: "",cvideoTitle: "", cvideoURL: "", clink: ""}]
    }))
}
addImportantClick() {
    this.setState(prevState => ({
        importantlist: [...prevState.importantlist, { otherPosition: "", otherTitle: "",othervideoTitle: "", othervideolink: "", otherlink: ""}]
    }))
}
removeClick(i) {
    let documents = [...this.state.documents];
    documents.splice(i, 1);
    this.setState({ documents });
}
removeCalculationClick(i) {
    let calculations = [...this.state.calculations];
    calculations.splice(i, 1);
    this.setState({ calculations });
}
removeImportantsClick(i) {
    let importantlist = [...this.state.importantlist];
    importantlist.splice(i, 1);
    this.setState({ importantlist });
}

handlePublish(event) {
    event.preventDefault();
    const rData = this.state;
    this.setState({wait:true});
    API.post('/courses//update/resource', rData)
        .then(result => {
            this.setState({wait:false});
            this.props.history.goBack()
            message.success('Resource updated', 3)
        })
        .catch(e => {
            this.setState({wait:false});
            Modal.error({
                title: 'An error occurred',
                content: e.response.data.msg,
            });
        });
}

handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
        [name]: value,
    });
}
handleChangechk(event) {
    this.setState({ status: !this.state.status });
}
handleChangeDoc(event) {
    this.setState({ checkDoc: !this.state.checkDoc });
}
handleChangeCal(event) {
    this.setState({ checkCal: !this.state.checkCal });
}
handleChangeOther(event) {
    this.setState({ checkOther: !this.state.checkOther });
}
handleMainPosition = (event) => {
    const { name, value } = event.target;
    if (event.target.value != "") {
        this.setState({
            [name]: parseInt(Math.abs(value)),
        })
    } else {
        this.setState({
            [name]: "",
        })
    }

}


render() {
    const courseCateSelect = () => {
        if (this.state.selectedCategory != '') {
            return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                <option value="">Select Category</option>
                {this.state.catlists.map(catlist =>
                    <option
                        value={catlist._id}
                        selected={this.state.selectedCategory == catlist._id ? true : false}
                    >
                        {catlist.name}
                    </option>
                )
                }
            </select>
        } else {
            return <select id="cat-select" className="custom-select" required onChange={this.handleChange} name="selectedCategory">
                <option value="">Select Category</option>
                {this.state.catlists.map(catlist => <option value={catlist._id}>{catlist.name}</option>)}
            </select>
        }

    }
    return (

        <div>
                <div className="d-flex" id="wrapper">
                    <Sidebar />

                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h1 className="mt-2 text-primary">Update Resource</h1>
                            <div className="new-post">
                                <form onSubmit={this.handlePublish}>
                                    <div className="form-group">
                                        <h5 className="l-duration">Resource Order: <span className="requiredsymbol">*</span></h5>
                                        <input
                                            className="form-control video-position"
                                            pattern="[0-9]"
                                            type="number"
                                            name="resourceOrder"
                                            min="1"
                                            value={this.state.resourceOrder}
                                            onChange={this.handleMainPosition}
                                            placeholder="Position"
                                            required
                                        />
                                    </div>
                                    <div className="alert alert-info">
                                        <ol>
                                            <li>Order should be greater than 0</li>
                                            <li>This will define which Category will come first</li>
                                        </ol>
                                    </div>
                                    <div className="form-group">
                                        <h5>This resource will have</h5>
                                        <ol>
                                            <li>
                                                <input type="checkbox" checked={this.state.checkDoc} onChange={this.handleChangeDoc} className="custom-control-input" id="docCheck" />
                                                <label style={{ marginLeft: "16px" }} className="custom-control-label" for="docCheck">Documents</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" checked={this.state.checkCal} onChange={this.handleChangeCal} className="custom-control-input" id="calCheck" />
                                                <label style={{ marginLeft: "16px" }} className="custom-control-label" for="calCheck">Calculations</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" checked={this.state.checkOther} onChange={this.handleChangeOther} className="custom-control-input" id="otherCheck" />
                                                <label style={{ marginLeft: "16px" }} className="custom-control-label" for="otherCheck">Other Resources</label>
                                            </li>
                                        </ol>                                           
                                    </div>
                                    <div className="form-group col-md-6 p-0 mt-3">
                                        <h5 className="cat-select" >Category <span className="requiredsymbol">*</span></h5>
                                        {courseCateSelect()}
                                    </div>
                                    {this.state.checkDoc && 
                                        <fieldset className="form-group video-input-box">
                                            <h4 className="intro-video">Documents List</h4>
                                            {this.createDocumentsListUI()}
                                            <input type='button' value='Add More Document' className="btn btn-outline-primary float-right mt-2 mb-2" onClick={this.addClick.bind(this)} />
                                        </fieldset>
                                    }
                                    {this.state.checkCal && 
                                        <fieldset className="form-group video-input-box">
                                            <h4 className="intro-video">Calculations List</h4>
                                            {this.createCalculationListUI()}
                                            <input type='button' value='Add More Calculator' className="btn btn-outline-primary float-right mt-2 mb-2" onClick={this.addCalculationsClick.bind(this)} />
                                        </fieldset>
                                    }
                                    {this.state.checkOther && 
                                        <fieldset className="form-group video-input-box">
                                            <h4 className="intro-video">Other Resources</h4>
                                            {this.createImportantListUI()}
                                            <input type='button' value='Add More Resources' className="btn btn-outline-primary float-right mt-2 mb-2" onClick={this.addImportantClick.bind(this)} />
                                        </fieldset>
                                    }
                                    <div className="form-group publish-box">
                                        <input type="checkbox" checked={this.state.status} onChange={this.handleChangechk} className="custom-control-input" id="customCheck1" />
                                        <label style={{ marginLeft: "16px" }} className="custom-control-label" for="customCheck1">Publish</label>
                                    </div>
                                    <div className="form-group save-block">
                                        <input
                                            disabled={this.state.wait}
                                            type="submit"
                                            value="Update"
                                            className="btn btn-outline-primary btn-lg"
                                        />
                                        {this.state.wait && <div style={{ textAlign: 'center' }} class="spinner-border" role="status">
                                    </div>}
                                    </div>
                                    
                                </form>
                            </div>
                            </div>
                            </div>
            </div>
        </div>



    );
}
}

export default withRouter(UpdateResource);