import React, { Component } from 'react';
import API from '../../utils/API';
import { Modal } from 'antd'

class DeleteBasicpage extends Component {

    componentDidMount() {
    };

    deleteRecord(id) {
        API.post("/pages/content-delete", { id: id })
            .then(res => {
                if (res.status === 200) {
                    Modal.success({
                        onOk: window.location.reload(),
                        content: res.data.msg,
                    })
                }
            })
            .catch(e => {
                Modal.error({
                    title: 'An error occurred',
                    content: e.message,
                });
            });
    }
    render() {
        return (

            <div>
                <div className="modal fade" id="delete-basicpage-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delete Page</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                Do you want to delete Page?
                                <div>Page Title: {this.props.record.title}</div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    data-toggle="modal"
                                    data-target="#delete-course-modal"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => this.deleteRecord(this.props.record._id)}>
                                    <i className="fa fa-trash"></i>Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default DeleteBasicpage;