import React, { useState } from 'react'
import './aboutuser.css'
import API from '../../utils/API'
import profilebanner from '../../assets/images/bannerdefault.png'
import profileimage from "../../assets/images/userdefault.png"
import 'react-toastify/dist/ReactToastify.css';

const UserViewModal = props => {
  const [userDetail, setUserDetail] = useState()
  let data = {}
  const [{ alt, src }, setImg] = useState({
    src: profilebanner,

  });
  const [primaryCat, setPrimaryCat] = useState("")
  const [secondaryCat, setSecondaryCat] = useState()
  const [otherCat, setOtherCat] = useState([])
  const [catData, setCatData] = useState([])
  const [interest, setInterest] = useState([])
  const [intData, setData] = useState()
  const [isUpload, setUpload] = useState(false)
  const [profileImg, setProfileImg] = useState()
  const [bannerImg, setbannerImg] = useState()
  const [isProfileUpload, setProfileUpload] = useState(false)
  const [roles, setRoles] = useState([])
  const id = props.record._id;
  React.useEffect(() => {
    // API.get(`/users/userinfo/?id=${props.record._id}`)
    setUserDetail("")
    API.get(`/users/userinfo/?id=${props.record._id}`).then(
      result => {
        data = result.data.user
        setUserDetail(result.data.user)
        if (data?.userinfoid?.primarycategory !== undefined)
          categoryName(data?.userinfoid?.primarycategory)
        if (data?.userinfoid?.secondarycategory !== undefined)
          if (data?.userinfoid?.roles !== undefined) {
            rolesName(data?.userinfoid?.roles)
          }
        secCatName(data?.userinfoid?.secondarycategory)
        if (data?.usrinfoid?.othercategory?.length !== 0) {

          const arr = []
          data?.userinfoid?.othercategory?.map(res => {
            API.get(`/categories/id/?categoryid=${res}`).then(res => {
              otherCat.push(res?.data?.category?.name)
              setCatData(<ul>
                {otherCat?.map(cat => <li>{cat}</li>)}

              </ul>)

            }).catch(err => {
              console.log(err);
            })
          })
          //    setOtherCat(arr)
        }

        if (result?.data?.user?.userinfoid?.interests.length !== 0) {
          const arr = []
          result?.data?.user?.userinfoid?.interests.map(result => {

            API.get(`/interests/id/?interestid=${result}`).then(res => {

              interest.push(res?.data?.interets?.name)
              setData(<ul>
                {interest?.map(cat => <li>{cat}</li>)}
              </ul>)

            }).catch(err => {
              console.log(err);
            })
          })




        }

      }
    ).catch(err => {
      console.log(err);
    })
    API.get(`/userinfo/getimageprofile/?userid=${id}`).then(result => {

      setProfileImg(<img alt={profilebanner} className="img" src={`data:image/jpeg;base64,${result?.data?.base64}`} />)
      setProfileUpload(true)

    }).catch(err => {

      return
    })
    API.get(`/userinfo/getimage/?userid=${id}`).then(result => {
      setbannerImg(<img width="100%" height="420" alt={profilebanner} src={`data:image/jpeg;base64,${result.data.base64}`} />)
      setUpload(true)

    }).catch(err => {
      console.log(err);
      return
    })
  }, [id])
  const categoryName = (id) => {

    API.get(`/categories/id/?categoryid=${id}`).then(res => {
      setPrimaryCat(res?.data?.category?.name)
    }).catch(err => {
      console.log(err);
    })
  }
  const rolesName = (id) => {
    API.get(`/roles/id?rolesid=${id}`).then(res => {
      console.log("dddddddddddddddddddddddddddddddd");
      setRoles(res?.data?.roles?.name)
    }).catch(err => {
      console.log(err);
    })
  }
  const secCatName = (id) => {

    API.get(`/categories/id/?categoryid=${id}`).then(res => {
      setSecondaryCat(res?.data?.category?.name)

    }).catch(err => {
      console.log(err);
    })
  }
  const titlecase = (word) => {
    if (word !== undefined) {
      return word.split(' ')
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
    }
  }
  const Capitalize = (str) => {
    if (str !== undefined && str !== null)
      return str.charAt(0).toUpperCase() + str.slice(1);

  }
  const onCloseModal = () => {
    window.location.reload()
    props.onclose()
  }

  return (

    <div>
      <div style={{ marginBottom: '5px' }} class="float-right"> <button onClick={onCloseModal} type="button" class="btn btn-primary">Close</button></div>
      <div className="guestprofileinformation">

        {!isUpload ? <div className="coverimage"><img width="100%" height="420" src={src} /></div> :
          <div className="coverimage">{bannerImg}</div>}
        <div className="guestprofiledetails">
          {!isProfileUpload ? <div className="userpict"><img className="img" src={profileimage} /></div>
            : <div key={Math.random()} className="userpict">{profileImg}</div>}
          <div className="username">{userDetail?.firstname && <h2>{Capitalize(userDetail?.firstname)} {Capitalize(userDetail?.lastname)}</h2>}</div>
          <div className="description">{Capitalize(props?.shortBio)}</div>
        </div>
      </div>
      <div className="maincontent aboutuser">


        <div className="aboutsection">

          <div className="aboutcontainer">
            {userDetail?.userinfoid?.roles && <div className="viewrow">
              <h1 className="blocktitle" >Primary role</h1>
              <div className="value">{roles}</div>
            </div>}
            {userDetail?.userinfoid?.primarycategory && <div className="viewrow">
              <h1 className="blocktitle" >Primary category</h1>
              <div className="value">{primaryCat}</div>
            </div>}
            {userDetail?.userinfoid?.secondarycategory && <div className="viewrow">
              <h1 className="blocktitle" >Secondary category</h1>
              <div className="value">{secondaryCat}</div>
            </div>}
            {userDetail?.userinfoid?.othercategory[0] && <div className="viewrow">
              <h1 className="blocktitle" >Other categories</h1>
              <div className="value">
                {catData}
              </div>
            </div>}
            {userDetail?.userinfoid?.interests[0] && <div className="viewrow">
              <h1 className="blocktitle" >Interests</h1>
              <div className="value">
                {intData}
              </div>
            </div>}
            {userDetail?.userinfoid?.shortbio && <div className="viewrow description">
              <h1 className="blocktitle" >Short bio</h1>
              <div className="value">{userDetail?.userinfoid?.shortbio}</div>
            </div>}
            {userDetail?.userinfoid?.currentlocation?.state && <div className="viewrow addresslocation">
              <h1 className="blocktitle" >Current location</h1>
              <div className="value">
                <ul>
                  <li>{titlecase(userDetail?.userinfoid?.currentlocation?.state)}</li>
                  <li>{titlecase(userDetail?.userinfoid?.currentlocation?.city)} </li>
                  <li>{userDetail?.userinfoid?.currentlocation.zipcode}</li>
                </ul>
              </div>
            </div>}
            {userDetail?.userinfoid?.fulladdress && <div className="viewrow description">
              <h1 className="blocktitle" >Address</h1>
              <div className="value">{userDetail?.userinfoid?.fulladdress}</div>
            </div>}
            {userDetail?.userinfoid?.primaryinvestmentlocation && <div className="viewrow addresslocation">
              <h1 className="blocktitle" >Primary investment location</h1>
              <div className="value">
                <ul>
                  <li>{titlecase(userDetail?.userinfoid?.primaryinvestmentlocation?.state)}</li>
                  <li>{titlecase(userDetail?.userinfoid?.primaryinvestmentlocation?.city)} </li>
                  <li>{userDetail?.userinfoid?.primaryinvestmentlocation.zipcode} </li>
                </ul>
              </div>
            </div>}
            {userDetail?.userinfoid?.secondaryinvestmentlocation[0]?.state && <div className="viewrow addresslocation">
              <h1 className="blocktitle" >Secondary investment location</h1>
              <div className="value">
                <ul>
                  <li>{titlecase(userDetail?.userinfoid?.secondaryinvestmentlocation[0]?.state)}</li>
                  <li>{titlecase(userDetail?.userinfoid?.secondaryinvestmentlocation[0]?.city)} </li>
                  <li>{userDetail?.userinfoid?.secondaryinvestmentlocation[0]?.zipcode} </li>
                </ul>
              </div>
            </div>}
            {userDetail?.userinfoid?.secondaryinvestmentlocation[0]?.state && <div className="viewrow addresslocation">
              <h1 className="blocktitle" >Other investment locations</h1>
              <div className="value">
                {userDetail?.userinfoid?.secondaryinvestmentlocation?.map(cat => <ul>
                  <li>{titlecase(cat?.state)}</li>
                  <li>{titlecase(cat?.city)}</li>
                  <li>{cat.zipcode}</li>
                </ul>).splice(1, userDetail.userinfoid?.secondaryinvestmentlocation?.length)}

              </div>
            </div>}
            {userDetail?.userinfoid?.phonenumber && <div className="viewrow">
              <h1 className="blocktitle" >Phone number</h1>
              <div className="value">{userDetail?.userinfoid?.phonenumber}</div>
            </div>}
            {userDetail?.email && <div className="viewrow">
              <h1 className="blocktitle" >Email</h1>
              <div className="value">{userDetail?.email}</div>
            </div>}
            {userDetail?.userinfoid?.website && <div className="viewrow">
              <h1 className="blocktitle" >Website</h1>
              <div className="value">{userDetail?.userinfoid?.website} </div>
            </div>}
            {userDetail?.userinfoid?.aboutyourself && <div className="viewrow description">
              <h1 className="blocktitle" >About yourself / Short description</h1>
              <div className="value">{userDetail?.userinfoid?.aboutyourself}</div>

            </div>}
            {userDetail?.userinfoid?.companydetails && <div className="viewrow description">
              <h1 className="blocktitle" >Company details</h1>
              <div className="value">{userDetail?.userinfoid?.companydetails} </div>

            </div>}
            {userDetail?.pathalias?.alias && <div className="viewrow description">
              <h1 className="blocktitle" >Profile Url</h1>
              <div className="value">{userDetail?.pathalias?.alias} </div>

            </div>}
          </div>
        </div>
      </div></div>
  )

}

export default UserViewModal;

